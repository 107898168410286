import {
  CREATE_PROBLEM_ITEM_FOR_RETURNING_REQ,
  CREATE_PROBLEM_ITEM_FOR_RETURNING_RES,
  CREATE_UNVERIFIED_LIST_FOR_RETURNING_REQ,
  REMOVE_PROBLEM_ITEMS_REQ,
  UPDATE_PROBLEM_MEMO_REQ,
  UPDATE_UNVERIFIED_ITEM_FOR_RETURNING_REQ,
  UPDATE_UNVERIFIED_ITEM_FOR_RETURNING_RES,
} from "@sellernote/_shared/src/api-interfaces/boful-api/returning";
import { useAppMutation } from "@sellernote/_shared/src/services/query";

export const INSPECTION_QUERY_KEY_GEN = {
  all: () => [{ scope: "returning/INSPECTION_QUERY" }] as const,
};

// 개별 불일치 상품 추가 및 수정
function useUpdateUnverifiedItemForReturning({
  returningId,
  onSuccess,
}: {
  returningId: number;
  onSuccess?: () => void;
}) {
  const mutation = useAppMutation<
    UPDATE_UNVERIFIED_ITEM_FOR_RETURNING_REQ,
    UPDATE_UNVERIFIED_ITEM_FOR_RETURNING_RES
  >({
    requestOptions: {
      method: "patch",
      path: `returning/${returningId}/add/unverifiedItem`,
      apiType: "BofulDefault",
    },

    onSuccess,

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        if (failureInfo?.errorCode === "E3022")
          return {
            title: `스캔하신 상품은 ${returningId}에 해당하는 상품입니다. 불일치 상품 입력 화면을 이탈 후 다시 스캔해주세요.`,
          };

        return {
          title: "불일치 상품 입력 중 오류가 발생했습니다.",
        };
      },
    },
  });

  return { ...mutation };
}

// 스캔으로 입력된 불일치 상품 여러개를 한번에 추가 (불일치 리스트 페이지를 위한)
function useCreateUnverifiedListForReturning({
  returningId,
  onSuccess,
}: {
  returningId: number;
  onSuccess?: () => void;
}) {
  const mutation = useAppMutation<
    CREATE_UNVERIFIED_LIST_FOR_RETURNING_REQ,
    unknown
  >({
    requestOptions: {
      method: "patch",
      path: `returning/${returningId}/add/unverifiedItems`,
      apiType: "BofulDefault",
    },

    onSuccess,

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        if (failureInfo?.errorCode === "E3022")
          return {
            title: `스캔하신 상품은 ${returningId}에 해당하는 상품입니다. 불일치 상품 입력 화면을 이탈 후 다시 스캔해주세요.`,
          };

        return {
          title: "불일치 상품 입력 중 오류가 발생했습니다.",
        };
      },
    },
  });

  return { ...mutation };
}

function useRemoveUnverifiedList({
  returningId,
  onSuccess,
}: {
  returningId: number;
  onSuccess?: () => void;
}) {
  const mutation = useAppMutation<{ itemIds: number[] }, unknown>({
    requestOptions: {
      method: "delete",
      path: `returning/${returningId}/unverifiedItem`,
      apiType: "BofulDefault",
    },

    onSuccess,

    failureModalInfo: {
      customizeMessage: () => ({
        title: "불일치 상품 삭제 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

function useCreateProblemItemForReturning({
  returningId,
  onSuccess,
}: {
  returningId: number;
  onSuccess?: () => void;
}) {
  const mutation = useAppMutation<
    CREATE_PROBLEM_ITEM_FOR_RETURNING_REQ,
    CREATE_PROBLEM_ITEM_FOR_RETURNING_RES
  >({
    requestOptions: {
      method: "patch",
      path: `returning/${returningId}/inspection/add/problem`,
      apiType: "BofulDefault",
    },

    onSuccess,

    failureModalInfo: {
      customizeMessage: () => ({
        title: "비정상 상품 입력 중 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

function useRemoveProblemList({
  returningId,
  onSuccess,
}: {
  returningId: number;
  onSuccess?: () => void;
}) {
  const mutation = useAppMutation<REMOVE_PROBLEM_ITEMS_REQ, unknown>({
    requestOptions: {
      method: "patch",
      path: `returning/${returningId}/inspection/delete/problem`,
      apiType: "BofulDefault",
    },

    onSuccess,

    failureModalInfo: {
      customizeMessage: () => ({
        title: "비정상 상품 삭제 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

function useUpdateProblemMemo({
  returningId,
  onSuccess,
}: {
  returningId: number;
  onSuccess?: () => void;
}) {
  const mutation = useAppMutation<UPDATE_PROBLEM_MEMO_REQ, unknown>({
    requestOptions: {
      method: "patch",
      path: `returning/${returningId}`,
      apiType: "BofulDefault",
    },

    onSuccess,

    failureModalInfo: {
      customizeMessage: () => ({
        title: "비정상 상품 비고 저장 중 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

const INSPECTION_QUERY = {
  useUpdateUnverifiedItemForReturning,
  useCreateUnverifiedListForReturning,
  useRemoveUnverifiedList,
  useCreateProblemItemForReturning,
  useRemoveProblemList,
  useUpdateProblemMemo,
};

export default INSPECTION_QUERY;
