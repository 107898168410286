import styled from "styled-components";

const form = styled.form`
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export default { form };
