import { memo, useEffect, useMemo, useState } from "react";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import { ReceivingItem } from "@sellernote/_shared/src/types/fulfillment/receiving";
import {
  checkIsUnverifiedSku,
  getWarehousingItemStatusLabel,
} from "@sellernote/_shared/src/utils/fulfillment/common";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import InputText from "@sellernote/_sds-v1/src/components/input/InputText";

import useAddPartialWarehousing from "pages/returning/warehousing/:id/multi-location/:skuId/AddPartialWarehousing/useAddPartialWarehousing";

import ItemStatus from "components/ItemStatus";

import Styled from "../index.styles";

function AddPartialWarehousing({
  returningId,
  returningItem,
}: {
  returningId: number;
  returningItem: ReceivingItem;
}) {
  const {
    addPartialWarehousingToItem,
    canAddPartialWarehousing,
    remainedQtyForWarehousing,
    ResponseHandlerOfAddingPartialWarehousingToItem,
  } = useAddPartialWarehousing({ returningId, returningItem });

  const [visibleInputModal, setVisibleInputModal] = useState(false);

  const [qtyInput, setQtyInput] = useState<number>();

  useEffect(() => {
    if (!visibleInputModal) {
      setQtyInput(undefined);
    }
  }, [visibleInputModal]);

  const qtyInputValidation = useMemo(():
    | { valid: true }
    | { valid: false; errorMsg?: string } => {
    if (!qtyInput) {
      return {
        valid: false,
      };
    }

    if (qtyInput > remainedQtyForWarehousing) {
      return {
        valid: false,
        errorMsg: "잔여 수량 이하로 입력해주세요.",
      };
    }

    return {
      valid: true,
    };
  }, [qtyInput, remainedQtyForWarehousing]);

  return (
    <>
      <Button
        className="btn-add"
        theme="secondary"
        size="block"
        label={"분할입고 추가"}
        handleClick={() => setVisibleInputModal(true)}
        disabled={!canAddPartialWarehousing}
      />

      <Modal
        active={visibleInputModal}
        uiType="contentWithCustomBody"
        title={
          <>
            {getFormattedSingleSkuId(returningItem.sku?.id)}(SKU ID)
            <br />
            상태:{" "}
            <ItemStatus
              statusLabel={getWarehousingItemStatusLabel(
                returningItem.processStatus
              )}
              isUnverifiedSku={checkIsUnverifiedSku(
                returningItem.inspectProblems
              )}
            />
          </>
        }
        body={
          <Styled.addPartialModalBody>
            <div className="remained">
              잔여수량: {remainedQtyForWarehousing}
            </div>

            <InputText
              borderType="outline"
              valueType="int"
              label="입고 수량"
              placeholder="입력해주세요."
              value={qtyInput}
              setValue={setQtyInput}
              errorMessage={
                !qtyInputValidation.valid &&
                (
                  qtyInputValidation as {
                    valid: false;
                    errorMsg?: string;
                  }
                ).errorMsg
              }
            />

            <Button
              theme="primary"
              size="normal"
              label="확인"
              disabled={!qtyInputValidation.valid}
              handleClick={addPartialWarehousingToItem({
                itemId: returningItem.id,
                remainedQty: remainedQtyForWarehousing,
                // validation에서 확인
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                quantity: qtyInput!,
                isInit: false,
                callbackAfterAdd: () => setVisibleInputModal(false),
              })}
            />
          </Styled.addPartialModalBody>
        }
        onClose={() => setVisibleInputModal(false)}
      />

      {ResponseHandlerOfAddingPartialWarehousingToItem}
    </>
  );
}

export default memo(AddPartialWarehousing);
