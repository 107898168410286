import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { useSetRecoilState } from "recoil";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import { TableRowInfoToHighlight } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import { FULFILLMENT_COMMON_ATOMS } from "@sellernote/_shared/src/states/fulfillment/common";
import { ReceivingItem } from "@sellernote/_shared/src/types/fulfillment/receiving";
import { omitWithEllipsis } from "@sellernote/_shared/src/utils/common/string";
import { getTeamLabelForBofulWorker } from "@sellernote/_shared/src/utils/fulfillment/common";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import { getFormattedReturningId } from "@sellernote/_shared/src/utils/fulfillment/returning";
import ChipWithClear from "@sellernote/_sds-v1/src/components/ChipWithClear";

import Layout from "containers/Layout";
import { LayoutRefreshInfo } from "containers/Layout/Refresh";
import withRequireAuth from "hocs/withRequireAuth";
import useConfirmModal from "hooks/common/useConfirmModal";
import useGetWorkerByIdFactory from "hooks/common/useGetWorkerByIdFactory";
import useFetchDetailForWarehousing from "hooks/returning/useFetchDetailForWarehousing";
import useScanLocation from "hooks/returning/useScanLocation";
import useScanWarehousingSKU from "hooks/returning/useScanWarehousingSKU";
import useWarehousingCounting from "hooks/returning/useSKUCountingForWarehousing";
import { authSelectors } from "modules/auth";
import { returningActions, returningSelectors } from "modules/returning";
import { useAppSelector } from "store";

import ItemStatus from "components/ItemStatus";

import CloseWarehousing from "./CloseWarehousing";
import CompleteWarehousing from "./CompleteWarehousing";
import SKUList from "./SKUList";
import Styled from "./index.styles";

export type ScanModeForReturningWarehousing = "skuId" | "location";

function ReturningWarehousingDetail() {
  const { ConfirmModal, setConfirmModal } = useConfirmModal();

  const match = useRouteMatch<{ id: string }>();
  const {
    params: { id },
  } = match;

  const setCanNotLeavePage = useSetRecoilState(
    FULFILLMENT_COMMON_ATOMS.CAN_NOT_LEAVE_PAGE
  );

  const dispatch = useDispatch();
  const { currentUser, canCloseWarehousing } = useAppSelector((state) => {
    return {
      currentUser: authSelectors.getCurrentUser(),

      canCloseWarehousing: returningSelectors.checkCanCloseWarehousing(state),
    };
  });

  const [scanMode, setScanMode] =
    useState<ScanModeForReturningWarehousing>("skuId");
  const [rowInfoToHighlight, setRowInfoToHighlight] = useState<
    TableRowInfoToHighlight | undefined
  >(undefined);
  const [groupedItemIdInProgress, setGroupedItemIdInProgress] = useState(0);

  const {
    returningDetailData,
    SKUItemsFilteredByActualQtyReturning,
    ResponseHandlerOfDetail,
  } = useFetchDetailForWarehousing({
    returningId: Number(id),
  });

  const skuCounting = useWarehousingCounting(
    SKUItemsFilteredByActualQtyReturning
  );

  const { setScanSKUActive, ResultHandlerOfScanSKU } = useScanWarehousingSKU({
    scanType: "single",
    skuCounting,
    returningId: Number(id),
    setRowInfoToHighlight,
    setSkuInProgress: skuCounting.setSkuInProgress,
    itemList: SKUItemsFilteredByActualQtyReturning,
    groupedItemIdInProgress,
  });

  const {
    scannedLocation,
    setScannedLocation,
    setScanLocationActive,
    showSelectionErrorForScanLocation,
    ResultHandlerOfScanLocation,
  } = useScanLocation({
    scanType: "single",
    skuIdInprogress: skuCounting.skuInProgress?.skuId,
    counterData: skuCounting.counter.counterInfo,
    itemList: SKUItemsFilteredByActualQtyReturning,
    groupedItemIdInProgress,
  });

  const getWorkerById = useGetWorkerByIdFactory();

  useEffect(() => {
    setScanSKUActive(scanMode === "skuId");
    setScanLocationActive(scanMode === "location");
  }, [scanMode, setScanLocationActive, setScanSKUActive]);

  /**
   * 담당중인 SKU 중 미결된 것이 있으면 이탈 할 수 없게 함
   */
  const checkHasUnfinishedWarehousingOfMine = useCallback(
    (items?: ReceivingItem[]) => {
      if (!items) return;

      return items.some((item) => {
        if (!item.placeItems) return false;

        return item.placeItems.some(
          (pi) => pi.placerId === currentUser?.id && !pi.isCompletePlacing
        );
      });
    },
    [currentUser?.id]
  );

  /**
   * 담당중인 SKU 중 미결된 것이 있으면 이탈 할 수 없게 함
   */
  useEffect(() => {
    const hasUnfinishedWarehousingOfMine = checkHasUnfinishedWarehousingOfMine(
      SKUItemsFilteredByActualQtyReturning
    );

    if (hasUnfinishedWarehousingOfMine) {
      setCanNotLeavePage(true);
    } else {
      setCanNotLeavePage(false);
    }
  }, [
    SKUItemsFilteredByActualQtyReturning,
    checkHasUnfinishedWarehousingOfMine,
    dispatch,
    setCanNotLeavePage,
  ]);

  const refreshInfo: LayoutRefreshInfo = useMemo(() => {
    let confirmMessage: ReactNode;

    if (skuCounting.skuInProgress) {
      confirmMessage = (
        <>
          현재 카운트 중인 SKU ID(
          {getFormattedSingleSkuId(skuCounting.skuInProgress.skuId)} / 상태:{" "}
          <ItemStatus
            statusLabel={skuCounting.skuInProgress.statusLabel}
            isUnverifiedSku={skuCounting.skuInProgress.isUnverifiedSku}
          />
          )가 초기화 됩니다
        </>
      );
    }

    return {
      ...(confirmMessage ? { confirm: { message: confirmMessage } } : {}),
      handleRefresh: (showSuccessMessage) => {
        dispatch(
          returningActions.GET_DETAIL({
            id: Number(id),

            _postSuccessCallback: (res) => {
              setRowInfoToHighlight(undefined);
              showSuccessMessage();
              skuCounting.reset(
                res.returning.items.filter((item) => (item.actualQty ?? 0) > 0)
              );
            },
          })
        );
      },
    };
  }, [skuCounting, dispatch, id]);

  const handleScanSKUClick = useCallback(() => {
    setScanMode("skuId");
  }, []);

  const handleScanLocationClick = useCallback(() => {
    if (!skuCounting.skuInProgress) {
      showSelectionErrorForScanLocation();
    }

    setScanMode("location");
  }, [showSelectionErrorForScanLocation, skuCounting.skuInProgress]);

  const resetCountByCounterKey = useCallback(
    (counterKey: string) => () => {
      setConfirmModal(undefined);

      skuCounting.counter.resetCountById(counterKey);
      skuCounting.setSkuInProgress(undefined);
      setRowInfoToHighlight(undefined);
    },
    [setConfirmModal, skuCounting]
  );

  const resetAfterComplete = useCallback(() => {
    setScanMode("skuId");
    setScannedLocation(undefined);
    setRowInfoToHighlight(undefined);
    skuCounting.setSkuInProgress(undefined);
    setGroupedItemIdInProgress(0);
  }, [setScannedLocation, skuCounting]);

  return (
    <Layout
      navigator={{
        title: `${getFormattedReturningId(
          returningDetailData?.returning
        )} / ${getTeamLabelForBofulWorker({
          id: returningDetailData?.returning.team?.id,
          name: omitWithEllipsis({
            src: returningDetailData?.returning.team?.name,
            maxLength: 10,
            ellipsis: "...",
          }),
          company: omitWithEllipsis({
            src: returningDetailData?.returning.team?.company,
            maxLength: 10,
            ellipsis: "...",
          }),
        })}`,
      }}
      refreshInfo={refreshInfo}
    >
      <Styled.container>
        {ConfirmModal}

        <div className="header">
          <div className="left">{scannedLocation?.barCode}</div>

          <div className="right">
            <Button
              label="상품스캔"
              size="small"
              theme={scanMode === "skuId" ? "tertiary" : "secondary"}
              handleClick={handleScanSKUClick}
            />

            <Button
              label="위치스캔"
              size="small"
              theme={scanMode === "location" ? "tertiary" : "secondary"}
              handleClick={handleScanLocationClick}
            />
          </div>
        </div>

        <div className="main-manager">
          <div className="main-manager-label">메인담당자</div>

          <ChipWithClear
            label={
              getWorkerById(returningDetailData?.returning.managerId)?.name ??
              "-"
            }
            colorTheme="dark"
            className="custom-chip-with-clear"
          />
        </div>

        <SKUList
          returningId={Number(id)}
          mainManagerId={returningDetailData?.returning.managerId}
          detailItems={SKUItemsFilteredByActualQtyReturning}
          selectedLocation={scannedLocation}
          rowInfoToHighlight={rowInfoToHighlight}
          setRowInfoToHighlight={setRowInfoToHighlight}
          groupedItemIdInProgress={groupedItemIdInProgress}
          setGroupedItemIdInProgress={setGroupedItemIdInProgress}
          skuCounting={skuCounting}
          counterData={skuCounting.counter.counterInfo}
          placingIdInProgress={skuCounting.skuInProgress?.placingId}
          addCountByInput={skuCounting.counter.addCountByInput}
          resetCountByCounterKey={resetCountByCounterKey}
          setConfirmModal={setConfirmModal}
          resetAfterCompleteWarehousingByForce={resetAfterComplete}
        />

        <div className="total-count">
          <span className="label">총 카운트:</span>
          <span className="value">{skuCounting.totalCount}</span>
        </div>

        <div className="footer">
          {canCloseWarehousing ? (
            <CloseWarehousing returningId={Number(id)} />
          ) : (
            <CompleteWarehousing
              locationType="single"
              returningId={Number(id)}
              selectedLocation={scannedLocation}
              counterInProgress={
                skuCounting.skuInProgress
                  ? skuCounting.counter.counterInfo[
                      skuCounting.skuInProgress.counterKey
                    ]
                  : undefined
              }
              resetAfterComplete={resetAfterComplete}
            />
          )}
        </div>
      </Styled.container>

      {ResponseHandlerOfDetail}
      {ResultHandlerOfScanSKU}
      {ResultHandlerOfScanLocation}
    </Layout>
  );
}

export default withRequireAuth(ReturningWarehousingDetail);
