import React, { ReactNode, useCallback, useMemo } from "react";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import {
  TableDataListItem,
  TableRowInfoToHighlight,
} from "@sellernote/_shared/src/headlessComponents/table/useTable";
import { ReceivingItem } from "@sellernote/_shared/src/types/fulfillment/receiving";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import TableForCounting from "@sellernote/_sds-v1/src/components/table/TableForCounting";

import { ConfirmModal } from "hooks/common/useConfirmModal";
import useCountForScanning from "hooks/common/useCountForScanning";
import useGetWorkerByIdFactory from "hooks/common/useGetWorkerByIdFactory";
import useCompleteInspectionByForce from "hooks/returning/useCompleteInspectionByForce";
import useRemoveInspectionPersonInCharge from "hooks/returning/useRemoveInspectionPersonInCharge";
import useResetInspectionOfItem from "hooks/returning/useResetInspectionOfItem";
import {
  CounterDataForInspection,
  SKUCountingForInspection,
} from "hooks/returning/useSKUCountingForInspection";
import useSKUImageInfoModal from "hooks/sku/useSKUImageInfoModal";
import { authSelectors } from "modules/auth";
import { returningSelectors } from "modules/returning";
import { ReceivingInspectionDetailTableItem } from "pages/receiving/inspection/:id/SKUList";
import { useAppSelector } from "store";

import CompleteByForce from "components/CompleteByForce";
import CountForScanning from "components/CountForScanning";
import PersonInCharge from "components/PersonInCharge";
import ProblemInput from "components/ProblemInput";
import ResetCounting from "components/ResetCounting";

import { ReturningGuideModal } from "../useReturningGuide";

type ReturningInspectionDetailTableItem = Omit<
  ReceivingInspectionDetailTableItem,
  "selectMultiLocation"
> & {
  returningGuide: ReactNode;
  problem: ReactNode;
};

function SKUList({
  returningId,
  returningItems,
  rowInfoToHighlight,
  setRowInfoToHighlight,
  skuCounting,
  counterData,
  addCountByInput,
  resetAfterRemoveInProgressPersonInCharge,
  resetCountByCounterKey,
  setConfirmModal,
  returningRequests,
  setReturningGuideModal,
}: {
  returningId: number;
  returningItems: ReceivingItem[] | undefined;
  rowInfoToHighlight: TableRowInfoToHighlight | undefined;
  setRowInfoToHighlight: (val: TableRowInfoToHighlight | undefined) => void;
  skuCounting: SKUCountingForInspection;
  counterData: CounterDataForInspection;
  addCountByInput: ({ id, count }: { id: string; count: number }) => void;
  resetAfterRemoveInProgressPersonInCharge: (counterKey: string) => () => void;
  resetCountByCounterKey: (counterKey: string) => void;
  setConfirmModal: React.Dispatch<
    React.SetStateAction<ConfirmModal | undefined>
  >;
  returningRequests: string | undefined;
  setReturningGuideModal: React.Dispatch<
    React.SetStateAction<ReturningGuideModal | undefined>
  >;
}) {
  const { currentUser, currentUserIsMainManager } = useAppSelector((state) => {
    return {
      currentUser: authSelectors.getCurrentUser(),
      currentUserIsMainManager:
        returningSelectors.getCurrentUserIsReturningManager(state),
    };
  });

  const {
    CountForScanningModal,
    handleDirectInputModalOpen,
    setCountForScanning,
  } = useCountForScanning();

  const {
    fetchImageInfoBySKUId,
    SKUImageInfoModal,
    ResponseHandlerOfGettingSKUInfo,
    ResponseHandlerOfGettingFileURLList,
  } = useSKUImageInfoModal();

  const getWorkerById = useGetWorkerByIdFactory();

  const { removePersonInCharge, ResponseHandlerOfRemovingPersonInCharge } =
    useRemoveInspectionPersonInCharge();

  const {
    resetInspectionOfItem,
    ResponseHandlerOfResetInspectionOfItemExceptInspector,
  } = useResetInspectionOfItem();

  const {
    completeInspectionByForce,
    ResponseHandlerOfCompletingInspectionByForce,
  } = useCompleteInspectionByForce();

  const handleCountAddByDirectInput = useCallback(
    (counterKey: string) => (count: number | undefined) => {
      if (!count) return;

      const target = skuCounting.counter.counterInfo[counterKey];

      addCountByInput({ id: counterKey, count });

      setRowInfoToHighlight({
        rowKey: target.itemId,
      });
    },
    [addCountByInput, setRowInfoToHighlight, skuCounting]
  );

  const resetLocalCount = useCallback(
    ({ counterKey }: { counterKey: string }) =>
      () => {
        resetCountByCounterKey(counterKey);
      },
    [resetCountByCounterKey]
  );

  const tableDataList = useMemo(() => {
    const list = returningItems;

    if (!list) return [];

    const currentUserId = currentUser?.id;

    // 불일치 상품(skuId 가 없는 상품) 필터링 해서 검수 상품을 보여줌.
    return list
      .filter(({ skuId }) => skuId)
      .map((v) => {
        const worker = getWorkerById(v.inspectorId);

        const counterKey = `S${v.sku.id}`;

        const skuId = getFormattedSingleSkuId(v.sku.id);

        const currentTempCount = counterData[counterKey]?.current;
        const currentCount = currentTempCount ?? v.actualQty ?? 0;

        const currentUserIsAssignee = v.inspectorId === currentUserId;
        const completedInspection = v.isCompleteInspection;
        const skuInProgress = v.id === skuCounting.skuInProgress?.itemId;

        const canOpenDirectInputModal =
          currentUserIsAssignee && !completedInspection && skuInProgress;

        const hasRightToReset = currentUserIsAssignee;

        const canReset =
          hasRightToReset && (!!currentCount || v.isCompleteInspection);

        const canCompleteInspectionByForce =
          !completedInspection && currentUserIsMainManager && !currentCount;

        const problemCount = v.inspectProblems.reduce((acc, cur) => {
          return acc + cur.quantity;
        }, 0);

        const mappedReturning: TableDataListItem<ReturningInspectionDetailTableItem> =
          {
            rowKey: v.id,

            SKUId: (
              <div
                onClick={() => {
                  fetchImageInfoBySKUId(v.skuId);
                }}
              >
                {skuId}{" "}
                {v.sku.barCode ? (
                  <>
                    /
                    <br />
                    {v.sku.barCode}
                  </>
                ) : (
                  ""
                )}
              </div>
            ),

            count: (
              <CountForScanning
                // type="inspection"
                canOpenDirectInputModal={canOpenDirectInputModal}
                // skuId={v.skuId}
                currentQty={currentCount}
                quantity={v.quantity}
                // onCountAdd={handleCountAddByDirectInput(counterKey)}
                openDirectInputModal={() => {
                  handleDirectInputModalOpen();
                  setCountForScanning({
                    type: "returningInspection",
                    skuId: v.sku.id,
                    currentQty: currentCount,
                    quantity: v.quantity,
                    minCount: problemCount,
                    onCountAdd: handleCountAddByDirectInput(counterKey),
                  });
                }}
              />
            ),

            problem: (
              <ProblemInput
                itemId={v.id}
                skuId={v.sku.id}
                inspectionCount={currentCount}
                problemCount={problemCount}
                disabled={v.isCompleteInspection || !skuInProgress}
                inputtingCounterInfo={counterData[counterKey]}
              />
            ),

            personInCharge: (
              <PersonInCharge
                worker={worker?.name ?? ""}
                isRemovable={currentUserIsMainManager}
                openConfirmModal={() => {
                  setConfirmModal({
                    uiType: "titleOnly",
                    title: "SKU 담당자를 삭제하시겠습니까?",
                    actions: {
                      actionPositive: {
                        label: "예",
                        handleClick: removePersonInCharge({
                          returningId,
                          skuId: v.sku.id,
                          resetAfterRemoveInProgressPersonInCharge:
                            resetAfterRemoveInProgressPersonInCharge(
                              counterKey
                            ),
                        }),
                      },
                      actionNegative: {
                        label: "아니오",
                        handleClick: () => setConfirmModal(undefined),
                      },
                    },
                  });
                }}
                // removePersonInCharge={removePersonInCharge({
                //   receivingId,
                //   itemId: v.id,
                //   resetAfterRemoveInProgressPersonInCharge:
                //     resetAfterRemoveInProgressPersonInCharge(counterKey),
                // })}
              />
            ),

            status: completedInspection ? "Y" : "N",

            returningGuide: (
              <Button
                label="확인"
                theme="secondary"
                size="small"
                handleClick={() =>
                  setReturningGuideModal({
                    condition: v.sku.returningCondition,
                    requests: returningRequests,
                  })
                }
              />
              // <ReturningGuide
              //   condition={v.sku.returningCondition}
              //   requests={returningRequests}
              // />
            ),

            reset: (
              <ResetCounting
                skuId={v.sku.id}
                canReset={canReset}
                openConfirmModal={() => {
                  setConfirmModal({
                    uiType: "content",
                    title: `${skuId}(SKU ID)`,
                    body: "카운트를 초기화하시겠습니까?",
                    actions: {
                      actionPositive: {
                        label: "예",
                        handleClick: resetInspectionOfItem({
                          returningId,
                          skuId: v.sku.id,
                          resetLocalCount: resetLocalCount({
                            counterKey,
                          }),
                        }),
                      },
                      actionNegative: {
                        label: "아니오",
                        handleClick: () => setConfirmModal(undefined),
                      },
                    },
                  });
                }}
                // reset={resetInspectionOfItem({
                //   receivingId,
                //   itemId: v.id,
                //   resetLocalCount: resetLocalCount({
                //     hasMultiLocation,
                //     inspectItems: v.inspectItems,
                //     skuId: v.sku.id,
                //   }),
                // })}
              />
            ),
            completeByForce: (
              <CompleteByForce
                skuId={v.sku.id}
                canCompleteByForce={canCompleteInspectionByForce}
                openConfirmModal={() => {
                  setConfirmModal({
                    uiType: "titleOnly",
                    title: `강제로 완료처리하시겠습니까? (SKU ID: ${skuId})`,
                    actions: {
                      actionPositive: {
                        label: "예",
                        handleClick: completeInspectionByForce({
                          returningId,
                          skuId: v.sku.id,
                          setConfirmModal,
                        }),
                      },
                      actionNegative: {
                        label: "아니오",
                        handleClick: () => setConfirmModal(undefined),
                      },
                    },
                  });
                }}
                // completeByForce={completeInspectionByForce({
                //   receivingId: receivingId,
                //   skuId: v.sku.id,
                // })}
              />
            ),
            // printBarcode: <PrintBarcode />,
          };

        return mappedReturning;
      });
  }, [
    returningItems,
    currentUser?.id,
    getWorkerById,
    counterData,
    skuCounting,
    currentUserIsMainManager,
    fetchImageInfoBySKUId,
    handleDirectInputModalOpen,
    setCountForScanning,
    handleCountAddByDirectInput,
    setConfirmModal,
    removePersonInCharge,
    returningId,
    resetAfterRemoveInProgressPersonInCharge,
    setReturningGuideModal,
    returningRequests,
    resetInspectionOfItem,
    resetLocalCount,
    completeInspectionByForce,
  ]);

  // function print() {
  //   WebToApp.print();
  // }

  return (
    <>
      <TableForCounting<ReturningInspectionDetailTableItem>
        isWindowed
        height={300}
        rowInfoToHighlight={rowInfoToHighlight}
        columnInfo={{
          SKUId: {
            label: (
              <>
                SKU ID /<br />
                상품바코드
              </>
            ),
            fixedWidth: 130,
          },
          count: {
            label: (
              <>
                검수수량/
                <br />
                요청수량(PCS)
              </>
            ),
            fixedWidth: 150,
          },
          problem: {
            label: "비정상 상품(PCS)",
            fixedWidth: 150,
          },
          personInCharge: {
            label: "담당자",
            fixedWidth: 100,
          },
          status: {
            label: "검품완료여부",
            fixedWidth: 100,
          },
          returningGuide: {
            label: "반품가이드 확인",
            fixedWidth: 120,
          },
          reset: {
            label: "초기화",
            fixedWidth: 110,
          },
          completeByForce: {
            label: "강제처리(카운트: 0)",
            fixedWidth: 140,
          },
          // printBarcode: {
          //   label: "바코드 출력",
          //   fixedWidth: 110,
          // },
        }}
        dataList={tableDataList}
      />

      {CountForScanningModal}

      {SKUImageInfoModal}
      {ResponseHandlerOfGettingFileURLList}
      {ResponseHandlerOfGettingSKUInfo}

      {ResponseHandlerOfRemovingPersonInCharge}
      {ResponseHandlerOfResetInspectionOfItemExceptInspector}
      {ResponseHandlerOfCompletingInspectionByForce}
    </>
  );
}

export default SKUList;
