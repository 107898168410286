/**
 * dev: 단일상품 300만~, 그룹상품 600만~
 * prod: 단일상품 200만~, 그룹상품 500만~
 */
const FIRST_GROUP_SKU_ID_IN_DEV = 6000000;
const FIRST_GROUP_SKU_ID_IN_PROD = 5000000;

const ONE_MEGA_BYTE = 1024 * 1024; // 1MB

const MAX_UPLOAD_FILE_SIZE = 10 * ONE_MEGA_BYTE; // 10MB

export {
  FIRST_GROUP_SKU_ID_IN_DEV,
  FIRST_GROUP_SKU_ID_IN_PROD,
  ONE_MEGA_BYTE,
  MAX_UPLOAD_FILE_SIZE,
};
