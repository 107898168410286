import { useQueryClient } from "react-query";

import {
  MutationSideEffectType,
  useAppMutation,
  useAppQuery,
  useAppQueryWithQueryKeyFactory,
} from "@sellernote/_shared/src/services/query";

import { ResponseFailureInfo } from "../../types/common/common";
import {
  ReceivingList,
  ReceivingListItem,
} from "../../types/fulfillment/receiving";

import {
  QueryResponseHandlerFailureModalInfo,
  QueryResponseHandlerSuccessModalInfo,
} from "../../components/QueryResponseHandler";

import {
  ADD_PARTIAL_INSPECTION_REQ,
  ADD_PARTIAL_WAREHOUSING_REQ,
  AGREE_RECEIVING_ITEM_PATH_PARAMS,
  ASSIGN_INSPECTOR_TO_ITEM_REQ,
  ASSIGN_INSPECTOR_TO_ITEM_REQ_PATH_PARAMS,
  ASSIGN_PLACER_TO_ITEM_REQ,
  ASSIGN_PLACER_TO_ITEM_REQ_PATH_PARAMS,
  CHANGE_TOTAL_PACKING_UNIT_REQ,
  GET_ADMIN_RECEIVING_LIST_REQ,
  GET_ADMIN_RECEIVING_LIST_RES,
  GET_BID_LIST_REQ_PATH_PARAMS,
  GET_BID_LIST_RES,
  GET_DETAIL_RES_DATA,
  GET_MANAGER_RECEIVING_LIST_FOR_INSPECTION_REQ,
  GET_MANAGER_RECEIVING_LIST_REQ,
  GET_RECEIVING_DETAIL_BY_SKU_ID_REQ_PATH_PARAMS,
  GET_RECEIVING_DETAIL_BY_SKU_ID_RES,
  GET_RECEIVING_SEARCH_SUGGESTION_LIST_REQ,
  GET_RECEIVING_SEARCH_SUGGESTION_LIST_RES,
  GET_USER_RECEIVING_ITEM_REQ_PATH_PARAMS,
  GET_USER_RECEIVING_ITEM_RES,
  GET_USER_RECEIVING_LIST_FOR_CSV_DOWNLOAD_REQ,
  GET_USER_RECEIVING_LIST_FOR_CSV_DOWNLOAD_RES,
  GET_USER_RECEIVING_LIST_REQ,
  GET_USER_RECEIVING_LIST_RES,
  MODIFY_EXPECTED_DATE_REQ,
  MODIFY_EXPECTED_DATE_REQ_PATH_PARAMS,
  REMOVE_PARTIAL_INSPECTION_REQ,
  REMOVE_PARTIAL_INSPECTION_REQ_PATH_PARAMS,
  REMOVE_PARTIAL_WAREHOUSING_REQ,
  REMOVE_PARTIAL_WAREHOUSING_REQ_PATH_PARAMS,
  REPORT_INSPECTION_PROBLEM_REQ,
  REPORT_WAREHOUSING_PROBLEM_REQ,
  RESET_INSPECTION_OF_ITEM_EXPECT_INSPECTOR_REQ,
  RESET_INSPECTION_OF_ITEM_EXPECT_INSPECTOR_REQ_PATH_PARAMS,
  RESET_INSPECTOR_AND_INSPECTION_OF_ITEM_REQ,
  RESET_INSPECTOR_AND_INSPECTION_OF_ITEM_REQ_PATH_PARAMS,
  RESET_PLACER_AND_WAREHOUSING_OF_ITEM_REQ,
  RESET_PLACER_AND_WAREHOUSING_OF_ITEM_REQ_PATH_PARAMS,
  RESET_WAREHOUSING_OF_ITEM_EXPECT_PLACER_REQ,
  RESET_WAREHOUSING_OF_ITEM_EXPECT_PLACER_REQ_PARAMS,
  SET_INSPECTION_DONE_REQ,
  SET_INSPECTION_STARTED_REQ_PATH_PARAMS,
  SET_INVOICE_SCAN_DONE_REQ,
  SET_ITEM_INSPECTION_DONE_BY_FORCE_REQ_PATH_PARAMS,
  SET_ITEM_INSPECTION_DONE_REQ,
  SET_ITEM_INSPECTION_DONE_REQ_PATH_PARAMS,
  SET_ITEM_WAREHOUSING_DONE_BY_FORCE_REQ_PATH_PARAMS,
  SET_ITEM_WAREHOUSING_DONE_REQ,
  SET_ITEM_WAREHOUSING_DONE_REQ_PATH_PARAMS,
  SET_WAREHOUSING_DONE_REQ,
  UPDATE_ADMIN_RECEIVING_DETAIL_REQ,
  UPDATE_ADMIN_RECEIVING_DETAIL_REQ_PATH_PARAMS,
  UPDATE_TRUCK_INFO_REQ,
  UPDATE_TRUCK_INFO_RES,
  VALIDATE_RECEIVING_IN_SKU_EXCEL_REQ,
  VALIDATE_RECEIVING_IN_SKU_EXCEL_RES,
} from "../../api-interfaces/boful-api/receiving";

export const RECEIVING_QUERY_KEY_GEN = {
  all: () => [{ scope: "fulfillment/RECEIVING_QUERY" }] as const,
  receivingList: () =>
    [
      { ...RECEIVING_QUERY_KEY_GEN.all()[0], subScope: "receivingList" },
    ] as const,
  getReceivingList: (params: GET_USER_RECEIVING_LIST_REQ) =>
    [
      {
        ...RECEIVING_QUERY_KEY_GEN.receivingList()[0],
        ...params,
      },
    ] as const,

  getUserReceivingListForCSVDownload: (
    params: GET_USER_RECEIVING_LIST_FOR_CSV_DOWNLOAD_REQ
  ) =>
    [
      {
        ...RECEIVING_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "userReceivingListForCSVDownload",
      },
    ] as const,
  getUserReceivingItemDetail: (
    params: GET_USER_RECEIVING_ITEM_REQ_PATH_PARAMS
  ) =>
    [
      {
        ...RECEIVING_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "userReceivingItemDetail",
      },
    ] as const,
  getAdminReceivingList: (params: GET_ADMIN_RECEIVING_LIST_REQ) =>
    [
      {
        ...RECEIVING_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "adminReceivingList",
      },
    ] as const,

  getManagerReceivingList: (params: GET_MANAGER_RECEIVING_LIST_REQ) =>
    [
      {
        ...RECEIVING_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "managerReceivingList",
      },
    ] as const,

  getManagerReceivingDetail: (params: { receivingId: number }) =>
    [
      {
        ...RECEIVING_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "receivingDetail",
      },
    ] as const,

  getManagerReceivingDetailToCheck: (params: { receivingId: number }) =>
    [
      {
        ...RECEIVING_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "receivingDetailToCheck",
      },
    ] as const,

  getManagerReceivingListForInspection: (
    params: GET_MANAGER_RECEIVING_LIST_FOR_INSPECTION_REQ
  ) =>
    [
      {
        ...RECEIVING_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "managerReceivingListForInspection",
      },
    ] as const,

  getReceivingDetailBySkuId: (
    params: Partial<GET_RECEIVING_DETAIL_BY_SKU_ID_REQ_PATH_PARAMS>
  ) =>
    [
      {
        ...RECEIVING_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "receivingDetailBySkuId",
      },
    ] as const,

  getReceivingSearchSuggestionList: (
    params: GET_RECEIVING_SEARCH_SUGGESTION_LIST_REQ
  ) =>
    [
      {
        ...RECEIVING_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "receivingSearchSuggestionList",
      },
    ] as const,
  getBidList: (params: GET_BID_LIST_REQ_PATH_PARAMS) =>
    [
      {
        ...RECEIVING_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "bidList",
      },
    ] as const,
};

function useGetAdminReceivingList({
  onSuccess,
  ...params
}: GET_ADMIN_RECEIVING_LIST_REQ & {
  onSuccess?: (data: GET_ADMIN_RECEIVING_LIST_RES) => void;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof RECEIVING_QUERY_KEY_GEN.getAdminReceivingList>,
    GET_ADMIN_RECEIVING_LIST_RES
  >({
    queryKey: RECEIVING_QUERY_KEY_GEN.getAdminReceivingList(params),
    requestOptions: {
      method: "get",
      path: "/receiving/admin/v2",
      apiType: "BofulDefault",
      params,
    },

    keepPreviousData: true,

    onSuccess,
  });

  return { ...queryResult };
}

function useGetUserReceivingList({
  enabled,
  ...params
}: GET_USER_RECEIVING_LIST_REQ & { enabled?: boolean }) {
  const queryResult = useAppQuery<GET_USER_RECEIVING_LIST_RES>({
    queryKey: RECEIVING_QUERY_KEY_GEN.getReceivingList(params),
    requestOptions: {
      method: "get",
      path: "/receiving/user/list",
      params,
      apiType: "BofulDefault",
    },

    keepPreviousData: true,

    enabled,
  });

  return { ...queryResult };
}

function useGetUserReceivingListForCSVDownload({
  enabled,
  ...params
}: GET_USER_RECEIVING_LIST_FOR_CSV_DOWNLOAD_REQ & {
  enabled?: boolean;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<
      typeof RECEIVING_QUERY_KEY_GEN.getUserReceivingListForCSVDownload
    >,
    GET_USER_RECEIVING_LIST_FOR_CSV_DOWNLOAD_RES
  >({
    queryKey:
      RECEIVING_QUERY_KEY_GEN.getUserReceivingListForCSVDownload(params),
    requestOptions: {
      method: "get",
      path: "/receiving/user/list",
      apiType: "BofulDefault",
      params,
    },

    ...(typeof enabled === "boolean" ? { enabled } : {}),
  });

  return { ...queryResult };
}

function useGetUserReceivingItemDetail({
  id,
  enabled = true,
  onError,
}: GET_USER_RECEIVING_ITEM_REQ_PATH_PARAMS & {
  enabled?: boolean;
  onError?: (
    error: ResponseFailureInfo | undefined,
    hideFailureModal: () => void
  ) => void;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof RECEIVING_QUERY_KEY_GEN.getUserReceivingItemDetail>,
    GET_USER_RECEIVING_ITEM_RES
  >({
    queryKey: RECEIVING_QUERY_KEY_GEN.getUserReceivingItemDetail({ id }),
    requestOptions: {
      method: "get",
      path: `/receiving/${id}`,
      apiType: "BofulDefault",
    },
    enabled: id !== 0 && enabled,

    onError,
  });

  return { ...queryResult };
}

function useCreateReceivingItem(
  sideEffectOptions?: MutationSideEffectType<any, any>
) {
  const queryClient = useQueryClient();

  const mutation = useAppMutation<any, any>({
    requestOptions: {
      method: "post",
      path: `/receiving/`,
      apiType: "BofulDefault",
    },
    ...sideEffectOptions,

    onSuccess: () => {
      queryClient.invalidateQueries(RECEIVING_QUERY_KEY_GEN.receivingList());
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "입고 등록 중 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

function useUpdateReceivingItem(
  { receivingId }: { receivingId: number },
  sideEffectOptions?: MutationSideEffectType<any, any>
) {
  const queryClient = useQueryClient();

  const mutation = useAppMutation<any, any>({
    requestOptions: {
      method: "patch",
      path: `/receiving/${receivingId}`,
      apiType: "BofulDefault",
    },
    ...sideEffectOptions,

    onSuccess: () => {
      queryClient.invalidateQueries(RECEIVING_QUERY_KEY_GEN.receivingList());
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "입고 수정 중 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

function useCancelReceivingItem(
  sideEffectOptions?: MutationSideEffectType<any, any>
) {
  const queryClient = useQueryClient();

  const mutation = useAppMutation<any, any>({
    requestOptions: {
      method: "delete",
      path: `/receiving`,
      apiType: "BofulDefault",
    },
    ...sideEffectOptions,

    onSuccess: () => {
      queryClient.invalidateQueries(RECEIVING_QUERY_KEY_GEN.receivingList());
    },

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        if (failureInfo?.errorCode === "E3010")
          return {
            title: "[현황: 도착 전] 건만 취소 가능합니다.",
            messageType: "titleOnly",
          };

        return { title: "입고 취소 중 오류가 발생했습니다." };
      },
    },
  });

  return { ...mutation };
}

function useUpdateTruckInfo(
  { receivingId }: { receivingId: number },
  sideEffectOptions?: MutationSideEffectType<
    UPDATE_TRUCK_INFO_REQ,
    UPDATE_TRUCK_INFO_RES
  >
) {
  const mutation = useAppMutation<UPDATE_TRUCK_INFO_REQ, UPDATE_TRUCK_INFO_RES>(
    {
      requestOptions: {
        method: "patch",
        path: `/receiving/addMoreInfo/${receivingId}`,
        apiType: "BofulDefault",
      },

      ...sideEffectOptions,

      failureModalInfo: {
        customizeMessage: () => ({
          title: "기사 및 차량정보 등록 중에 오류가 발생했습니다.",
        }),
      },
    }
  );

  return { ...mutation };
}

function useGetManagerReceivingList(params: GET_MANAGER_RECEIVING_LIST_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof RECEIVING_QUERY_KEY_GEN.getManagerReceivingList>,
    ReceivingList
  >({
    queryKey: RECEIVING_QUERY_KEY_GEN.getManagerReceivingList(params),
    requestOptions: {
      method: "get",
      path: "/receiving/manager/list",
      apiType: "BofulDefault",
      params,
    },

    keepPreviousData: true,

    failureModalInfo: {
      customizeMessage: () => ({
        title: `입고 리스트 조회 중에 오류가 발생했습니다.`,
      }),
    },
  });

  return { ...queryResult };
}

function useGetManagerReceivingDetail({
  receivingId,
  enabled,
  onSuccess,
  onError,
}: {
  receivingId: number;
  enabled?: boolean;
  onSuccess?: ((data: GET_DETAIL_RES_DATA) => void) | undefined;
  onError?: (
    error: ResponseFailureInfo | undefined,
    hideFailureModal: () => void
  ) => void;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof RECEIVING_QUERY_KEY_GEN.getManagerReceivingDetail>,
    GET_DETAIL_RES_DATA
  >({
    queryKey: RECEIVING_QUERY_KEY_GEN.getManagerReceivingDetail({
      receivingId,
    }),
    requestOptions: {
      method: "get",
      path: `/receiving/${receivingId}`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: `입고 상세 정보 조회 중에 오류가 발생했습니다.`,
      }),
    },

    enabled,

    onSuccess,
    onError,
  });

  return { ...queryResult };
}

/**
 * 상세 정보 조회 API를 호출하나, 전체 리렌더링을 막기 위해 별도의 확인용 hook를 사용
 */
function useGetManagerReceivingDetailToCheck({
  receivingId,
  enabled,
}: {
  receivingId: number;
  enabled?: boolean;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof RECEIVING_QUERY_KEY_GEN.getManagerReceivingDetailToCheck>,
    GET_DETAIL_RES_DATA
  >({
    queryKey: RECEIVING_QUERY_KEY_GEN.getManagerReceivingDetailToCheck({
      receivingId,
    }),
    requestOptions: {
      method: "get",
      path: `/receiving/${receivingId}`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "입고 상세 정보 조회 중에 오류가 발생했습니다.",
      }),
    },

    enabled,
  });

  return { ...queryResult };
}

function useGetManagerReceivingListForInspection(
  params: GET_MANAGER_RECEIVING_LIST_FOR_INSPECTION_REQ
) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<
      typeof RECEIVING_QUERY_KEY_GEN.getManagerReceivingListForInspection
    >,
    ReceivingList
  >({
    queryKey:
      RECEIVING_QUERY_KEY_GEN.getManagerReceivingListForInspection(params),
    requestOptions: {
      method: "get",
      path: "/receiving/manager/list/inspection",
      apiType: "BofulDefault",
      params,
    },

    keepPreviousData: true,

    failureModalInfo: {
      customizeMessage: () => ({
        title: `검수 리스트 조회 중에 오류가 발생했습니다.`,
      }),
    },
  });

  return { ...queryResult };
}

function useAssignInspectorToItem() {
  const mutation = useAppMutation<
    ASSIGN_INSPECTOR_TO_ITEM_REQ,
    ReceivingListItem,
    ASSIGN_INSPECTOR_TO_ITEM_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "patch",
      path: ({ receivingId, itemId }) =>
        `/receiving/${receivingId}/inspection/assign/${itemId}`,
      apiType: "BofulDefault",
    },

    loadingLabel: "검수 작업자 지정 중",
    failureModalInfo: {
      customizeMessage: () => ({
        title: "검수 담당자 지정 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

function useSetInspectionStarted() {
  const mutation = useAppMutation<
    unknown,
    ReceivingListItem,
    SET_INSPECTION_STARTED_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "patch",
      path: ({ receivingId }) => `/receiving/${receivingId}/inspection/start`,
      apiType: "BofulDefault",
    },

    loadingLabel: "검수 시작 중",
    failureModalInfo: {
      customizeMessage: () => ({
        title: "검수 시작 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

/**
 * 검수/분할검수에 대한 담당자 정보 및 검수관련 정보 초기화
 */
function useResetInspectorAndInspectionOfItem() {
  const mutation = useAppMutation<
    RESET_INSPECTOR_AND_INSPECTION_OF_ITEM_REQ,
    ReceivingListItem,
    RESET_INSPECTOR_AND_INSPECTION_OF_ITEM_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "patch",
      path: ({ receivingId, itemId }) =>
        `/receiving/${receivingId}/inspection/reset/${itemId}/worker`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "검수 담당자 삭제 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

/**
 * 검수/분할검수에 대한 담당자를 제외한 검수관련 정보 초기화
 */
function useResetInspectionOfItemExceptInspector() {
  const mutation = useAppMutation<
    RESET_INSPECTION_OF_ITEM_EXPECT_INSPECTOR_REQ,
    ReceivingListItem,
    RESET_INSPECTION_OF_ITEM_EXPECT_INSPECTOR_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "patch",
      path: ({ receivingId, itemId }) =>
        `/receiving/${receivingId}/inspection/reset/${itemId}`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "검수 카운트 초기화 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

/**
 * 검수 강제 완료처리
 */
function useSetItemInspectionDoneByForce() {
  const mutation = useAppMutation<
    unknown,
    ReceivingListItem,
    SET_ITEM_INSPECTION_DONE_BY_FORCE_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "patch",
      path: ({ receivingId, skuId }) =>
        `/receiving/${receivingId}/inspection/done/force/${skuId}`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "검수 강제처리 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

/**
 * 검수/분할검수 완료
 */
function useSetItemInspectionDone({
  locationType,
}: {
  locationType: "single" | "multi";
}) {
  const mutation = useAppMutation<
    SET_ITEM_INSPECTION_DONE_REQ,
    ReceivingListItem,
    SET_ITEM_INSPECTION_DONE_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "patch",
      path: ({ receivingId }) =>
        `/receiving/${receivingId}/inspection/done/worker`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: `${
          locationType === "single" ? "검수 완료" : "분할검수 완료"
        } 중에 오류가 발생했습니다.`,
      }),
    },
  });

  return { ...mutation };
}

/**
 * 분할검수 내역을 추가
 * 기존에 inspecItems가 한 개 뿐이면서 quantity가 max인 경우 (사실상 단일검수),
 * 새 분할검수를 추가하면 기존 inspectItems는 사라지고 새로 추가한 것만 남는다.
 */
function useAddPartialInspection({
  receivingId,
  type,
}: {
  receivingId: number;
  type: "add" | "AfterRemove";
}) {
  const mutation = useAppMutation<
    ADD_PARTIAL_INSPECTION_REQ,
    ReceivingListItem
  >({
    requestOptions: {
      method: "patch",
      path: `/receiving/${receivingId}/inspection/add/partial`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: `${
          type === "add"
            ? "분할검수 추가 중에 오류가 발생했습니다."
            : "일반검수 생성 중에 오류가 발생했습니다."
        } `,
      }),
    },
  });

  return { ...mutation };
}

/**
 * 분할검수 내역을 삭제
 */
function useRemovePartialInspection() {
  const mutation = useAppMutation<
    REMOVE_PARTIAL_INSPECTION_REQ,
    ReceivingListItem,
    REMOVE_PARTIAL_INSPECTION_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "delete",
      path: ({ receivingId, itemId }) =>
        `/receiving/${receivingId}/inspection/delete/partial/${itemId}`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "분할검수 내역 삭제 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

/**
 * 검수 담당자 추가
 */
function useAddWorkerToInspection({
  receivingId,
  workerId,
}: {
  receivingId: number;
  workerId: number;
}) {
  const mutation = useAppMutation<void, ReceivingListItem>({
    requestOptions: {
      method: "patch",
      path: `/receiving/${receivingId}/assign/worker/${workerId}`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "검수 담당자 추가 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

/**
 * 검수 담당자 삭제
 */
function useRemoveWorkerFromInspection({
  receivingId,
  workerId,
}: {
  receivingId: number;
  workerId: number;
}) {
  const mutation = useAppMutation<void, ReceivingListItem>({
    requestOptions: {
      method: "patch",
      path: `/receiving/${receivingId}/assign/cancel/${workerId}`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "검수 담당자에서 제외시키는 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

/**
 * 검수 마감
 */
function useSetInspectionDone({
  receivingId,
  successModalInfo,
}: {
  receivingId: number;
  successModalInfo?: QueryResponseHandlerSuccessModalInfo<ReceivingListItem>;
}) {
  const mutation = useAppMutation<SET_INSPECTION_DONE_REQ, ReceivingListItem>({
    requestOptions: {
      method: "patch",
      path: `/receiving/${receivingId}/inspection/done`,
      apiType: "BofulDefault",
    },

    successModalInfo,

    failureModalInfo: {
      customizeMessage: () => ({
        title: "검수 마감 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

/**
 * 검수 문제보고
 */
function useReportInspectionProblem({ receivingId }: { receivingId: number }) {
  const mutation = useAppMutation<
    REPORT_INSPECTION_PROBLEM_REQ,
    ReceivingListItem
  >({
    requestOptions: {
      method: "patch",
      path: `/receiving/${receivingId}/report/inspection `,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "검수 문제보고 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

/**
 * 특정 SKU에 대한 입고담당자 할당(분할입고 포함)
 */
function useAssignPlacerToItem() {
  const mutation = useAppMutation<
    ASSIGN_PLACER_TO_ITEM_REQ,
    ReceivingListItem,
    ASSIGN_PLACER_TO_ITEM_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "patch",
      path: ({ receivingId, itemId }) =>
        `/receiving/${receivingId}/putAway/assign/${itemId}`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "입고 담당자 지정 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

/**
 * 입고/분할입고에 대한 담당자 정보 및 입고관련 정보 초기화
 */
function useResetPlacerAndWarehousingOfItem() {
  const mutation = useAppMutation<
    RESET_PLACER_AND_WAREHOUSING_OF_ITEM_REQ,
    ReceivingListItem,
    RESET_PLACER_AND_WAREHOUSING_OF_ITEM_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "patch",
      path: ({ receivingId, itemId }) =>
        `/receiving/${receivingId}/putAway/reset/${itemId}/worker`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "입고 담당자 삭제 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

/**
 * 입고/분할입고에 대한 담당자를 제외한 입고관련 정보 초기화
 */
function useResetWarehousingOfItemExceptPlacer() {
  const mutation = useAppMutation<
    RESET_WAREHOUSING_OF_ITEM_EXPECT_PLACER_REQ,
    ReceivingListItem,
    RESET_WAREHOUSING_OF_ITEM_EXPECT_PLACER_REQ_PARAMS
  >({
    requestOptions: {
      method: "patch",
      path: ({ receivingId, itemId }) =>
        `/receiving/${receivingId}/putAway/reset/${itemId}`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "입고 카운트 초기화 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

/**
 * 입고 강제 완료처리
 */
function useSetItemWarehousingDoneByForce() {
  const mutation = useAppMutation<
    unknown,
    ReceivingListItem,
    SET_ITEM_WAREHOUSING_DONE_BY_FORCE_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "patch",
      path: ({ receivingId, skuId }) =>
        `/receiving/${receivingId}/putawayItem/force/${skuId}`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "입고 강제처리 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

/**
 * 입고/분할입고 완료
 */
function useSetItemWarehousingDone({
  locationType,
}: {
  locationType: "single" | "multi";
}) {
  const mutation = useAppMutation<
    SET_ITEM_WAREHOUSING_DONE_REQ,
    ReceivingListItem,
    SET_ITEM_WAREHOUSING_DONE_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "patch",
      path: ({ receivingId }) => `/receiving/${receivingId}/putawayItem`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: `${
          locationType === "single" ? "입고 완료" : "분할입고 완료"
        } 중에 오류가 발생했습니다.`,
      }),
    },
  });

  return { ...mutation };
}

/**
 * 분할입고 내역을 추가
 * 기존에 placeItems가 한 개 뿐이면서 quantity가 max인 경우 (사실상 단일입고),
 * 새 분할입고를 추가하면 기존 placeItems는 사라지고 새로 추가한 것만 남는다.
 */
function useAddPartialWarehousing({
  receivingId,
  type,
}: {
  receivingId: number;
  type: "add" | "AfterRemove";
}) {
  const mutation = useAppMutation<
    ADD_PARTIAL_WAREHOUSING_REQ,
    ReceivingListItem
  >({
    requestOptions: {
      method: "patch",
      path: `/receiving/${receivingId}/add/partial`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: `${
          type === "add"
            ? "분할입고 추가 중에 오류가 발생했습니다."
            : "일반입고 생성 중에 오류가 발생했습니다."
        } `,
      }),
    },
  });

  return { ...mutation };
}

/**
 * 분할입고 내역을 삭제
 */
function useRemovePartialWarehousing() {
  const mutation = useAppMutation<
    REMOVE_PARTIAL_WAREHOUSING_REQ,
    ReceivingListItem,
    REMOVE_PARTIAL_WAREHOUSING_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "delete",
      path: ({ receivingId, itemId }) =>
        `/receiving/${receivingId}/delete/partial/${itemId}`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "분할입고 내역 삭제 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

/**
 * 입고 마감
 */
function useSetWarehousingDone({
  receivingId,
  successModalInfo,
}: {
  receivingId: number;
  successModalInfo?: QueryResponseHandlerSuccessModalInfo<ReceivingListItem>;
}) {
  const mutation = useAppMutation<SET_WAREHOUSING_DONE_REQ, ReceivingListItem>({
    requestOptions: {
      method: "patch",
      path: `/receiving/${receivingId}/done`,
      apiType: "BofulDefault",
    },

    successModalInfo,

    failureModalInfo: {
      customizeMessage: () => ({
        title: "입고 마감 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

/**
 * 입고 문제보고
 */
function useReportWarehousingProblem({ receivingId }: { receivingId: number }) {
  const mutation = useAppMutation<
    REPORT_WAREHOUSING_PROBLEM_REQ,
    ReceivingListItem
  >({
    requestOptions: {
      method: "patch",
      path: `/receiving/${receivingId}/report/receiving `,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "입고 문제보고 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

/**
 * My의뢰로 이동
 */
function useAssignToMe({
  itemId,
  successModalInfo,
}: {
  itemId: number;
  successModalInfo: QueryResponseHandlerSuccessModalInfo<ReceivingListItem>;
}) {
  const mutation = useAppMutation<void, ReceivingListItem>({
    requestOptions: {
      method: "patch",
      path: `/receiving/${itemId}/assignToMe`,
      apiType: "BofulDefault",
    },

    successModalInfo,

    failureModalInfo: {
      customizeMessage: () => ({
        title: "My의뢰로 이동시키는 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

/**
 * PDA > 총 포장 개수 수정
 */
function useChangeTotalPackingUnit({ receivingId }: { receivingId: number }) {
  const mutation = useAppMutation<
    CHANGE_TOTAL_PACKING_UNIT_REQ,
    ReceivingListItem
  >({
    requestOptions: {
      method: "patch",
      path: `/receiving/${receivingId}`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "총 포장 개수 수정 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

/**
 * PDA > 송장 스캔 완료
 */
function useSetInvoiceScanDone({
  receivingId,
  successModalInfo,
}: {
  receivingId: number;
  successModalInfo: QueryResponseHandlerSuccessModalInfo<ReceivingListItem>;
}) {
  const mutation = useAppMutation<SET_INVOICE_SCAN_DONE_REQ, ReceivingListItem>(
    {
      requestOptions: {
        method: "patch",
        path: `/receiving/${receivingId}/scanInvoice/done`,
        apiType: "BofulDefault",
      },

      successModalInfo,

      failureModalInfo: {
        customizeMessage: () => ({
          title: "송장 스캔 완료 중에 오류가 발생했습니다.",
        }),
      },
    }
  );

  return { ...mutation };
}

/**
 * PDA > 송장확인과 관련된 정보(isInvoiceMatched, 송장 사진)를 초기화
 */
function useResetInvoiceInfo({ receivingId }: { receivingId: number }) {
  const mutation = useAppMutation<void, ReceivingListItem>({
    requestOptions: {
      method: "patch",
      path: `/receiving/${receivingId}/scanInvoice/reset`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "송장 스캔 초기화 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

/**
 * PDA > 제품 수령 완료
 */
function useSetArrived({ receivingId }: { receivingId: number }) {
  const mutation = useAppMutation<void, ReceivingListItem>({
    requestOptions: {
      method: "patch",
      path: `/receiving/${receivingId}/arrive`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "제품 수령 완료 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

function useModifyExpectedDate({
  successModalInfo,
}: {
  successModalInfo?: QueryResponseHandlerSuccessModalInfo<ReceivingListItem>;
}) {
  const mutation = useAppMutation<
    MODIFY_EXPECTED_DATE_REQ,
    ReceivingListItem,
    MODIFY_EXPECTED_DATE_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "patch",
      path: ({ receivingOrBidId }) => `/receiving/admin/${receivingOrBidId}`,
      apiType: "BofulDefault",
    },

    successModalInfo,

    failureModalInfo: {
      customizeMessage: () => ({
        title: "입고 도착일 수정 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

function useGetReceivingDetailBySkuId({
  skuId,
  enabled,
}: GET_RECEIVING_DETAIL_BY_SKU_ID_REQ_PATH_PARAMS & {
  enabled: boolean;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof RECEIVING_QUERY_KEY_GEN.getReceivingDetailBySkuId>,
    GET_RECEIVING_DETAIL_BY_SKU_ID_RES
  >({
    queryKey: RECEIVING_QUERY_KEY_GEN.getReceivingDetailBySkuId({ skuId }),
    requestOptions: {
      method: "get",
      path: `/receiving/admin/sku/${skuId}`,
      apiType: "BofulDefault",
    },

    enabled,

    failureModalInfo: {
      customizeMessage: () => ({
        title: `입고 내역 조회 중에 오류가 발생했습니다.`,
      }),
    },
  });

  return { ...queryResult };
}

function useUpdateAdminReceivingDetail({
  failureModalInfo,
}: {
  failureModalInfo?: QueryResponseHandlerFailureModalInfo | undefined;
}) {
  const mutation = useAppMutation<
    UPDATE_ADMIN_RECEIVING_DETAIL_REQ,
    ReceivingListItem,
    UPDATE_ADMIN_RECEIVING_DETAIL_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "patch",
      path: ({ receivingId }) => `/receiving/admin/${receivingId}`,
      apiType: "BofulDefault",
    },

    failureModalInfo,
  });

  return { ...mutation };
}

function useGetReceivingSearchSuggestionList({
  enabled,
  ...params
}: {
  enabled?: boolean;
} & GET_RECEIVING_SEARCH_SUGGESTION_LIST_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof RECEIVING_QUERY_KEY_GEN.getReceivingSearchSuggestionList>,
    GET_RECEIVING_SEARCH_SUGGESTION_LIST_RES
  >({
    queryKey: RECEIVING_QUERY_KEY_GEN.getReceivingSearchSuggestionList(params),
    requestOptions: {
      method: "get",
      path: "/search/suggestion/receiving",
      apiType: "BofulDefault",
      params,
    },

    enabled,
  });

  return { ...queryResult };
}

function useAgreeReceivingItem({
  successModalInfo,
  failureModalInfo,
}: {
  successModalInfo?: QueryResponseHandlerSuccessModalInfo;
  failureModalInfo?: QueryResponseHandlerFailureModalInfo;
}) {
  const mutation = useAppMutation<
    unknown,
    ReceivingListItem,
    AGREE_RECEIVING_ITEM_PATH_PARAMS
  >({
    requestOptions: {
      method: "patch",
      path: ({ id }) => `/receiving/${id}/agree`,
      apiType: "BofulDefault",
    },
    successModalInfo,
    failureModalInfo,
  });

  return { ...mutation };
}

function useGetBidList({
  enabled,
  params,
}: {
  enabled?: boolean;
  params: GET_BID_LIST_REQ_PATH_PARAMS;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof RECEIVING_QUERY_KEY_GEN.getBidList>,
    GET_BID_LIST_RES
  >({
    queryKey: RECEIVING_QUERY_KEY_GEN.getBidList(params),
    requestOptions: {
      method: "get",
      path: "/bid/boful/list",
      apiType: "ShipdaDefaultNew",
      params,
    },
    enabled,
  });

  return { ...queryResult };
}

function useValidateReceivingInSkuExcel({
  onSuccess,
  failureModalInfo,
}: {
  onSuccess: () => void;
  failureModalInfo?: QueryResponseHandlerFailureModalInfo;
}) {
  const mutation = useAppMutation<
    VALIDATE_RECEIVING_IN_SKU_EXCEL_REQ,
    VALIDATE_RECEIVING_IN_SKU_EXCEL_RES
  >({
    requestOptions: {
      method: "post",
      path: "/sku/excel/validate/receiving",
      apiType: "BofulDefault",
    },

    onSuccess,

    failureModalInfo,
  });

  return mutation;
}

const RECEIVING_QUERY = {
  useGetUserReceivingList,
  useGetUserReceivingListForCSVDownload,

  useGetUserReceivingItemDetail,

  useCreateReceivingItem,
  useUpdateReceivingItem,
  useCancelReceivingItem,
  useUpdateTruckInfo,

  useGetAdminReceivingList,

  useGetManagerReceivingList,
  useGetManagerReceivingDetail,
  useGetManagerReceivingDetailToCheck,
  useGetManagerReceivingListForInspection,
  useAssignInspectorToItem,
  useSetInspectionStarted,
  useResetInspectorAndInspectionOfItem,
  useResetInspectionOfItemExceptInspector,
  useSetItemInspectionDoneByForce,
  useSetItemInspectionDone,
  useAddPartialInspection,
  useRemovePartialInspection,
  useAddWorkerToInspection,
  useRemoveWorkerFromInspection,
  useSetInspectionDone,
  useReportInspectionProblem,

  useAssignPlacerToItem,
  useResetPlacerAndWarehousingOfItem,
  useResetWarehousingOfItemExceptPlacer,
  useSetItemWarehousingDoneByForce,
  useSetItemWarehousingDone,
  useAddPartialWarehousing,
  useRemovePartialWarehousing,
  useSetWarehousingDone,
  useReportWarehousingProblem,

  useAssignToMe,
  useChangeTotalPackingUnit,
  useSetInvoiceScanDone,
  useResetInvoiceInfo,
  useSetArrived,

  useModifyExpectedDate,

  useGetReceivingDetailBySkuId,

  useUpdateAdminReceivingDetail,

  useGetReceivingSearchSuggestionList,

  useAgreeReceivingItem,

  useGetBidList,

  useValidateReceivingInSkuExcel,
};

export default RECEIVING_QUERY;
