import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSetRecoilState } from "recoil";

import { FULFILLMENT_COMMON_ATOMS } from "@sellernote/_shared/src/states/fulfillment/common";
import { ReceivingItem } from "@sellernote/_shared/src/types/fulfillment/receiving";
import {
  useCounter,
  UseCounterData,
  UseCounterDataValue,
  UseCounterReturn,
} from "@sellernote/_shared/src/utils/common/hook";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";

export interface InspectionCounterSKU {
  counterKey: string;
  itemId: number;
  skuId: number;
  skuBarcode: string | undefined; // 상품 바코드
  inspectorId?: number;
  isCompleteInspection?: boolean;
}

type CounterForInspection = UseCounterReturn<InspectionCounterSKU>;
export type CounterDataForInspection = UseCounterData<InspectionCounterSKU>;

export interface SKUCountingForInspection {
  skuInProgress?: InspectionCounterSKU;
  setSkuInProgress: (val?: InspectionCounterSKU) => void;
  totalCount: string;
  counter: CounterForInspection;
  reset: (items: ReceivingItem[]) => void;
}

export function getCounterKeyFromScanResult({
  counterData,
  scanResult,
}: {
  counterData: SKUCountingForInspection;
  scanResult: string;
}) {
  return Object.values(counterData.counter.counterInfo).find(
    (counter) =>
      getFormattedSingleSkuId(counter.skuId) === scanResult ||
      counter.skuBarcode === scanResult
  )?.counterKey;
}

export default function useSKUCountingForInspection({
  items,
  setRowInfoToHighlight,
  unverifiedCount,
}: {
  items?: ReceivingItem[];
  setRowInfoToHighlight: ({ rowKey }: { rowKey: number }) => void;
  unverifiedCount: number;
}): SKUCountingForInspection {
  const setCanNotLeavePage = useSetRecoilState(
    FULFILLMENT_COMMON_ATOMS.CAN_NOT_LEAVE_PAGE
  );

  const location = useLocation();

  const state = location.state as
    | {
        inputtingCounterInfo: UseCounterDataValue<InspectionCounterSKU>;
      }
    | undefined;

  const counter = useCounter<InspectionCounterSKU>();

  const [skuInProgress, setSkuInProgress] = useState<InspectionCounterSKU>();

  const setScanInprogressState = useCallback(
    (counterInfo?: UseCounterDataValue<InspectionCounterSKU>) => {
      if (!counterInfo) return;

      setSkuInProgress(counterInfo);
      setRowInfoToHighlight({ rowKey: counterInfo.itemId });
    },
    [setRowInfoToHighlight]
  );

  const initCounter = useCallback(
    ({
      counter,
      receivingItemList,
      needReset,
    }: {
      counter: CounterForInspection;
      receivingItemList?: ReceivingItem[];
      needReset?: boolean; // 이전 local에서 카운팅했던 값을 무시할때 사용
    }) => {
      if (!receivingItemList) return;

      const newInfo: CounterDataForInspection = {};

      // 불일치 상품(skuId 가 없는 상품) 필터링 해서 검수 상품을 보여줌.
      receivingItemList
        .filter(({ skuId }) => skuId)
        .forEach((v) => {
          const counterKey = `S${v.skuId}`;

          if (!counterKey) return;

          const previousCountInfo =
            !needReset && counter.counterInfo
              ? counter.counterInfo[counterKey]
              : null;

          const isInputtingItem = state?.inputtingCounterInfo?.itemId === v.id;

          const prevInputtingCounterInfo = isInputtingItem
            ? state?.inputtingCounterInfo
            : null;

          newInfo[counterKey] = {
            counterKey,
            itemId: v.id,
            skuId: v.skuId,
            skuBarcode: v.sku.barCode,
            inspectorId: v.inspectorId,
            isCompleteInspection: v.isCompleteInspection,
            max: v.quantity,
            current:
              prevInputtingCounterInfo?.current ||
              previousCountInfo?.current ||
              v.actualQty,
          };
        });

      counter.initCounterInfo(newInfo);

      setScanInprogressState(state?.inputtingCounterInfo);
    },
    [setScanInprogressState, state?.inputtingCounterInfo]
  );

  useEffect(() => {
    initCounter({ counter, receivingItemList: items });

    // counter를 넣을 수 없음
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, initCounter]);

  useEffect(() => {
    if (skuInProgress) {
      setCanNotLeavePage(true);
    } else {
      setCanNotLeavePage(false);
    }
  }, [skuInProgress, setCanNotLeavePage]);

  useEffect(() => {
    if (skuInProgress) {
      const target = counter.counterInfo[skuInProgress.counterKey];
      setSkuInProgress(target);
    } else {
      setSkuInProgress(undefined);
    }
    // counter를 넣을 수 없음
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skuInProgress]);

  const getTotalCount = useCallback(
    (counter: CounterDataForInspection, list?: ReceivingItem[]) => {
      if (!list) return "";

      let sum = 0;
      let total = 0;

      Object.values(counter).forEach((v) => {
        sum += v.current || 0;
      });

      list.forEach((v) => {
        total += v.quantity || 0;
      });

      return `${sum + unverifiedCount} / ${total}`;
    },
    [unverifiedCount]
  );

  const totalCount = useMemo<string>(
    () => getTotalCount(counter.counterInfo, items),
    [getTotalCount, counter.counterInfo, items]
  );

  const reset = useCallback(
    (items: ReceivingItem[]) => {
      setSkuInProgress(undefined);

      initCounter({
        counter,
        receivingItemList: items,
        needReset: true,
      });
    },
    [counter, initCounter]
  );

  return {
    skuInProgress,
    setSkuInProgress,
    counter,
    totalCount,
    reset,
  };
}
