import { useCallback } from "react";
import { useHistory } from "react-router-dom";

import RETURNING_QUERY from "@sellernote/_shared/src/queries/fulfillment/RETURNING_QUERY";
import { getFormattedReturningId } from "@sellernote/_shared/src/utils/fulfillment/returning";

import { returningSelectors } from "modules/returning";
import { useAppSelector } from "store";

export default function useCloseAsNormal({
  returningId,
}: {
  returningId: number;
}) {
  const history = useHistory();

  const {
    isCompletedInspectionWithMatchedCount,
    SKUItemsFilteredByQuantityReturning,
    SKUItemsReturning,

    returningCompanyName,
    returningTeamName,
  } = useAppSelector((state) => {
    return {
      isCompletedInspectionWithMatchedCount:
        returningSelectors.checkIsCompletedInspectionWithMatchedCount(state),
      SKUItemsFilteredByQuantityReturning:
        returningSelectors.getSKUItemsFilteredByQuantity(state),
      SKUItemsReturning: returningSelectors.getSKUItems(state),

      returningCompanyName:
        state.returning.GET_DETAIL?.data?.returning.team?.company,
      returningTeamName: state.returning.GET_DETAIL?.data?.returning.team?.name,
    };
  });

  const {
    mutate: setInspectionDone,
    ResponseHandler: ResponseHandlerOfSetInspectionDone,
  } = RETURNING_QUERY.useSetInspectionDone({
    returningId,
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();
        history.replace("/returning/inspection");
      },
      customizeMessage: () => ({
        messageType: "titleOnly",
        title: "검수 마감 처리되었습니다.",
      }),
    },
  });

  const handleSubmit = useCallback(() => {
    if (!SKUItemsFilteredByQuantityReturning.length) return;

    const totalActualQty = SKUItemsFilteredByQuantityReturning.reduce(
      (a, c) => {
        return a + (c.actualQty ?? 0);
      },
      0
    );

    // sku id 가 없는 불일치 상품을 제외하고 검수마감.
    const filteredSKUItemsReturning = SKUItemsReturning.filter((v) => v.skuId);

    setInspectionDone({
      // 검수마감(반품)의 경우 0개인 item도 포함
      inspectedItems: filteredSKUItemsReturning.map((v) => {
        return {
          skuId: v.skuId,
          // 마감 시에는 값이 존재
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          actualQty: v.actualQty!,
        };
      }),
      actualQty: totalActualQty,
    });
  }, [
    SKUItemsFilteredByQuantityReturning,
    SKUItemsReturning,
    setInspectionDone,
  ]);

  const confirmMessageTitle = `${getFormattedReturningId({
    id: returningId,
  })} / ${returningCompanyName ?? "-"} (${returningTeamName ?? "-"})`;

  return {
    confirmMessageTitle,

    handleSubmit,

    ResponseHandlerOfSetInspectionDone,
  };
}
