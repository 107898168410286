import React, { useMemo, useState } from "react";

import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import { InputSelectOption } from "@sellernote/_shared/src/headlessComponents/input/useInputSelect";
import { TableDataListItem } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import { Overwrite } from "@sellernote/_shared/src/types/common/customUtilityTypes";
import { BofulWorker } from "@sellernote/_shared/src/types/fulfillment/auth";
import { BofulProblem } from "@sellernote/_shared/src/types/fulfillment/fulfillment";
import { ReceivingItem } from "@sellernote/_shared/src/types/fulfillment/receiving";
import {
  checkIsUnverifiedSku,
  getWarehousingItemStatusLabel,
} from "@sellernote/_shared/src/utils/fulfillment/common";
import { BOFUL_PROBLEM_OPTION_LIST } from "@sellernote/_shared/src/utils/fulfillment/constants";
import {
  checkIsGroupedItemBySkuId,
  getGroupedItemSkuIdList,
} from "@sellernote/_shared/src/utils/fulfillment/returning";
import { getStyledSKUCount } from "@sellernote/_shared/src/utils/fulfillment/sku";
import InputSelect from "@sellernote/_sds-v1/src/components/input/InputSelect";
import InputTextArea from "@sellernote/_sds-v1/src/components/input/InputTextArea";
import Table from "@sellernote/_sds-v1/src/components/table/Table";

import useGetWorkerByIdFactory from "hooks/common/useGetWorkerByIdFactory";
import { returningSelectors } from "modules/returning";
import { useAppSelector } from "store";

import ItemStatus from "components/ItemStatus";
import SkuIdForReportingProblem from "components/SkuIdForReportingProblem";
import SubRowIndicator from "components/SubRowIndicator";
import UnverifiedSku from "components/UnverifiedSku";

import ButtonForReporting from "./ButtonForReporting";
import Styled from "./index.styles";

type ItemListToReportTableItem =
  | {
      skuId: React.ReactNode;
      counter: React.ReactNode;
      manager: string;
      problem: React.ReactNode;
    }
  | Record<string, unknown>;

export type BofulProblemDict = {
  [itemId in string]: BofulProblemDictValue;
};

export type BofulProblemDictValue = {
  type: InputSelectOption<BofulProblem> | undefined;
  directInput?: string;
};

function SKUProblem({
  selectedProblem,
  setProblem,
}: {
  selectedProblem?: InputSelectOption<BofulProblem>;
  setProblem: (problem: InputSelectOption<BofulProblem>) => void;
}) {
  return (
    <InputSelect<BofulProblem>
      placeholder="선택"
      uiType="outline"
      optionList={BOFUL_PROBLEM_OPTION_LIST}
      selectedOption={selectedProblem}
      handleSelect={(problem) => setProblem(problem)}
      width="100%"
    />
  );
}

function getManagerName({
  getWorkerById,
  placerIds,
}: {
  getWorkerById: (workerId?: number | undefined) => BofulWorker | undefined;
  placerIds: number[];
}) {
  if (!placerIds.length) return "";

  const isMultiWarehousing = placerIds.length > 1;

  const workerIds = [...new Set(placerIds)];

  const isMultiWorker = workerIds.length > 1;

  const titleWorkerName = workerIds ? getWorkerById(workerIds[0])?.name : "";

  return isMultiWarehousing
    ? `[분할] ${titleWorkerName}${
        isMultiWorker ? ` 외 ${placerIds.length - 1}` : ""
      }`
    : titleWorkerName;
}

function SKUList({
  closeModal,
  returningId,
}: {
  closeModal: () => void;
  returningId: number;
}) {
  const { SKUItemsFilteredByActualQtyReturning } = useAppSelector((state) => {
    return {
      SKUItemsFilteredByActualQtyReturning:
        returningSelectors.getSKUItemsFilteredByActualQty(state),
    };
  });

  const getWorkerById = useGetWorkerByIdFactory();

  const itemsToReport = useMemo(() => {
    return SKUItemsFilteredByActualQtyReturning.reduce<
      Overwrite<Partial<ReceivingItem>, { id: number; sku: { id: number } }>[]
    >((acc, cur, index) => {
      if (cur.actualQty === cur.placeQty) {
        return acc;
      }

      const duplicateItemIndex = acc.findIndex((v) => v.sku?.id === cur.sku.id);

      if (duplicateItemIndex === -1) {
        return [...acc, cur];
      }

      // 이미 부모행이 만들어져있으면 부모행에는 actualQty, placeQty를 더해주고, 자식행에는 그대로 추가
      if (acc[duplicateItemIndex].id < 0) {
        return [
          ...acc.slice(0, duplicateItemIndex),
          {
            ...acc[duplicateItemIndex],
            actualQty:
              (acc[duplicateItemIndex].actualQty ?? 0) + (cur.actualQty ?? 0),
            placeQty:
              (acc[duplicateItemIndex].placeQty ?? 0) + (cur.placeQty ?? 0),
          },
          ...acc.slice(duplicateItemIndex + 1),
          cur,
        ];
      }

      // 동일한 SKU ID가 존재하는 경우(정상/불량으로 나뉜 상품) 상단에 부모행(SKU ID, 상품바코드, 카운트만 존재)을 만들어 줌
      return [
        ...acc.slice(0, duplicateItemIndex),
        {
          // itemId과 겹치지 않는 고유한 값을 만들기 위해 -index로 설정
          id: -1 * index,
          sku: cur.sku,
          actualQty:
            (acc[duplicateItemIndex].actualQty ?? 0) + (cur.actualQty ?? 0),
          placeQty:
            (acc[duplicateItemIndex].placeQty ?? 0) + (cur.placeQty ?? 0),
          placeItems: [],
        },
        ...acc.slice(duplicateItemIndex),
        cur,
      ];
    }, []);
  }, [SKUItemsFilteredByActualQtyReturning]);

  const [problemDict, setProblemDict] = useState<BofulProblemDict>(
    itemsToReport.reduce((a, c) => {
      a[c.id] = { type: undefined };
      return a;
    }, {} as BofulProblemDict)
  );

  const TableToReport = useMemo(() => {
    const dataList: TableDataListItem<ItemListToReportTableItem>[] = [];

    const groupedItemSkuIdList = getGroupedItemSkuIdList(itemsToReport);

    itemsToReport.forEach((v) => {
      const hasTextArea = problemDict[v.id].type?.value === "directInput";

      const isParentRowOfGroupedItem = v.id < 0;
      if (isParentRowOfGroupedItem) {
        const parentRowOfGroupedItem = {
          rowKey: v.id,

          skuId: (
            <SkuIdForReportingProblem
              skuId={v.sku.id}
              skuBarcode={v.sku.barCode}
            />
          ),

          counter: getStyledSKUCount({
            currentCount: v.placeQty ?? 0,
            goalCount: v.actualQty,
          }),

          manager: getManagerName({
            getWorkerById,
            placerIds: v.placeItems
              ? v.placeItems.reduce((a: number[], c) => {
                  if (c.placerId) {
                    a.push(c.placerId);
                  }
                  return a;
                }, [])
              : [],
          }),
          problem: "",
        };

        dataList.push(parentRowOfGroupedItem);
        return;
      }

      const isGroupedItem = checkIsGroupedItemBySkuId(
        groupedItemSkuIdList,
        v.sku.id
      );
      const isUnverifiedSku = checkIsUnverifiedSku(v.inspectProblems);
      const itemStatusLabel = getWarehousingItemStatusLabel(v.processStatus);

      const mapped: TableDataListItem<ItemListToReportTableItem> = {
        rowKey: `${v.id}-${v.processStatus}`,
        noBorderBottom: hasTextArea,
        isSubRow: isGroupedItem,

        skuId: (() => {
          if (isUnverifiedSku) {
            if (isGroupedItem) {
              return (
                <SubRowIndicator>
                  <UnverifiedSku>
                    <Styled.skuIdContainer>
                      <SkuIdForReportingProblem
                        skuId={v.sku.id}
                        skuBarcode={v.sku.barCode}
                      />

                      <ItemStatus
                        statusLabel={itemStatusLabel}
                        isUnverifiedSku={isUnverifiedSku}
                        isTable
                      />
                    </Styled.skuIdContainer>
                  </UnverifiedSku>
                </SubRowIndicator>
              );
            }

            return (
              <UnverifiedSku>
                <Styled.skuIdContainer>
                  <SkuIdForReportingProblem
                    skuId={v.sku.id}
                    skuBarcode={v.sku.barCode}
                  />

                  <ItemStatus
                    statusLabel={itemStatusLabel}
                    isUnverifiedSku={isUnverifiedSku}
                    isTable
                  />
                </Styled.skuIdContainer>
              </UnverifiedSku>
            );
          }

          if (isGroupedItem) {
            return (
              <SubRowIndicator>
                <Styled.skuIdContainer>
                  <SkuIdForReportingProblem
                    skuId={v.sku.id}
                    skuBarcode={v.sku.barCode}
                  />

                  <ItemStatus
                    statusLabel={itemStatusLabel}
                    isUnverifiedSku={isUnverifiedSku}
                    isTable
                  />
                </Styled.skuIdContainer>
              </SubRowIndicator>
            );
          }

          return (
            <Styled.skuIdContainer>
              <SkuIdForReportingProblem
                skuId={v.sku.id}
                skuBarcode={v.sku.barCode}
              />

              <ItemStatus
                statusLabel={itemStatusLabel}
                isUnverifiedSku={isUnverifiedSku}
                isTable
              />
            </Styled.skuIdContainer>
          );
        })(),

        counter: getStyledSKUCount({
          currentCount: v.placeQty ?? 0,
          goalCount: v.actualQty,
        }),

        manager: getManagerName({
          getWorkerById,
          placerIds: v.placeItems
            ? v.placeItems.reduce((a: number[], c) => {
                if (c.placerId) {
                  a.push(c.placerId);
                }
                return a;
              }, [])
            : [],
        }),

        problem: (
          <SKUProblem
            selectedProblem={problemDict[v.id]?.type}
            setProblem={(problem) => {
              const newDict = { ...problemDict };
              newDict[v.id] = {
                ...newDict[v.id],
                type: problem,
                directInput: undefined,
              };

              setProblemDict(newDict);
            }}
          />
        ),
      };

      dataList.push(mapped);

      if (hasTextArea) {
        const additional: TableDataListItem<ItemListToReportTableItem> = {
          rowKey: `${v.id}-${v.processStatus}-additional`,
          isSubRow: isGroupedItem,
          colSpan: {
            value: 4,
            content: (
              <InputTextArea
                value={problemDict[v.id].directInput}
                setValue={(value) => {
                  const newDict = { ...problemDict };
                  newDict[v.id] = {
                    ...newDict[v.id],
                    directInput: value,
                  };

                  setProblemDict(newDict);
                }}
                placeholder="직접입력"
                isAutoResize
                isValidated={!!problemDict[v.id].directInput}
              />
            ),
            hasFullWidth: true,
          },
        };

        dataList.push(additional);
      }
    });

    return (
      <Styled.modalBodyContainer>
        <Table<ItemListToReportTableItem>
          columnInfo={{
            skuId: {
              label: (
                <>
                  SKU ID / 상품 바코드
                  <br />
                  상태
                </>
              ),
              fixedWidth: 136,
            },
            counter: {
              label: "카운트",
              fixedWidth: 100,
            },
            manager: {
              label: "담당자",
              fixedWidth: 150,
            },
            problem: {
              label: "발생문제 선택",
              fixedWidth: 200,
            },
          }}
          dataList={dataList}
        />

        <ButtonForReporting
          returningId={returningId}
          problemDict={problemDict}
        />
      </Styled.modalBodyContainer>
    );
  }, [itemsToReport, returningId, problemDict, getWorkerById]);

  if (!itemsToReport.length) return null;

  return (
    <Modal
      active
      uiType="contentWithCustomBody"
      title={"문제가 발생한 상품(들)을 선택해주세요."}
      body={<>{TableToReport}</>}
      onClose={closeModal}
    />
  );
}

export default SKUList;
