import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";

import BofulRequestResponseHandler from "containers/BofulRequestResponseHandler";
import { returningActions, returningSelectors } from "modules/returning";
import { useAppSelector } from "store";

export default function useFetchDetailForWarehousing({
  returningId,
}: {
  returningId: number;
}) {
  const dispatch = useDispatch();

  const { getDetailReturningRes, SKUItemsFilteredByActualQtyReturning } =
    useAppSelector((state) => {
      return {
        getDetailReturningRes: state.returning.GET_DETAIL,
        SKUItemsFilteredByActualQtyReturning:
          returningSelectors.getSKUItemsFilteredByActualQty(state),
      };
    });

  useEffect(() => {
    if (returningId) {
      dispatch(returningActions.GET_DETAIL({ id: returningId }));
    }

    return () => {
      dispatch(returningActions.INIT_GET_DETAIL({}));
    };
  }, [returningId, dispatch]);

  const ResponseHandlerOfDetail = useMemo(() => {
    return (
      <BofulRequestResponseHandler
        response={getDetailReturningRes}
        initRequest={() => {
          dispatch(returningActions.INIT_GET_DETAIL({}));
        }}
        loading={{
          actionType: returningActions.GET_DETAIL.type,
        }}
      />
    );
  }, [getDetailReturningRes, dispatch]);

  const returningDetailData = useMemo(() => {
    if (!getDetailReturningRes?.data) {
      return;
    }

    return getDetailReturningRes.data;
  }, [getDetailReturningRes]);

  return {
    returningDetailData,
    SKUItemsFilteredByActualQtyReturning,
    ResponseHandlerOfDetail,
  };
}
