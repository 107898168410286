import RETURNING_QUERY from "@sellernote/_shared/src/queries/fulfillment/RETURNING_QUERY";
import { ReceivingItem } from "@sellernote/_shared/src/types/fulfillment/receiving";

// 불일치 상품의 attachment 정보 -> items 의 attachment에서 domain이 "unverifiedSku"만 해당
const getUnverifiedListWithAttachments = (returningItems?: ReceivingItem[]) => {
  if (!returningItems) return;

  const unverifiedList = returningItems.filter(({ skuId }) => !skuId);

  return unverifiedList.map((item) => ({
    ...item,
    attachment: item.attachment?.filter(
      ({ domain }) => domain === "unverifiedSku"
    ),
  }));
};

export default function useFetchListForUnverified({
  returningId,
  onSuccessOfGetReturningUnverifiedList,
}: {
  returningId: number;
  onSuccessOfGetReturningUnverifiedList: (data?: ReceivingItem[]) => void;
}) {
  const {
    data: returningDetail,
    ResponseHandler: ResponseHandlerOfReturningDetail,
  } = RETURNING_QUERY.useGetPDAReturningDetail({
    id: returningId,
    onSuccess: (res) =>
      onSuccessOfGetReturningUnverifiedList(
        getUnverifiedListWithAttachments(res.returning.items)
      ),
    titleOfFailureModal: "불일치 상품 조회 중 오류가 발생했습니다.",
  });

  const unverifiedList = getUnverifiedListWithAttachments(
    returningDetail?.returning.items
  );

  // 불일치 상품을 제외한 리스트
  const normalItemList = returningDetail?.returning.items.filter(
    (item) => !!item.skuId
  );

  return {
    unverifiedList,
    ResponseHandlerOfUnverifiedList: ResponseHandlerOfReturningDetail,
    normalItemList,
  };
}
