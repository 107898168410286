import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import * as Sentry from "@sentry/react";

import ErrorBoundary from "@sellernote/_sds-v1/src/components/error/ErrorBoundary";
import PageNotFoundForCRA from "@sellernote/_sds-v1/src/components/error/PageNotFoundForCRA";

import PartTimerCannotAccessRoute from "containers/PartTimerCannotAccessRoute";
import Home from "pages";
import Sample from "pages/_sample";
import CancelingMain from "pages/canceling";
import CancelingDetail from "pages/canceling/detail/:id";
import InventoryMain from "pages/inventory";
import InventoryInformation from "pages/inventory/information";
import InventoryInspection from "pages/inventory/inspection";
import InventoryMovement from "pages/inventory/movement";
import InventoryMovementAdjustMent from "pages/inventory/movement/adjustment";
import InventoryMovementConsolidation from "pages/inventory/movement/consolidation";
import InventoryMovementSplit from "pages/inventory/movement/split";
import Login from "pages/login";
import ReceivingMain from "pages/receiving";
import ReceivingConfirm from "pages/receiving/confirm";
import ReceivingConfirmDetail from "pages/receiving/confirm/:id";
import ReceivingConfirmDetailPictureType from "pages/receiving/confirm/:id/picture-type";
import ReceivingInspection from "pages/receiving/inspection";
import ReceivingInspectionDetail from "pages/receiving/inspection/:id";
import ReceivingInspectionDetailMultiLocation from "pages/receiving/inspection/:id/multi-location/:skuId";
import ReceivingWarehousing from "pages/receiving/warehousing";
import ReceivingWarehousingDetail from "pages/receiving/warehousing/:id";
import ReceivingWarehousingDetailMultiLocation from "pages/receiving/warehousing/:id/multi-location/:skuId";
import ReturningMain from "pages/returning";
import ReturningConfirm from "pages/returning/confirm";
import ReturningConfirmDetail from "pages/returning/confirm/:id";
import ReturningConfirmDetailPictureType from "pages/returning/confirm/:id/picture-type";
import ReturningConfirmDetailPictureByDomainType from "pages/returning/confirm/:id/picture-type/:domainType";
import ReturningInspection from "pages/returning/inspection";
import ReturningInspectionDetail from "pages/returning/inspection/:id";
import ReturningInspectionDetailByProblemItem from "pages/returning/inspection/:id/problem-item";
import ReturningInspectionDetailByUnverifiedItem from "pages/returning/inspection/:id/unverified-item";
import ReturningWarehousing from "pages/returning/warehousing";
import ReturningWarehousingDetail from "pages/returning/warehousing/:id";
import ReturningWarehousingDetailMultiLocation from "pages/returning/warehousing/:id/multi-location/:skuId";
import ShippingMain from "pages/shipping";
import ShippingConfirm from "pages/shipping/confirm";
import ShippingPacking from "pages/shipping/packing";
import ShippingPicking from "pages/shipping/picking";
import ShippingShipment from "pages/shipping/shipment";
import ShippingShipmentClosing from "pages/shipping/shipment/closing";
import ShippingShipmentClosingParcelCompany from "pages/shipping/shipment/closing/parcel/:company";
import ShippingShipmentClosingParcelCompanyDetail from "pages/shipping/shipment/closing/parcel/:company/:status";
import ShippingShipmentClosingTruckCompany from "pages/shipping/shipment/closing/truck/:company";
import ShippingShipmentClosingTruckCompanyDetail from "pages/shipping/shipment/closing/truck/:company/:status";
import ShippingShipmentPreparation from "pages/shipping/shipment/preparation";

function AppRouter({ children }: { children: React.ReactNode }) {
  return (
    <Router>
      <ErrorBoundary sentry={Sentry}>
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/login" component={Login} exact />

          <Route path="/receiving" component={ReceivingMain} exact />
          <Route path="/receiving/confirm" component={ReceivingConfirm} exact />
          <Route
            path="/receiving/confirm/:id"
            component={ReceivingConfirmDetail}
            exact
          />
          <Route
            path="/receiving/confirm/:id/picture-type"
            component={ReceivingConfirmDetailPictureType}
            exact
          />

          <Route
            path="/receiving/inspection"
            component={ReceivingInspection}
            exact
          />
          <Route
            path="/receiving/inspection/:id"
            component={ReceivingInspectionDetail}
            exact
          />
          <Route
            path="/receiving/inspection/:id/multi-location/:skuId"
            component={ReceivingInspectionDetailMultiLocation}
            exact
          />

          <Route
            path="/receiving/warehousing"
            component={ReceivingWarehousing}
            exact
          />
          <Route
            path="/receiving/warehousing/:id"
            component={ReceivingWarehousingDetail}
            exact
          />
          <Route
            path="/receiving/warehousing/:id/multi-location/:skuId"
            component={ReceivingWarehousingDetailMultiLocation}
            exact
          />

          <Route path="/shipping" component={ShippingMain} exact />
          <PartTimerCannotAccessRoute
            path="/shipping/confirm"
            component={ShippingConfirm}
            exact
          />

          <Route path="/shipping/picking" component={ShippingPicking} exact />

          <Route path="/shipping/packing" component={ShippingPacking} exact />

          <Route path="/shipping/shipment" component={ShippingShipment} exact />
          <Route
            path="/shipping/shipment/preparation"
            component={ShippingShipmentPreparation}
            exact
          />
          <Route
            path="/shipping/shipment/closing"
            component={ShippingShipmentClosing}
            exact
          />
          <Route
            path="/shipping/shipment/closing/parcel/:company"
            component={ShippingShipmentClosingParcelCompany}
            exact
          />
          <Route
            path="/shipping/shipment/closing/parcel/:company/:status"
            component={ShippingShipmentClosingParcelCompanyDetail}
            exact
          />
          <Route
            path="/shipping/shipment/closing/parcel/:company/:status/:order"
            component={ShippingShipmentClosingParcelCompanyDetail}
            exact
          />
          <Route
            path="/shipping/shipment/closing/truck/:company"
            component={ShippingShipmentClosingTruckCompany}
            exact
          />
          <Route
            path="/shipping/shipment/closing/truck/:company/:status"
            component={ShippingShipmentClosingTruckCompanyDetail}
            exact
          />

          <Route path="/inventory" component={InventoryMain} exact />
          <Route
            path="/inventory/information"
            component={InventoryInformation}
            exact
          />
          <Route
            path="/inventory/movement"
            component={InventoryMovement}
            exact
          />
          <Route
            path="/inventory/movement/split"
            component={InventoryMovementSplit}
            exact
          />
          <Route
            path="/inventory/movement/consolidation"
            component={InventoryMovementConsolidation}
            exact
          />
          <Route
            path="/inventory/movement/adjustment"
            component={InventoryMovementAdjustMent}
            exact
          />
          <Route
            path="/inventory/inspection"
            component={InventoryInspection}
            exact
          />

          <Route path="/returning" component={ReturningMain} exact />
          <Route path="/returning/confirm" component={ReturningConfirm} exact />
          <Route
            path="/returning/confirm/:id"
            component={ReturningConfirmDetail}
            exact
          />
          <Route
            path="/returning/confirm/:id/picture-type"
            component={ReturningConfirmDetailPictureType}
            exact
          />
          <Route
            path="/returning/confirm/:id/picture-type/:domainType"
            component={ReturningConfirmDetailPictureByDomainType}
            exact
          />

          <Route
            path="/returning/inspection"
            component={ReturningInspection}
            exact
          />
          <Route
            path="/returning/inspection/:id"
            component={ReturningInspectionDetail}
            exact
          />
          <Route
            path="/returning/inspection/:id/unverified-item"
            component={ReturningInspectionDetailByUnverifiedItem}
            exact
          />
          <Route
            path="/returning/inspection/:id/:skuId/:itemId/problem-item"
            component={ReturningInspectionDetailByProblemItem}
            exact
          />

          <Route
            path="/returning/warehousing"
            component={ReturningWarehousing}
            exact
          />
          <Route
            path="/returning/warehousing/:id"
            component={ReturningWarehousingDetail}
            exact
          />
          <Route
            path="/returning/warehousing/:id/multi-location/:skuId"
            component={ReturningWarehousingDetailMultiLocation}
            exact
          />

          <Route path="/canceling" component={CancelingMain} exact />
          <Route
            path="/canceling/detail/:id"
            component={CancelingDetail}
            exact
          />

          <Route path="/sample" component={Sample} exact />

          <Route path="*" component={PageNotFoundForCRA} />
        </Switch>

        {children}
      </ErrorBoundary>
    </Router>
  );
}

export default AppRouter;
