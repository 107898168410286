import { useCallback } from "react";
import { useState } from "react";
import { useMemo } from "react";
import { useDispatch } from "react-redux";

import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import RETURNING_QUERY from "@sellernote/_shared/src/queries/fulfillment/RETURNING_QUERY";
import { ScannedLocation } from "@sellernote/_shared/src/types/fulfillment/scan";
import { UseCounterDataValue } from "@sellernote/_shared/src/utils/common/hook";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";

import { WarehousingCounterSKU } from "hooks/returning/useSKUCountingForWarehousing";
import { returningActions } from "modules/returning";

import ItemStatus from "components/ItemStatus";

import Styled from "./index.styles";

export default function useCompleteWarehousing({
  locationType,
  returningId,
  counterInProgress,
  selectedLocation,
  resetAfterComplete,
}: {
  locationType: "single" | "multi";
  returningId: number;
  counterInProgress: UseCounterDataValue<WarehousingCounterSKU> | undefined;
  selectedLocation: ScannedLocation | undefined;
  resetAfterComplete: () => void;
}) {
  const dispatch = useDispatch();

  const [visibleConfirmModal, setVisibleConfirmModal] = useState(false);

  const {
    mutate: setItemWarehousingDone,
    ResponseHandler: ResponseHandlerOfSetItemWarehousingDone,
  } = RETURNING_QUERY.useSetItemWarehousingDone({
    locationType,
    selectedLocationBarcode: selectedLocation?.barCode ?? "",
  });

  const canComplete = !!selectedLocation && !!counterInProgress?.current;

  const complete = useCallback(() => {
    if (!counterInProgress || !counterInProgress.current || !selectedLocation)
      return;

    setItemWarehousingDone(
      {
        pathParams: { returningId },
        putAwayItems: [
          {
            skuId: counterInProgress.skuId,
            locationId: selectedLocation.id,
            placingId: counterInProgress.placingId,
            placeQty: counterInProgress.current,
          },
        ],
      },
      {
        onSuccess: () => {
          dispatch(
            returningActions.GET_DETAIL({
              id: returningId,
              _postSuccessCallback: () => {
                setVisibleConfirmModal(false);
                resetAfterComplete();
              },
            })
          );
        },
      }
    );
  }, [
    counterInProgress,
    selectedLocation,
    setItemWarehousingDone,
    returningId,
    dispatch,
    resetAfterComplete,
  ]);

  const handleCompleteWarehousingClick = useCallback(() => {
    if (!counterInProgress || !counterInProgress.current) return;

    const isMismatchedQuantity =
      counterInProgress.current !== counterInProgress.max;

    if (isMismatchedQuantity) {
      setVisibleConfirmModal(true);
      return;
    }

    complete();
  }, [complete, counterInProgress]);

  const ConfirmModal = useMemo(() => {
    if (!visibleConfirmModal || !counterInProgress) return null;

    return (
      <Modal
        active={true}
        uiType="content"
        title={
          <>
            {getFormattedSingleSkuId(counterInProgress.skuId)}(SKU ID)
            <br />
            상태:{" "}
            <ItemStatus
              statusLabel={counterInProgress.statusLabel}
              isUnverifiedSku={counterInProgress.isUnverifiedSku}
            />
          </>
        }
        body={
          <Styled.completeWarehousingConfirmModalBody>
            <div className="counting">
              <span className="strong">{counterInProgress.current}</span> /{" "}
              <span>{counterInProgress.max}</span>
            </div>

            <div className="message">이대로 완료하시겠습니까?</div>
          </Styled.completeWarehousingConfirmModalBody>
        }
        actionPositive={{
          label: "예",
          handleClick: complete,
        }}
        actionNegative={{
          label: "아니오",
          handleClick: () => setVisibleConfirmModal(false),
        }}
      />
    );
  }, [complete, counterInProgress, visibleConfirmModal]);

  return {
    canComplete,
    handleCompleteWarehousingClick,

    ConfirmModal,

    ResponseHandlerOfSetItemWarehousingDone,
  };
}
