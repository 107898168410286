import { useCallback, useEffect, useMemo, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { useRecoilValue } from "recoil";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import { TableRowInfoToHighlight } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import { FULFILLMENT_RECEIVING_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/receiving";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import { checkConfirmedAsSingleLocationWarehousing } from "@sellernote/_shared/src/utils/fulfillment/receiving";
import { checkHasOnlyInitialWarehousing } from "@sellernote/_shared/src/utils/fulfillment/receiving";

import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";
import useConfirmModal from "hooks/common/useConfirmModal";
import useGetReceivingDetail from "hooks/receiving/useGetReceivingDetail";
import useScanLocation from "hooks/receiving/useScanLocation";
import useScanWarehousingSKU from "hooks/receiving/useScanWarehousingSKU";
import useWarehousingCounting from "hooks/receiving/useSKUCountingForWarehousing";

import { ScanModeForReceivingWarehousing } from "../..";
import CompleteWarehousing from "../../CompleteWarehousing";
import AddPartialWarehousing from "./AddPartialWarehousing";
import PartedList from "./PartedList";
import Styled from "./index.styles";

function Guide({ skuId, actualQty }: { skuId: number; actualQty?: number }) {
  return (
    <Styled.guide>
      <div className="title">‣ 분할입고할 내용을 추가해주세요.</div>

      <div className="desc">
        [입고 정보]
        <br />
        ・SKU ID : {getFormattedSingleSkuId(skuId)}
        <br />
        ・총 입고수량 : {actualQty}
      </div>
    </Styled.guide>
  );
}

function TotalCount({ max, counted }: { max: number; counted: number }) {
  return (
    <Styled.totalCount>
      <div className="label">총 카운트 :</div>
      <div className="value">
        {counted} / {max}
      </div>
    </Styled.totalCount>
  );
}

function MultiLocation() {
  const match = useRouteMatch<{ id: string; skuId: string }>();
  const {
    params: { id, skuId },
  } = match;

  const [scanMode, setScanMode] =
    useState<ScanModeForReceivingWarehousing>("skuId");
  const [rowInfoToHighlight, setRowInfoToHighlight] = useState<
    TableRowInfoToHighlight | undefined
  >(undefined);
  const [placingIdInProgress, setPlacingIdInProgress] = useState("");

  const { ConfirmModal, setConfirmModal } = useConfirmModal();

  const skuItemsFilteredByActualQty = useRecoilValue(
    FULFILLMENT_RECEIVING_SELECTORS.SKU_ITEMS_FILTERED_BY_ACTUAL_QTY
  );

  const { ResponseHandler: ResponseHandlerOfGettingManagerReceivingDetail } =
    useGetReceivingDetail({ receivingId: Number(id) });

  const skuCounting = useWarehousingCounting(skuItemsFilteredByActualQty);

  const {
    setScanSKUActive,
    showSelectionErrorForScanSKU,
    ResultHandlerOfScanSKU,
  } = useScanWarehousingSKU({
    scanType: "multi",
    skuId: Number(skuId),
    placingId: placingIdInProgress,
    skuCounting,
    receivingId: Number(id),
    setRowInfoToHighlight,
  });

  const {
    scannedLocation,
    setScannedLocation,
    setScanLocationActive,
    showSelectionErrorForScanLocation,
    ResultHandlerOfScanLocation,
  } = useScanLocation({
    skuIdInprogress: skuCounting.skuInProgress?.skuId,
    counterData: skuCounting.counter.counterInfo,
  });

  useEffect(() => {
    setScanSKUActive(scanMode === "skuId");
    setScanLocationActive(scanMode === "location");
  }, [scanMode, setScanLocationActive, setScanSKUActive]);

  const receivingItem = useMemo(() => {
    if (!skuItemsFilteredByActualQty) return;

    return skuItemsFilteredByActualQty.find((v) => v.sku.id === Number(skuId));
  }, [skuItemsFilteredByActualQty, skuId]);

  const confirmedAsSingleLocationWarehousing = useMemo(() => {
    return checkConfirmedAsSingleLocationWarehousing(receivingItem);
  }, [receivingItem]);

  const totalPlacedQty = useMemo(() => {
    if (!receivingItem?.placeItems) return 0;

    return receivingItem.placeItems.reduce((a, c) => {
      return a + (c.placeQty ?? 0);
    }, 0);
  }, [receivingItem]);

  const hasOnlyInitialWarehousing = useMemo(() => {
    return checkHasOnlyInitialWarehousing(receivingItem);
  }, [receivingItem]);

  const receivingItemCompleted = useMemo(() => {
    const allWarehousingItemsAreSplit =
      receivingItem?.actualQty ===
      receivingItem?.placeItems.reduce((a, c) => {
        return a + (c.quantity ?? 0);
      }, 0);

    const allWarehousingItemsAreCompleted = receivingItem?.placeItems.every(
      (ii) => ii.isCompletePlacing
    );

    return allWarehousingItemsAreSplit && allWarehousingItemsAreCompleted;
  }, [receivingItem?.placeItems, receivingItem?.actualQty]);

  const confirmMessageToLeave = useMemo(() => {
    //  분할입고를 시작했는데, 모든 분할입고를 완료하지 않았을 경우 confirm 메시지를 띄움.
    if (
      !hasOnlyInitialWarehousing &&
      !confirmedAsSingleLocationWarehousing &&
      !receivingItemCompleted
    ) {
      return {
        title: `${getFormattedSingleSkuId(Number(skuId))}(SKU ID)`,
        body: (
          <>
            입고가 완료되지 않았습니다.
            <br />
            분할입고를 그만하겠습니까?
          </>
        ),
      };
    }
  }, [
    hasOnlyInitialWarehousing,
    confirmedAsSingleLocationWarehousing,
    receivingItemCompleted,
    skuId,
  ]);

  const handleScanSKUClick = useCallback(() => {
    if (!placingIdInProgress) {
      showSelectionErrorForScanSKU();
      return;
    }

    setScanMode("skuId");
  }, [placingIdInProgress, showSelectionErrorForScanSKU]);

  const handleScanLocationClick = useCallback(() => {
    if (!skuCounting.skuInProgress) {
      showSelectionErrorForScanLocation();
    }

    setScanMode("location");
  }, [showSelectionErrorForScanLocation, skuCounting.skuInProgress]);

  const resetCountByCounterKey = useCallback(
    (counterKey: string) => () => {
      setConfirmModal(undefined);

      skuCounting.counter.resetCountById(counterKey);
      skuCounting.setSkuInProgress(undefined);
      setRowInfoToHighlight(undefined);
    },
    [setConfirmModal, skuCounting]
  );

  const resetAfterComplete = useCallback(() => {
    setScanMode("skuId");
    setScannedLocation(undefined);
    setRowInfoToHighlight(undefined);
    skuCounting.setSkuInProgress(undefined);
    setPlacingIdInProgress("");
  }, [setScannedLocation, skuCounting]);

  if (!receivingItem) return null;

  return (
    <Layout
      navigator={{
        title: (
          <>
            분할입고(ID: {getFormattedSingleSkuId(Number(skuId))})
            <br />
            {receivingItem.sku.barCode ? `(${receivingItem.sku.barCode})` : ""}
          </>
        ),
      }}
      confirmMessageToLeave={confirmMessageToLeave}
    >
      <Styled.container>
        {ConfirmModal}

        <Styled.headerForScan>
          <div className="left">{scannedLocation?.barCode}</div>

          <div className="right">
            <Button
              label="상품스캔"
              size="small"
              theme={scanMode === "skuId" ? "tertiary" : "secondary"}
              handleClick={handleScanSKUClick}
            />

            <Button
              label="위치스캔"
              size="small"
              theme={scanMode === "location" ? "tertiary" : "secondary"}
              handleClick={handleScanLocationClick}
            />
          </div>
        </Styled.headerForScan>

        {hasOnlyInitialWarehousing ? (
          <Guide skuId={Number(skuId)} actualQty={receivingItem.actualQty} />
        ) : (
          <PartedList
            receivingId={Number(id)}
            itemId={receivingItem.id}
            skuId={Number(skuId)}
            placeItems={receivingItem.placeItems}
            selectedLocation={scannedLocation}
            rowInfoToHighlight={rowInfoToHighlight}
            setRowInfoToHighlight={setRowInfoToHighlight}
            placingIdInProgress={placingIdInProgress}
            setPlacingIdInProgress={setPlacingIdInProgress}
            skuCounting={skuCounting}
            counterData={skuCounting.counter.counterInfo}
            maxCount={receivingItem.actualQty ?? 0}
            addCountByInput={skuCounting.counter.addCountByInput}
            resetCountByCounterKey={resetCountByCounterKey}
            setConfirmModal={setConfirmModal}
          />
        )}

        <TotalCount
          counted={totalPlacedQty}
          max={receivingItem.actualQty ?? 0}
        />

        <AddPartialWarehousing
          receivingId={Number(id)}
          receivingItem={receivingItem}
        />

        <CompleteWarehousing
          locationType="multi"
          receivingId={Number(id)}
          selectedLocation={scannedLocation}
          counterInProgress={
            skuCounting.skuInProgress
              ? skuCounting.counter.counterInfo[
                  skuCounting.skuInProgress.counterKey
                ]
              : undefined
          }
          resetAfterComplete={resetAfterComplete}
        />
      </Styled.container>

      {ResponseHandlerOfGettingManagerReceivingDetail}
      {ResultHandlerOfScanLocation}
      {ResultHandlerOfScanSKU}
    </Layout>
  );
}

export default withRequireAuth(MultiLocation);
