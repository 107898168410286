import { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useRecoilValue } from "recoil";

import { TableRowInfoToHighlight } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import useValidationErrorModal from "@sellernote/_shared/src/hooks/common/useValidationErrorModal";
import RETURNING_QUERY from "@sellernote/_shared/src/queries/fulfillment/RETURNING_QUERY";
import { FULFILLMENT_AUTH_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/auth";
import { ReceivingItem } from "@sellernote/_shared/src/types/fulfillment/receiving";
import { checkIsGroupedItem } from "@sellernote/_shared/src/utils/fulfillment/common";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";

import useScan from "hooks/common/useScan";
import { returningActions } from "modules/returning";

import ItemStatus from "components/ItemStatus";

import {
  checkScannedSkuInCounterData,
  getCounterKeyFromScanResult,
  getCounterKeyFromScanResultByItemIdInProgress,
  getCounterKeyFromScanResultByPlacingIdInProgress,
  SKUCountingForWarehousing,
  WarehousingCounterSKU,
} from "./useSKUCountingForWarehousing";

/**
 * 일반입고/분할입고를 구분
 */
type ScanTypeInfo =
  | {
      scanType: "single";
      setSkuInProgress: (val: WarehousingCounterSKU | undefined) => void;
      itemList: ReceivingItem[];
      groupedItemIdInProgress: number;
    }
  | { scanType: "multi"; skuId: number; placingId?: string };

export default function useScanWarehousingSKU({
  skuCounting,
  returningId,
  setRowInfoToHighlight,
  ...scanTypeInfo
}: ScanTypeInfo & {
  skuCounting: SKUCountingForWarehousing;
  returningId: number;
  setRowInfoToHighlight: (val: TableRowInfoToHighlight) => void;
}) {
  const dispatch = useDispatch();

  const [active, setActive] = useState(false);

  const currentManager = useRecoilValue(
    FULFILLMENT_AUTH_SELECTORS.CURRENT_MANAGER
  );

  const {
    mutate: assignPlacerToItem,
    ResponseHandler: ResponseHandlerOfAssigningPlacerToItem,
  } = RETURNING_QUERY.useAssignPlacerToItem();

  const [setValidationError, ValidationErrorModal] = useValidationErrorModal();

  const setScanSKUActive = useCallback((val: boolean) => {
    setActive(val);
  }, []);

  const showSelectionErrorForScanSKU = useCallback(() => {
    setValidationError({
      title: `분할입고할 항목을 '선택'한 후 '상품스캔'을 진행해주세요.`,
    });
  }, [setValidationError]);

  const handleScanResult = useCallback(
    (scanResult: string) => {
      if (!active) return;

      if (!returningId || !currentManager) return;

      let scannedCounterKey: string | undefined;

      if (scanTypeInfo.scanType === "multi") {
        if (!scanTypeInfo.placingId) {
          setValidationError({
            title: `분할입고할 항목을 '선택'한 후 '상품스캔'을 진행해주세요.`,
          });
          return;
        }

        // 분할입고의 경우 선택된 placingId를 기준으로 바코드를 찾는다.
        scannedCounterKey = getCounterKeyFromScanResultByPlacingIdInProgress({
          counterData: skuCounting,
          scanResult,
          placingIdInProgress: scanTypeInfo.placingId,
        });
      }

      if (scanTypeInfo.scanType === "single") {
        const isScannedSkuInCounterData = checkScannedSkuInCounterData({
          counterData: skuCounting,
          scanResult,
        });
        if (!isScannedSkuInCounterData) {
          setValidationError({
            title: "상품 바코드가 일치하는 상품이 없습니다.",
          });
          return;
        }

        const isGroupedItemSelected = !!scanTypeInfo.groupedItemIdInProgress;
        const isGroupedItem = checkIsGroupedItem(
          scanTypeInfo.itemList,
          scanResult
        );
        const isInProgress = !!skuCounting.skuInProgress;
        if (!isGroupedItemSelected && isGroupedItem && !isInProgress) {
          setValidationError({
            title: (
              <>
                스캔하신 상품에 정상, 불량, 불일치인 상태 전부 존재합니다.
                스캔할 상품을 선택한 후 ‘상품스캔’을 진행해주세요.
              </>
            ),
          });
          return;
        }

        scannedCounterKey = isGroupedItem
          ? getCounterKeyFromScanResultByItemIdInProgress({
              counterData: skuCounting,
              scanResult,
              itemIdInProgress: scanTypeInfo.groupedItemIdInProgress,
            })
          : getCounterKeyFromScanResult({
              counterData: skuCounting,
              scanResult,
            });
      }

      const otherSkuIsInProgress =
        !!skuCounting.skuInProgress &&
        scannedCounterKey !== skuCounting.skuInProgress.counterKey;
      if (otherSkuIsInProgress) {
        const title =
          scanTypeInfo.scanType === "single" ? (
            <>
              현재 작업중인 입고(SKU ID:{" "}
              {getFormattedSingleSkuId(skuCounting.skuInProgress?.skuId)} /
              상태:{" "}
              <ItemStatus
                statusLabel={skuCounting.skuInProgress?.statusLabel}
                isUnverifiedSku={skuCounting.skuInProgress?.isUnverifiedSku}
              />
              )을 완료한 후에 다른 입고를 진행할 수 있습니다.
            </>
          ) : (
            `현재 작업중인 분할 입고를 완료한 후에 다른 분할 입고를 진행할 수 있습니다.`
          );

        setValidationError({ title });
        return;
      }

      if (!scannedCounterKey) {
        setValidationError({
          title: "상품 바코드가 일치하는 상품이 없습니다.",
        });
        return;
      }

      const target = skuCounting.counter.counterInfo[scannedCounterKey];

      if (target.isCompletePlacing) {
        setValidationError({
          title: `이미 완료한 SKU ID 입니다.`,
        });
        return;
      }

      const isInitialScan = !target.placerId && !target.current;
      if (isInitialScan) {
        // 1) 작업자 지정 api 호출
        assignPlacerToItem(
          {
            pathParams: { returningId, itemId: target.itemId },
            placingId: target.placingId,
          },
          {
            onSuccess: () => {
              // 2) local count ++
              // 위에서 존재여부 이미 검사함
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              skuCounting.counter.addCountById(scannedCounterKey!);
              skuCounting.setSkuInProgress(target);

              setRowInfoToHighlight({
                rowKey:
                  scanTypeInfo.scanType === "single"
                    ? target.itemId
                    : target.placingId,
              });

              // 3) 변경된 상세 데이터 불러오기
              dispatch(
                returningActions.GET_DETAIL({
                  id: returningId,
                })
              );
            },
          }
        );
      } else {
        const isAssignedWorker = target.placerId === currentManager.id;
        if (!isAssignedWorker) {
          setValidationError({
            title: "이미 타 담당자가 해당 상품을 입고중입니다.",
          });
          return;
        }

        skuCounting.counter.addCountById(scannedCounterKey);
        if (target.counterKey !== skuCounting.skuInProgress?.counterKey) {
          skuCounting.setSkuInProgress(target);
        }
        setRowInfoToHighlight({
          rowKey:
            scanTypeInfo.scanType === "single"
              ? target.itemId
              : target.placingId,
        });
      }
    },
    [
      active,
      returningId,
      currentManager,
      scanTypeInfo,
      skuCounting,
      setValidationError,
      assignPlacerToItem,
      setRowInfoToHighlight,
      dispatch,
    ]
  );

  useScan(handleScanResult);

  const ResultHandlerOfScanSKU = useMemo(() => {
    return (
      <>
        {ValidationErrorModal}
        {ResponseHandlerOfAssigningPlacerToItem}
      </>
    );
  }, [ResponseHandlerOfAssigningPlacerToItem, ValidationErrorModal]);

  return {
    setScanSKUActive,
    showSelectionErrorForScanSKU,
    ResultHandlerOfScanSKU,
  };
}
