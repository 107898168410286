import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { RESET_INSPECTOR_AND_INSPECTION_OF_ITEM_REQ_PATH_PARAMS } from "@sellernote/_shared/src/api-interfaces/boful-api/returning";
import RETURNING_QUERY from "@sellernote/_shared/src/queries/fulfillment/RETURNING_QUERY";

import { returningActions } from "modules/returning";

export default function useRemoveInspectionPersonInCharge() {
  const dispatch = useDispatch();

  const history = useHistory();

  const {
    mutate: resetInspectorAndInspectionOfItem,
    ResponseHandler: ResponseHandlerOfRemovingPersonInCharge,
  } = RETURNING_QUERY.useResetInspectorAndInspectionOfItem();

  const removePersonInCharge = useCallback(
    ({
        returningId,
        skuId,
        resetAfterRemoveInProgressPersonInCharge,
      }: RESET_INSPECTOR_AND_INSPECTION_OF_ITEM_REQ_PATH_PARAMS & {
        resetAfterRemoveInProgressPersonInCharge: () => void;
      }) =>
      () => {
        if (!returningId) return;

        resetInspectorAndInspectionOfItem(
          {
            pathParams: { returningId, skuId },
          },
          {
            onSuccess: () => {
              history.replace(location.pathname, {
                inputtingCounterInfo: null,
              });

              dispatch(
                returningActions.GET_DETAIL({
                  id: returningId,
                  _postSuccessCallback:
                    resetAfterRemoveInProgressPersonInCharge,
                })
              );
            },
          }
        );
      },
    [dispatch, history, resetInspectorAndInspectionOfItem]
  );

  return {
    removePersonInCharge,
    ResponseHandlerOfRemovingPersonInCharge,
  };
}
