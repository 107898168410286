import {
  ClassifiedGroupItem,
  GroupDataByType,
} from "../../types/fulfillment/common";
import {
  InspectionStatusReturning,
  ReturningDeliveringStatus,
  ReturningDelivery,
  ReturningListFilterStatus,
  ReturningPacking,
  ReturningProcessStatus,
  ReturningStatus,
  UserReturningDetailItem,
} from "../../types/fulfillment/returning";
import {
  ShippingDeliveryType,
  ShippingItem,
  ShippingPacking,
} from "../../types/fulfillment/shipping";

import {
  getDetailSkuIdList,
  getFilteredItemsByQuantity,
  getGroupDataByType,
  getGroupItems,
  getGroupItemsWithTotalQuantity,
  getItemsWithoutGroupItemsQuantity,
} from "./common";
import {
  getFormattedGroupSkuId,
  getFormattedSingleSkuId,
  toParcelCompanyLabel,
} from "./fulfillment";

/** 반품 진행현황 > ReturningDelivery를 DeliveryType으로 변환 */
function toDeliveryTypeLabel(
  type?: ReturningDelivery
): ShippingDeliveryType | undefined {
  switch (type) {
    case "parcel":
      return "parcel";
    case "truck":
    case "truckRequest":
      return "truck";
  }
}

/** 반품 진행현황 > 검수상태를 반환 */
function toInspectionStatusLabel(
  returningStatus: ReturningStatus,
  inspectionStatus: InspectionStatusReturning
) {
  switch (returningStatus) {
    case "waitingInspection":
      return "검수 대기 중";

    case "inspecting":
      return "검수 중";

    case "completeInspection":
    case "pendingPutAway":
    case "putAway":
    case "done":
      if (inspectionStatus === "hold") return "검수완료(이슈)";
      if (inspectionStatus === "normal") return "검수완료(정상)";
      if (inspectionStatus === "consent") return "검수완료(동의)";
      else return "검수완료(정상)";

    default:
      return "-";
  }
}

/** 반품 진행현황 > 송장번호(차량번호)를 반환 */
function getDeliveryNumberOfReturning({
  deliveryType,
  packings,
  invoiceNo,
  truckNo,
  isFullList,
  needsArray,
}: {
  deliveryType?: ShippingDeliveryType;
  packings?: ReturningPacking[] | ShippingPacking[];
  invoiceNo?: string;
  truckNo?: string;
  isFullList?: boolean;
  needsArray?: boolean;
}) {
  if (deliveryType === "parcel") {
    if (!packings || !packings.length) return "-";

    if (packings.length === 1) return invoiceNo ?? "-";

    if (isFullList && needsArray) {
      return packings.map(({ invoiceNo }) => invoiceNo);
    }

    if (isFullList && !needsArray) {
      return packings.map(({ invoiceNo }) => invoiceNo).join(", ");
    }

    return `${invoiceNo ?? ""} 외 ${packings.length - 1}건`;
  }

  if (deliveryType === "truck") {
    return truckNo ?? "-";
  }

  return "-";
}

const PARCEL_COMPANY_OPTION_LIST = [
  { label: "CJ대한통운", value: "cj" },
  { label: "한진택배", value: "hanjin" },
  { label: "우체국택배", value: "post" },
  { label: "대신택배", value: "daesin" },
  { label: "건영택배", value: "kunyoung" },
  { label: "천일택배", value: "chunil" },
];

const RETURNING_LIST_FILTER_STATUS_DICT: {
  [K in ReturningListFilterStatus]: string;
} = {
  notSent: "반품접수완료",
  delivering: "센터 배송 중",
  doneDelivery: "센터 도착",
  waitingInspection: "검수 대기 중",
  inspecting: "검수중",
  completeInspection: "검수완료",
  pendingPutAway: "입고 대기 중",
  putAway: "입고 중",
  done: "입고 완료",
  hold: "전담매니저 확인중",
  uncollected: "미집화",
  emptyInvoice: "반품송장 번호 입력",
};

const RETURNING_PROCESS_STATUS_DICT: {
  [K in ReturningProcessStatus]: string;
} = {
  restock: "재입고",
  disposal: "폐기",
  forward: "회송",
};

/**
 * (ex. Admin 반품리스트의 반품현황에서)
 * returning의 여러 값을 고려하여 대표 상태(status) 값을 표시함
 */
function getUnitedStatus({
  status,
  deliveringStatus,
  invoiceNo,
}: {
  status?: ReturningStatus;
  deliveringStatus?: ReturningDeliveringStatus;
  invoiceNo?: string | "운송장 입력 대기 중";
}): ReturningListFilterStatus | undefined {
  if (invoiceNo === "운송장 입력 대기 중") {
    // 운송장 없이 등록한 경우 이렇게 저장되어있다고 함
    return "emptyInvoice";
  }

  switch (status) {
    case "beforeReturning": {
      if (deliveringStatus === "notSent") {
        return "notSent";
      }

      if (deliveringStatus === "delivering") {
        return "delivering";
      }

      if (deliveringStatus === "done") {
        return "doneDelivery";
      }

      if (deliveringStatus === "uncollected") {
        return "uncollected";
      }

      return;
    }

    case "waitingInspection": {
      return "waitingInspection";
    }

    case "pendingPutAway": {
      return "pendingPutAway";
    }

    case "putAway": {
      return "putAway";
    }

    case "done": {
      return "done";
    }

    case "inspecting": {
      return "inspecting";
    }

    case "completeInspection": {
      return "completeInspection";
    }

    case "hold": {
      return "hold";
    }

    default: {
      return;
    }
  }
}

function checkCanRestock({ status }: { status: ReturningStatus }): boolean {
  return status === "pendingPutAway";
}

function checkCanDisposal({ status }: { status: ReturningStatus }): boolean {
  return status === "pendingPutAway";
}

function checkCanForward({
  status,
  deliveringStatus,
}: {
  status: ReturningStatus;
  deliveringStatus: ReturningDeliveringStatus;
}): boolean {
  // 반품현황이 반품접수~센터도착일때만(= 반품접수완료, 센터 배송 중, 센터 도착) 회송 가능

  if (status !== "beforeReturning") return false;

  switch (deliveringStatus) {
    case "notSent":
    case "delivering":
    case "done": {
      return true;
    }

    default: {
      return false;
    }
  }
}

function checkNeedToDisplayReturningInvoice(invoiceNo?: string) {
  // 운송장 입력 대기 중, 수거 준비 중, 값이 없는 상태에서는 반품송장번호에 출고송장번호가 임시로 들어가므로 보이지 않도록 처리.
  if (
    invoiceNo === "운송장 입력 대기 중" ||
    invoiceNo === "수거 준비 중" ||
    !invoiceNo
  ) {
    return false;
  }

  return true;
}

/**
 * CSV 다운로드 시에는 출고 상품 기준으로 행을 만들기 위해서
 * 반품주문수량이 0인 상품도 포함시켜야 함
 */
const getFilteredItemsForCSV = ({
  items,
  shippingItems,
}: {
  items: UserReturningDetailItem[] | undefined;
  shippingItems: CSVListType["shippingItems"] | undefined;
}) => {
  const checkIsSingleItem = (item: UserReturningDetailItem) => {
    // items에서 그룹상품의 수량을 빼고 quantity가 남는 item은 단일상품
    const hasQuantity = item.quantity > 0;
    if (hasQuantity) {
      return true;
    }

    /**
     * quantity가 0이지만, 출고에 해당 상품이 있으면 단일상품
     * shippingItem에는 그룹상품도 있지만, SKU ID 범위가 다르기 때문에 겹칠일이 없음
     */
    const isShippingItem = (shippingItems ?? []).some(
      (shippingItem) => shippingItem.skuId === item.skuId
    );

    return isShippingItem;
  };

  return (items ?? []).filter(checkIsSingleItem);
};

/**
 * items와 그룹상품의 정보를 통해 items에서 반품신청된 단일상품만 필터링
 */
const getSingleReturningItems = ({
  items,
  ...paramsByType
}: {
  items: UserReturningDetailItem[] | undefined;
} & GroupDataByType &
  ParamsByListType) => {
  const groupItemsWithTotalQuantity =
    getGroupItemsWithTotalQuantity(paramsByType);

  const detailSkuIdList = getDetailSkuIdList(groupItemsWithTotalQuantity);

  const itemsWithoutGroupItemsQuantity = getItemsWithoutGroupItemsQuantity({
    items,
    detailSkuIdList,
    groupItemsWithTotalQuantity,
  });

  // 단일상품에는 productGroupId가 없음
  const itemsWithoutProductGroupId = items?.filter(
    (item) => !item.productGroupId
  );

  const needsNotReturningRequestedItems = paramsByType.listType === "adminCsv";
  if (needsNotReturningRequestedItems) {
    return getFilteredItemsForCSV({
      items: itemsWithoutProductGroupId,
      shippingItems: paramsByType.shippingItems,
    });
  }

  return itemsWithoutProductGroupId;
};

/**
 * CSV 다운로드 시에는 출고 상품 기준으로 행을 만들기 위해서
 * 반품주문수량이 0인 상품도 포함시키기 위해 ShippingItems 데이터도 필요함
 */
type ParamsByListType = WebListType | CSVListType;
interface WebListType {
  listType: "web";
}
interface CSVListType {
  listType: "adminCsv";
  shippingItems: (ShippingItem | ClassifiedGroupItem)[];
}
/**
 * 반품관리에서 반품신청된 items를 [그룹상품, 단일상품] 형태로 분류하기 위한 함수
 * 단, Admin 엑셀 다운로드 시에는 출고 상품 기준으로 행을 만들기 위해서 반품주문수량이 0인 상품도 포함시킴
 */
const classifyReturningItems = ({
  items,
  ...paramsByType
}: {
  items: UserReturningDetailItem[] | undefined;
} & GroupDataByType &
  ParamsByListType) => {
  const needsNotReturningRequestedItems = paramsByType.listType === "adminCsv";

  const groupData = getGroupDataByType(paramsByType);

  const hasGroupItems = groupData.length > 0;
  if (!hasGroupItems) {
    if (needsNotReturningRequestedItems) {
      return items ?? [];
    }

    return getFilteredItemsByQuantity(items);
  }

  // 반품 진행내역 리스트에서는 그룹상품의 수량 관계없이 반품신청된 그룹상품은 모두 productGroupIds 에 오므로 필터링 하지 않고 표기.
  const groupItems = (() => {
    const items = getGroupItems(paramsByType);

    return items;
  })();

  const singleItems = getSingleReturningItems({ items, ...paramsByType }) ?? [];

  return [...groupItems, ...singleItems];
};

const getFormattedReturningSkuId = (
  item: UserReturningDetailItem | ClassifiedGroupItem
) => {
  if (!item) {
    return "-";
  }

  return item.isGroup
    ? getFormattedGroupSkuId(item.skuId)
    : getFormattedSingleSkuId(item.skuId);
};

/**
 * https://www.notion.so/shipda/4ee5f64eaed248c4b5640f711fe9e570
 * 그룹상품을 우선(1순위), 수량이 많은 상품을 우선(2순위)으로 대표상품으로 표기
 */
const getRepresentativeProduct = (
  item: (UserReturningDetailItem | ClassifiedGroupItem)[]
) =>
  item.reduce((prev, cur) => {
    if ((!prev.isGroup && cur.isGroup) || prev.quantity < cur.quantity) {
      return cur;
    }

    return prev;
  });

const getReturningSkuIdList = (
  items: (UserReturningDetailItem | ClassifiedGroupItem)[]
) => {
  if (!items || !items.length) {
    return "-";
  }

  if (items.length === 1) {
    return getFormattedReturningSkuId(items[0]);
  }

  const representativeProduct = getRepresentativeProduct(items);

  return `${getFormattedReturningSkuId(representativeProduct)} 외 ${
    items.length - 1
  }건`;
};

/**
 * 수량이 가장 많은 상품이 대표상품. ㅇㅇ등으로 표기.
 */
function getReturningItemTitle(
  items?: (UserReturningDetailItem | ClassifiedGroupItem)[]
) {
  if (!items || !items.length) return "-";
  if (items.length === 1) return items[0].sku?.itemName;

  const hasGroupItem = items.some((item) => item.isGroup);

  const groupItemTitle = items.find((item) => item.isGroup)?.sku?.itemName;

  const maxQuantityItem = items.reduce((prev, cur) =>
    (prev.quantity ?? 0) > (cur.quantity ?? 0) ? prev : cur
  );

  return hasGroupItem
    ? `${groupItemTitle} 등`
    : `${maxQuantityItem.sku?.itemName} 등`;
}

/**
 * 반품관리 > 반품 진행내역 > 운송사
 * 현재는 택배만 반품 가능하기 때문에 택배만 표시
 */
const getReturningDeliveryName = ({
  packings,
}: {
  packings: ReturningPacking[] | undefined;
}) => {
  if (!packings) {
    return "-";
  }

  const parcelCompanyList = [
    ...new Set(
      packings.map(({ parcelCompany }) => toParcelCompanyLabel(parcelCompany))
    ),
  ];

  return (
    {
      0: "-",
      1: parcelCompanyList[0],
    }[parcelCompanyList.length] ??
    `${parcelCompanyList[0]} 외 ${parcelCompanyList.length - 1}건`
  );
};

const getReturningType = (isAlreadyApplied: boolean) =>
  isAlreadyApplied ? "고객사 측 입고" : "쉽다 측 수거";

const toReturningDeliveringStatusLabel = (
  status: ReturningDeliveringStatus
) => {
  switch (status) {
    case "uncollected": {
      return "-";
    }
    case "notSent": {
      return "수거 준비 중";
    }
    case "moveToParcelCompany": {
      return "택배사 전달";
    }
    case "delivering": {
      return "센터 배송 중";
    }
    case "done": {
      return "센터 도착완료";
    }
    default: {
      return "";
    }
  }
};

const getReturningStatusLabel = ({
  status,
  inspectionStatus,
  deliveringStatus,
}: {
  status: ReturningStatus;
  inspectionStatus: InspectionStatusReturning;
  deliveringStatus: ReturningDeliveringStatus;
}) => {
  switch (status) {
    case "beforeReturning": {
      if (deliveringStatus === "notSent") {
        return "반품접수완료";
      }
      return "도착 전";
    }
    case "waitingInspection": {
      return "검수 대기 중";
    }
    case "inspecting": {
      return "검수 중";
    }
    case "completeInspection": {
      if (inspectionStatus === "hold") {
        return "검수완료(이슈)";
      }
      return "";
    }
    case "putAway": {
      return "입고 중";
    }
    case "hold": {
      return "전담매니저 확인 중";
    }
    case "done": {
      return "입고완료";
    }
    default: {
      return "";
    }
  }
};

const getFormattedReturningId = (
  returning:
    | {
        id?: number;
      }
    | undefined
) => {
  if (!returning) {
    return "";
  }

  return `R${returning.id}`;
};

const getReturningInvoiceNoForPDA = (
  returningPackingList: ReturningPacking[] | undefined
) => {
  if (!returningPackingList || !returningPackingList.length) {
    return "-";
  }

  const invoiceNoList = returningPackingList.map(({ invoiceNo }) =>
    Number(invoiceNo)
  );

  if (invoiceNoList.length === 1) {
    return String(invoiceNoList[0]);
  }

  return `${Math.min(...invoiceNoList)} 외 ${invoiceNoList.length - 1}건`;
};

const getGroupedItemSkuIdList = (
  warehousingList: { id: number; sku: { id: number } }[]
) =>
  warehousingList.reduce<number[]>((acc, cur) => {
    if (cur.id < 0) {
      return [...acc, cur.sku.id];
    }

    return acc;
  }, []);

const checkIsGroupedItemBySkuId = (
  groupedItemSkuIdList: number[],
  targetSkuId: number
) => groupedItemSkuIdList.includes(targetSkuId);

export {
  RETURNING_PROCESS_STATUS_DICT,
  RETURNING_LIST_FILTER_STATUS_DICT,
  PARCEL_COMPANY_OPTION_LIST,
  getDeliveryNumberOfReturning,
  toDeliveryTypeLabel,
  toInspectionStatusLabel,
  getUnitedStatus,
  checkCanRestock,
  checkCanDisposal,
  checkCanForward,
  checkNeedToDisplayReturningInvoice,
  getFilteredItemsForCSV,
  getSingleReturningItems,
  classifyReturningItems,
  getReturningDeliveryName,
  getReturningType,
  toReturningDeliveringStatusLabel,
  getReturningStatusLabel,
  getFormattedReturningId,
  getReturningSkuIdList,
  getReturningItemTitle,
  getReturningInvoiceNoForPDA,
  getGroupedItemSkuIdList,
  checkIsGroupedItemBySkuId,
};
