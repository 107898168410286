import React, { useState } from "react";
import { useMemo } from "react";

import { GET_DETAIL_RES_DATA_RECEIVING } from "@sellernote/_shared/src/api-interfaces/boful-api/receiving";
import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import {
  isTodayOrBeforeToday,
  toFormattedDate,
} from "@sellernote/_shared/src/utils/common/date";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import { getHighlightedLabel } from "@sellernote/_shared/src/utils/common/string";
import { getSKUItemTypeQuantity } from "@sellernote/_shared/src/utils/fulfillment/common";
import {
  getFormattedSingleSkuId,
  toParcelCompanyLabel,
} from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import {
  getBLNumber,
  getReceivingDeliveryTitle,
  getReceivingExpectedDate,
  getReceivingItemTitle,
  getTotalPackingUnit,
  getTotalPCS,
  getTransportMethodTitle,
} from "@sellernote/_shared/src/utils/fulfillment/receiving";
import ListItem from "@sellernote/_sds-v1/src/components/ListItem";

import Styled from "../index.styles";
import ChangeTotalPackingUnitModal from "./ChangeTotalPackingUnitModal";
import { DataToChangeTotalPackingUnit } from "./ChangeTotalPackingUnitModal/useChangeTotalPackingUnit";

function getInfoSummary(data: GET_DETAIL_RES_DATA_RECEIVING | undefined) {
  const result = [];
  let key = 0;

  const isImport = data?.receivingKind === "import";
  const isDomestic = data?.receivingKind === "domestic";
  const isParcel = data?.delivery === "parcel";

  if (isImport) {
    result.push(
      <ListItem
        key={key++}
        type="withContentAlignRight"
        label={"B/L(AWB)"}
        value={getBLNumber(data?.bid)}
      />
    );
  }

  if (isDomestic) {
    result.push(
      <ListItem
        key={key++}
        type="withContentAlignRight"
        label={"입고방식"}
        value={
          <>
            <div>{getReceivingDeliveryTitle(data?.delivery)}</div>

            {isParcel
              ? (data?.invoiceNo ?? []).map(({ parcelCompany, invoiceNo }) => (
                  <div key={invoiceNo}>
                    {toParcelCompanyLabel(parcelCompany)} {invoiceNo}
                  </div>
                ))
              : data?.driver?.truckNo}
          </>
        }
      />
    );
  }

  result.push(
    <ListItem
      key={key++}
      type="withContentAlignRight"
      label={"상품명"}
      value={getReceivingItemTitle({ items: data?.items })}
    />
  );

  result.push(
    <ListItem
      key={key++}
      type="withContentAlignRight"
      label={"상품종류(SKU)"}
      value={getSKUItemTypeQuantity(data?.items)}
    />
  );

  if (isImport) {
    result.push(
      <ListItem
        key={key++}
        type="withContentAlignRight"
        label={"화물디테일"}
        value={
          <>
            {data?.bid?.package}
            <br />
            {data?.bid?.invoiceCbm}
          </>
        }
      />
    );
  }

  return result;
}

function getInfoDetail({
  data,
  setDataToChangeTotalPackingUnit,
}: {
  data: GET_DETAIL_RES_DATA_RECEIVING | undefined;
  setDataToChangeTotalPackingUnit: React.Dispatch<
    React.SetStateAction<DataToChangeTotalPackingUnit | undefined>
  >;
}) {
  const result = [];
  let key = 0;

  const isImport = data?.receivingKind === "import";

  result.push(
    <ListItem
      key={key++}
      type="withContentAlignRight"
      label={"상품 정보"}
      value={data?.items.map((item) => (
        <div key={item.skuId}>{`・${getFormattedSingleSkuId(item.skuId)} / ${
          item.sku.itemName ?? "-"
        } / ${item.sku.productCode ?? "-"} / ${
          item.sku.managementCode ?? "-"
        }`}</div>
      ))}
    />
  );

  result.push(
    <ListItem
      key={key++}
      type="withContentAlignRight"
      label={"총 포장개수"}
      value={
        <>
          <span className="total-packing-unit">
            {getTotalPackingUnit({
              packingUnitPallet: data?.packingUnitPallet,
              packingUnitBox: data?.packingUnitBox,
              packingUnitOther: data?.packingUnitOther,
            })}
          </span>
          <Button
            theme="dangerStroke"
            label="수정"
            size="small"
            handleClick={() =>
              setDataToChangeTotalPackingUnit({
                packingUnitPallet: data?.packingUnitPallet,
                packingUnitBox: data?.packingUnitBox,
                packingUnitOther: data?.packingUnitOther,
              })
            }
          />
        </>
      }
    />
  );

  result.push(
    <ListItem
      key={key++}
      type="withContentAlignRight"
      label={"총 입고수량"}
      value={toThousandUnitFormat(getTotalPCS(data?.items))}
    />
  );

  if (isImport) {
    result.push(
      <ListItem
        key={key++}
        type="withContentAlignRight"
        label={"운송방식"}
        value={getTransportMethodTitle({
          freightType: data?.bid?.freightType,
        })}
      />
    );
  }

  result.push(
    <ListItem
      key={key++}
      type="withContentAlignRight"
      label={"도착요청일"}
      value={getReceivingExpectedDate(data)}
    />
  );

  result.push(
    <ListItem
      key={key++}
      type="withContentAlignRight"
      label={"입고기한"}
      value={getHighlightedLabel({
        label: toFormattedDate(data?.dueDate, "YYYY-MM-DD"),
        isHighlighted: isTodayOrBeforeToday(data?.dueDate),
      })}
    />
  );

  return result;
}

export default function ConfirmDetailInfo({
  data,
}: {
  data: GET_DETAIL_RES_DATA_RECEIVING | undefined;
}) {
  const [dataToChangeTotalPackingUnit, setDataToChangeTotalPackingUnit] =
    useState<DataToChangeTotalPackingUnit>();

  const InfoSummary = useMemo(() => getInfoSummary(data), [data]);
  const InfoDetail = useMemo(
    () => getInfoDetail({ data, setDataToChangeTotalPackingUnit }),
    [data]
  );

  if (!data) return null;

  return (
    <>
      <Styled.info>
        <div className="kind">
          {data.receivingKind === "import" ? "[수입]" : "[국내]"}
        </div>

        <div className="summary">{InfoSummary}</div>

        <div className="detail">{InfoDetail}</div>
      </Styled.info>

      {!!dataToChangeTotalPackingUnit && (
        <ChangeTotalPackingUnitModal
          dataToChangeTotalPackingUnit={dataToChangeTotalPackingUnit}
          setDataToChangeTotalPackingUnit={setDataToChangeTotalPackingUnit}
        />
      )}
    </>
  );
}
