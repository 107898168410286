import { useHistory } from "react-router-dom";
import { useSetRecoilState } from "recoil";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import { FULFILLMENT_COMMON_ATOMS } from "@sellernote/_shared/src/states/fulfillment/common";
import { UseCounterDataValue } from "@sellernote/_shared/src/utils/common/hook";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import { replaceEmptyToDash } from "@sellernote/_shared/src/utils/common/string";

import { InspectionCounterSKU } from "hooks/returning/useSKUCountingForInspection";

import Styled from "./index.styles";

export default function ProblemInput({
  itemId,
  skuId,
  inspectionCount,
  problemCount,
  disabled,
  inputtingCounterInfo,
}: {
  itemId: number;
  skuId: number;
  inspectionCount: number;
  problemCount?: number;
  disabled: boolean;
  inputtingCounterInfo: UseCounterDataValue<InspectionCounterSKU>;
}) {
  const history = useHistory();

  const setCanNotLeavePage = useSetRecoilState(
    FULFILLMENT_COMMON_ATOMS.CAN_NOT_LEAVE_PAGE
  );

  const hasProblemItem = !!problemCount;

  return (
    <Styled.container hasProblemItem={hasProblemItem}>
      <div>{replaceEmptyToDash(toThousandUnitFormat(problemCount), true)}</div>

      <Button
        label={hasProblemItem ? "수정" : "입력"}
        theme={hasProblemItem ? "secondary" : "dangerStroke"}
        size="small"
        handleClick={() => {
          // 비정상 리스트 페이지에서는 뒤로가기 확인없이 가능하도록 처리.
          setCanNotLeavePage(false);

          history.push(
            `${history.location.pathname}/${skuId}/${itemId}/problem-item?inspectionCount=${inspectionCount}`,
            {
              inputtingCounterInfo,
              prevPath: history.location.pathname,
            }
          );
        }}
        disabled={disabled}
      />
    </Styled.container>
  );
}
