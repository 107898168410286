import React, { useCallback, useMemo } from "react";
import { useRecoilValue } from "recoil";

import Icon from "@sellernote/_shared/src/componentsToMoveToV1/Icon";
import {
  TableDataListItem,
  TableRowInfoToHighlight,
} from "@sellernote/_shared/src/headlessComponents/table/useTable";
import { FULFILLMENT_AUTH_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/auth";
import { FULFILLMENT_RECEIVING_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/receiving";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { ReceivingInspectItem } from "@sellernote/_shared/src/types/fulfillment/receiving";
import TableForCounting from "@sellernote/_sds-v1/src/components/table/TableForCounting";

import { ConfirmModal } from "hooks/common/useConfirmModal";
import useCountForScanning from "hooks/common/useCountForScanning";
import useGetWorkerByIdFactory from "hooks/common/useGetWorkerByIdFactory";
import useRemoveInspectionPersonInCharge from "hooks/receiving/useRemoveInspectionPersonInCharge";
import useResetInspectionOfItem from "hooks/receiving/useResetInspectionOfItem";
import {
  CounterDataForInspection,
  getCounterKeyForMultiLocationInspection,
  SKUCountingForInspection,
} from "hooks/receiving/useSKUCountingForInspection";

import CountForScanning from "components/CountForScanning";
import PersonInCharge from "components/PersonInCharge";
import RemovePartialItem from "components/RemovePartialItem";
import StyledForRemove from "components/RemovePartialItem/index.styles";
import ResetCountingAsPartial from "components/ResetCountingAsPartial";
import StyledForReset from "components/ResetCountingAsPartial/index.styles";

import useRemovePartialInspection from "./useRemovePartialInspection";

interface InspectionPartedListTableItem {
  selection: React.ReactNode;
  count: React.ReactNode;
  personInCharge?: React.ReactNode;
  status: "Y" | "N";
  reset: React.ReactNode;
  remove: React.ReactNode;
}

export default function PartedList({
  receivingId,
  itemId,
  skuId,
  inspectItems,
  rowInfoToHighlight,
  setRowInfoToHighlight,
  inspectingIdInProgress,
  setInspectingIdInProgress,
  skuCounting,
  counterData,
  maxCount,
  addCountByInput,
  resetCountByCounterKey,
  setConfirmModal,
}: {
  receivingId: number;
  itemId: number;
  skuId: number;
  inspectItems: ReceivingInspectItem[];
  rowInfoToHighlight: TableRowInfoToHighlight | undefined;
  setRowInfoToHighlight: (val: TableRowInfoToHighlight | undefined) => void;
  inspectingIdInProgress?: string;
  setInspectingIdInProgress: (val: string) => void;
  skuCounting: SKUCountingForInspection;
  counterData: CounterDataForInspection;
  maxCount: number;
  addCountByInput: ({ id, count }: { id: string; count: number }) => void;
  resetCountByCounterKey: (counterKey: string) => () => void;
  setConfirmModal: React.Dispatch<
    React.SetStateAction<ConfirmModal | undefined>
  >;
}) {
  const currentManager = useRecoilValue(
    FULFILLMENT_AUTH_SELECTORS.CURRENT_MANAGER
  );
  const isCurrentManagerReceivingManager = useRecoilValue(
    FULFILLMENT_RECEIVING_SELECTORS.IS_CURRENT_MANAGER_RECEIVING_MANAGER
  );

  const {
    CountForScanningModal,
    handleDirectInputModalOpen,
    setCountForScanning,
  } = useCountForScanning();

  const getWorkerById = useGetWorkerByIdFactory();

  const { removePersonInCharge, ResponseHandlerOfRemovingPersonInCharge } =
    useRemoveInspectionPersonInCharge();

  const {
    resetInspectionOfItem,
    ResponseHandlerOfResetInspectionOfItemExceptInspector,
  } = useResetInspectionOfItem();

  const {
    removePartialInspectionFromItem,
    ResponseHandlerOfRemovingPartialInspectionFromItem,
  } = useRemovePartialInspection({ receivingId });

  const handleCountAddByDirectInput = useCallback(
    (counterKey: string) => (count: number | undefined) => {
      if (!count) return;

      const target = skuCounting.counter.counterInfo[counterKey];

      addCountByInput({ id: counterKey, count });

      setRowInfoToHighlight({ rowKey: target.inspectingId });
    },
    [addCountByInput, setRowInfoToHighlight, skuCounting]
  );

  const tableDataList = useMemo(() => {
    const isLastRemaining = inspectItems.length === 1;

    return inspectItems.map((v) => {
      const isSelected = v.inspectingId === inspectingIdInProgress;

      const completedInspection = v.isCompleteInspecting;

      const counterKey = getCounterKeyForMultiLocationInspection(
        skuId,
        v.inspectingId
      );
      const counter = counterData[counterKey];

      const currentUserIsMultiLocationInspectionAssignee =
        v.inspectorId === currentManager?.id;

      const currentCount = counter?.current || v.actualQty || 0;

      const skuInprogress =
        v.inspectingId === skuCounting.skuInProgress?.inspectingId;

      const canOpenDirectInputModal =
        isSelected &&
        currentUserIsMultiLocationInspectionAssignee &&
        !completedInspection &&
        skuInprogress;

      const canReset =
        currentUserIsMultiLocationInspectionAssignee &&
        (completedInspection || !!counter?.current);

      const isNowCounting =
        isSelected && !completedInspection && !!counter?.current;

      const mapped: TableDataListItem<InspectionPartedListTableItem> = {
        rowKey: v.inspectingId,

        selection: isSelected ? (
          <Icon type="radioSelected" size={1} color={COLOR.primaryBlue} />
        ) : completedInspection ? (
          <Icon
            type="checkStrokeCircleDisabled"
            size={1}
            color={COLOR.grayScale_300}
          />
        ) : (
          <Icon
            type="radio"
            size={1}
            color={COLOR.grayScale_300}
            onClick={() => setInspectingIdInProgress(v.inspectingId)}
          />
        ),

        count: (
          <CountForScanning
            // type="inspection"
            canOpenDirectInputModal={canOpenDirectInputModal}
            // skuId={skuId}
            currentQty={currentCount}
            quantity={v.quantity}
            // onCountAdd={handleCountAddByDirectInput(counterKey)}
            openDirectInputModal={() => {
              handleDirectInputModalOpen();
              setCountForScanning({
                type: "receivingInspection",
                skuId,
                currentQty: currentCount,
                quantity: v.quantity,
                onCountAdd: handleCountAddByDirectInput(counterKey),
              });
            }}
          />
        ),

        personInCharge: (
          <PersonInCharge
            worker={getWorkerById(v.inspectorId)?.name}
            isRemovable={isCurrentManagerReceivingManager}
            openConfirmModal={() => {
              setConfirmModal({
                uiType: "titleOnly",
                title: "SKU 담당자를 삭제하시겠습니까?",
                actions: {
                  actionPositive: {
                    label: "예",
                    handleClick: removePersonInCharge({
                      receivingId,
                      itemId,
                      inspectingId: v.inspectingId,
                      resetAfterRemoveInProgressPersonInCharge:
                        resetCountByCounterKey(counterKey),
                    }),
                  },
                  actionNegative: {
                    label: "아니오",
                    handleClick: () => setConfirmModal(undefined),
                  },
                },
              });
            }}
            // removePersonInCharge={removePersonInCharge({
            //   receivingId,
            //   itemId,
            //   inspectingId: v.inspectingId,
            //   resetAfterRemoveInProgressPersonInCharge:
            //     resetCountByCounterKey(counterKey),
            // })}
          />
        ),

        status: completedInspection ? "Y" : "N",

        reset: (
          <ResetCountingAsPartial
            type="inspection"
            canReset={canReset}
            openConfirmModal={() => {
              setConfirmModal({
                uiType: "titleOnly",
                title: (
                  <StyledForReset.resetConfirmModalTitle>
                    선택한 항목의 분할검수를 <b>초기화</b>하겠습니까?
                  </StyledForReset.resetConfirmModalTitle>
                ),
                actions: {
                  actionPositive: {
                    label: "예",
                    handleClick: resetInspectionOfItem({
                      receivingId,
                      itemId,
                      inspectingId: v.inspectingId,
                      resetLocalCount: resetCountByCounterKey(counterKey),
                    }),
                  },
                  actionNegative: {
                    label: "아니오",
                    handleClick: () => setConfirmModal(undefined),
                  },
                },
              });
            }}
            // reset={resetInspectionOfItem({
            //   receivingId,
            //   itemId,
            //   inspectingId: v.inspectingId,
            //   resetLocalCount: resetCountByCounterKey(counterKey),
            // })}
          />
        ),

        remove: (
          <RemovePartialItem
            type="inspection"
            isNowCounting={isNowCounting}
            openConfirmModal={() => {
              isNowCounting
                ? setConfirmModal({
                    uiType: "titleOnly",
                    title: (
                      <>
                        현재 작업 중인 항목은 삭제할 수 없습니다 <br /> (초기화
                        후 진행 가능)
                      </>
                    ),
                    actions: {
                      actionPositive: {
                        label: "확인",
                        handleClick: () => setConfirmModal(undefined),
                      },
                    },
                  })
                : setConfirmModal({
                    uiType: "titleOnly",
                    title: (
                      <StyledForRemove.removeConfirmModalTitle>
                        선택한 항목의 분할검수를 <b>삭제</b>
                        하겠습니까?
                      </StyledForRemove.removeConfirmModalTitle>
                    ),
                    actions: {
                      actionPositive: {
                        label: "예",
                        handleClick: removePartialInspectionFromItem({
                          receivingId,
                          itemId,
                          inspectingId: v.inspectingId,
                          isLastRemaining,
                          skuId,
                          maxCount,
                          postSuccessCallback: () => {
                            setInspectingIdInProgress("");
                            setConfirmModal(undefined);
                          },
                        }),
                      },
                      actionNegative: {
                        label: "아니오",
                        handleClick: () => setConfirmModal(undefined),
                      },
                    },
                  });
            }}
            // remove={removePartialInspection({
            //   receivingId,
            //   itemId,
            //   inspectingId: v.inspectingId,
            //   isLastRemaining,
            //   skuId,
            //   maxCount,
            //   setInspectingIdInProgress,
            // })}
          />
        ),
      };

      return mapped;
    });
  }, [
    inspectItems,
    inspectingIdInProgress,
    skuId,
    counterData,
    currentManager?.id,
    skuCounting.skuInProgress?.inspectingId,
    getWorkerById,
    isCurrentManagerReceivingManager,
    setInspectingIdInProgress,
    handleDirectInputModalOpen,
    setCountForScanning,
    handleCountAddByDirectInput,
    setConfirmModal,
    removePersonInCharge,
    receivingId,
    itemId,
    resetCountByCounterKey,
    resetInspectionOfItem,
    removePartialInspectionFromItem,
    maxCount,
  ]);

  return (
    <>
      <TableForCounting<InspectionPartedListTableItem>
        isWindowed
        height={300}
        rowInfoToHighlight={rowInfoToHighlight}
        columnInfo={{
          selection: {
            label: "선택",
            fixedWidth: 60,
          },
          count: {
            label: "카운트",
            fixedWidth: 110,
          },
          personInCharge: {
            label: "담당자",
            fixedWidth: 150,
          },
          status: {
            label: "검품완료여부",
            fixedWidth: 100,
          },
          reset: {
            label: "초기화",
            fixedWidth: 110,
          },
          remove: {
            label: "삭제",
            fixedWidth: 110,
          },
        }}
        dataList={tableDataList}
      />

      {CountForScanningModal}

      {ResponseHandlerOfRemovingPersonInCharge}
      {ResponseHandlerOfResetInspectionOfItemExceptInspector}
      {ResponseHandlerOfRemovingPartialInspectionFromItem}
    </>
  );
}
