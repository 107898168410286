import { SCAN_RESULT_READ_FAIL } from "constants/scan";

import { useCallback, useMemo } from "react";

import useValidationErrorModal from "@sellernote/_shared/src/hooks/common/useValidationErrorModal";
import { ReceivingItem } from "@sellernote/_shared/src/types/fulfillment/receiving";
import { getFormattedSkuId } from "@sellernote/_shared/src/utils/fulfillment/common";
import { getFormattedReturningId } from "@sellernote/_shared/src/utils/fulfillment/returning";

import useScan from "hooks/common/useScan";

export default function useScanInspectionUnverifiedItem({
  returningId,
  normalItemList,
  addScannedUnverifiedItem,
}: {
  returningId: number;
  normalItemList?: ReceivingItem[];
  addScannedUnverifiedItem: (barcode: string) => void;
}) {
  const [setValidationError, ValidationErrorModal] = useValidationErrorModal();

  const handleScanResult = useCallback(
    (scanResult: string) => {
      if (!returningId) return;

      // 허공이나 손상된 바코드를 스캔하는 경우
      if (scanResult === SCAN_RESULT_READ_FAIL) {
        setValidationError({
          title: (
            <>
              정상 스캔이 되지 않았습니다.
              <br />
              다시 스캔하거나 직접 입력해 주세요.
            </>
          ),
        });

        return;
      }

      const registeredNormalItem = normalItemList?.find(
        ({ sku, skuId }) =>
          sku.barCode === scanResult ||
          getFormattedSkuId({ skuId }) === scanResult
      );

      // 검수 리스트의 반품요청상품 바코드 스캔하는 경우
      if (registeredNormalItem) {
        setValidationError({
          title: (
            <>
              스캔하신 상품은 {getFormattedReturningId({ id: returningId })}에
              해당하는 상품입니다. 불일치 상품 입력 화면을 이탈 후 다시
              스캔해주세요.
            </>
          ),
        });

        return;
      }

      addScannedUnverifiedItem(scanResult);
    },
    [addScannedUnverifiedItem, normalItemList, returningId, setValidationError]
  );

  useScan(handleScanResult);

  const ResultHandlerOfScanUnverifiedItem = useMemo(
    () => <>{ValidationErrorModal}</>,
    [ValidationErrorModal]
  );

  return {
    ResultHandlerOfScanUnverifiedItem,
  };
}
