import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

const container = styled.div<{ isWithoutDefaultPadding?: boolean }>`
  height: 100%;
  min-height: 100vh;

  > header {
    padding-left: 16px;
    padding-right: 12px;
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${COLOR.white};

    img.logo {
      width: 70px;
    }
  }

  > .user-info {
    height: 38px;
    background-color: ${COLOR.bgColor_2};
    padding: 8px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > .left {
      ${setFontStyle("SubHead3")};
    }

    > .right {
      ${setFontStyle("SubHead3", "Bold")};
    }
  }

  > .navigator {
    text-align: center;
    padding: 16px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: ${COLOR.white};
    border-bottom: 1px solid ${COLOR.grayScale_200};

    > .confirm-refresh,
    > .icon-reset {
      position: absolute;
      right: 12px;
    }

    > .confirm-back,
    > .icon-arrowLeft {
      position: absolute;
      left: 8px;
    }

    > .title {
      ${setFontStyle("Head6", "Bold")};
      color: ${COLOR.grayScale_800};
      max-width: 250px;
    }
  }

  > main {
    padding: ${({ isWithoutDefaultPadding }) =>
      isWithoutDefaultPadding ? "0px" : "16px"};
  }
`;

export default {
  container,
};
