import { ReactNode, useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { SET_INSPECTION_DONE_REQ_ITEM } from "@sellernote/_shared/src/api-interfaces/boful-api/receiving";
import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import { TableDataListItem } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import RECEIVING_QUERY from "@sellernote/_shared/src/queries/fulfillment/RECEIVING_QUERY";
import { FULFILLMENT_RECEIVING_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/receiving";
import { ReceivingItem } from "@sellernote/_shared/src/types/fulfillment/receiving";
import { validateCount } from "@sellernote/_shared/src/utils/common/validation";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import InputText from "@sellernote/_sds-v1/src/components/input/InputText";
import Table from "@sellernote/_sds-v1/src/components/table/Table";

import Styled from "./index.styles";

interface MixQtyTableItem {
  skuId: ReactNode;
  actualQty: number;
  mixQty: ReactNode;
}

interface MixQtyList {
  skuId: number;
  skuBarcode: string | undefined;
  actualQty: number;
  mixQty: number;
  validationMessage: React.ReactNode;
}

const initMixQtyList = (SKUItems: ReceivingItem[]): MixQtyList[] =>
  SKUItems.map((item) => ({
    skuId: item.sku.id,
    skuBarcode: item.sku.barCode,
    actualQty: item.inspectItems.reduce(
      (total, inspectItem) => total + (inspectItem.actualQty ?? 0),
      0
    ),
    mixQty: 0,
    validationMessage: undefined,
  }));

export default function useInputMixQty({
  receivingId,
}: {
  receivingId: number;
}) {
  const history = useHistory();

  const skuItems = useRecoilValue(FULFILLMENT_RECEIVING_SELECTORS.SKU_ITEMS);

  const [isVisibleCheckHasMixQtyModal, setIsVisibleCheckHasMixQtyModal] =
    useState(false);
  const [isVisibleInputMixQtyModal, setIsVisibleInputMixQtyModal] =
    useState(false);
  const [mixQtyList, setMixQtyList] = useState<MixQtyList[]>(() =>
    initMixQtyList(skuItems)
  );

  const {
    mutate: setInspectionDone,
    ResponseHandler: ResponseHandlerOfSetInspectionDone,
  } = RECEIVING_QUERY.useSetInspectionDone({
    receivingId,
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();
        history.replace("/receiving/inspection");
      },
      customizeMessage: () => ({
        messageType: "titleOnly",
        title: "검수 마감 처리되었습니다.",
      }),
    },
  });

  const handleCheckHasMixQtyModalOpen = useCallback(() => {
    setIsVisibleCheckHasMixQtyModal(true);
  }, []);

  const handleCheckHasMixQtyModalClose = useCallback(() => {
    setIsVisibleCheckHasMixQtyModal(false);
  }, []);

  const handleInputMixQtyModalOpen = useCallback(() => {
    handleCheckHasMixQtyModalClose();
    setIsVisibleInputMixQtyModal(true);
  }, [handleCheckHasMixQtyModalClose]);

  const handleInputMixQtyModalClose = useCallback(() => {
    setIsVisibleInputMixQtyModal(false);
    setMixQtyList(initMixQtyList(skuItems));
  }, [skuItems]);

  const handleInspectionDoneSet = useCallback(() => {
    const inspectedItems = skuItems.reduce(
      (inspectedItems: SET_INSPECTION_DONE_REQ_ITEM[], item) => {
        item.inspectItems.forEach((inspectItem) => {
          const mixQty = mixQtyList.find(
            (mixQtyItem) => mixQtyItem.skuId === item.sku.id
          )?.mixQty;
          const hasNotMixQty = !mixQty;
          const isAddedItem = inspectedItems.some(
            (inspectedItem) => inspectedItem.skuId === item.sku.id
          );

          if (hasNotMixQty || isAddedItem) {
            inspectedItems.push({
              skuId: item.sku.id,
              // 검수마감은 모든 검수가 완료된 상태이기 때문에 actualQty가 항상 존재한다.
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              actualQty: inspectItem.actualQty!,
              inspectingId: inspectItem.inspectingId,
            });
          } else {
            inspectedItems.push({
              skuId: item.sku.id,
              // 검수마감은 모든 검수가 완료된 상태이기 때문에 actualQty가 항상 존재한다.
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              actualQty: inspectItem.actualQty!,
              inspectingId: inspectItem.inspectingId,
              mixQty,
            });
          }
        });

        return inspectedItems;
      },
      []
    );

    setInspectionDone({ inspectedItems });
  }, [skuItems, mixQtyList, setInspectionDone]);

  const setMixQtyValue = useCallback(
    (inputSkuId: number) => (inputValue: number) => {
      setMixQtyList((prevMixQtyList) =>
        prevMixQtyList.map((mixQtyItem) =>
          mixQtyItem.skuId === inputSkuId
            ? {
                ...mixQtyItem,
                mixQty: isNaN(inputValue) ? 0 : inputValue,
                validationMessage: validateCount({
                  count: inputValue,
                  maxCount: mixQtyItem.actualQty,
                  message: { maxCount: "검수 수량 이내로만 입력 가능" },
                }).message,
              }
            : mixQtyItem
        )
      );
    },
    []
  );

  const dataList: TableDataListItem<MixQtyTableItem>[] = useMemo(
    () =>
      mixQtyList.map((item) => {
        const { skuId, skuBarcode, actualQty, mixQty, validationMessage } =
          item;

        return {
          rowKey: skuId,

          skuId: (
            <Styled.skuId>
              {getFormattedSingleSkuId(skuId)}
              <br />
              {!!skuBarcode && `(${skuBarcode})`}
            </Styled.skuId>
          ),

          actualQty,

          mixQty: (
            <InputText
              borderType="outline"
              valueType="int"
              width={7.5}
              value={mixQty}
              setValue={setMixQtyValue(skuId)}
              placeholder="0"
              errorMessage={validationMessage}
            />
          ),
        };
      }),
    [mixQtyList, setMixQtyValue]
  );

  const isValidatedModal = useMemo(() => {
    const hasMixQty = mixQtyList.some((mixQtyItem) => mixQtyItem.mixQty > 0);
    const hasValidationMessage = mixQtyList.some(
      (mixQtyItem) => mixQtyItem.validationMessage
    );

    return hasMixQty && !hasValidationMessage;
  }, [mixQtyList]);

  const CheckHasMixQtyModal = useMemo(
    () => (
      <Modal
        active={isVisibleCheckHasMixQtyModal}
        title="혼적 상품이 있습니까?"
        uiType="titleOnly"
        actionPositive={{
          label: "예",
          handleClick: handleInputMixQtyModalOpen,
        }}
        actionNegative={{
          label: "아니오",
          handleClick: handleInspectionDoneSet,
        }}
      />
    ),
    [
      handleInputMixQtyModalOpen,
      handleInspectionDoneSet,
      isVisibleCheckHasMixQtyModal,
    ]
  );

  const InputMixQtyModal = useMemo(
    () => (
      <Modal
        active={isVisibleInputMixQtyModal}
        uiType="contentWithCustomBody"
        title="혼적 수량을 입력하세요."
        body={
          <Styled.inputMixQtyModalBody>
            <Table<MixQtyTableItem>
              columnInfo={{
                skuId: {
                  label: (
                    <>
                      SKU ID
                      <br />
                      (상품 바코드)
                    </>
                  ),
                  fixedWidth: 120,
                },
                actualQty: {
                  label: "검수수량",
                  fixedWidth: 100,
                },
                mixQty: {
                  label: "혼적수량",
                  fixedWidth: 160,
                },
              }}
              dataList={dataList}
            />

            <Button
              theme="primary"
              size="normal"
              label="완료"
              handleClick={handleInspectionDoneSet}
              disabled={!isValidatedModal}
            />
          </Styled.inputMixQtyModalBody>
        }
        onClose={handleInputMixQtyModalClose}
      />
    ),
    [
      dataList,
      handleInputMixQtyModalClose,
      handleInspectionDoneSet,
      isValidatedModal,
      isVisibleInputMixQtyModal,
    ]
  );

  return {
    handleCheckHasMixQtyModalOpen,

    ResultHandlerOfInputMixQty: (
      <>
        {CheckHasMixQtyModal}
        {InputMixQtyModal}

        {ResponseHandlerOfSetInspectionDone}
      </>
    ),
  };
}
