import { createSelector } from "reselect";

import {
  FileDomainType,
  FulfillmentAttachment,
} from "@sellernote/_shared/src/types/fulfillment/fulfillment";

import { authSelectors } from "modules/auth";
import { RootState } from "store";

const getSKUItems = createSelector(
  (state: RootState) => state.returning?.GET_DETAIL?.data?.returning.items,
  (SKUItems) => {
    return SKUItems || [];
  }
);

/** 반품수량이 0개인 경우 검수 작업을 할 필요가 없으므로 검수 화면에서 제외시킴 */
const getSKUItemsFilteredByQuantity = createSelector(
  getSKUItems,
  (SKUItems) => {
    return SKUItems?.filter((item) => item.quantity > 0) || [];
  }
);

/** 검수수량이 0인 경우 입고 작업을 할 필요가 없으므로 입고 화면에서 제외시킴 */
const getSKUItemsFilteredByActualQty = createSelector(
  getSKUItems,
  (SKUItems) => {
    return SKUItems?.filter((item) => (item.actualQty ?? 0) > 0) || [];
  }
);

const getCurrentUserIsReturningManager = createSelector(
  (state: RootState) => authSelectors.getCurrentUser(),
  (state: RootState) => state.returning?.GET_DETAIL?.data,
  (currentUser, returnDetail) => {
    if (!currentUser || !returnDetail?.returning) {
      return false;
    }

    return currentUser.id === returnDetail.returning.managerId;
  }
);

const getCurrentUserIsPersonInCharge = createSelector(
  (state: RootState) => authSelectors.getCurrentUser(),
  (state: RootState) => state.returning?.GET_DETAIL?.data?.returning.workerList,
  (currentUser, workerList) => {
    if (!currentUser || !workerList) {
      return false;
    }

    return !!workerList.find((v) => +v.id === currentUser.id);
  }
);

const getAllSKUAreInspectionCompleted = createSelector(
  getSKUItemsFilteredByQuantity,
  (SKUItemsFilteredByQuantity) => {
    if (!SKUItemsFilteredByQuantity?.length) return false;

    return SKUItemsFilteredByQuantity.every((v) => v.isCompleteInspection);
  }
);

/**
 * 모든 SKU가 검수요청수량 전체에 대해서 검수완료되었는지 확인
 * checkIsCompletedWarehousingWithMatchedCount
 */
const checkIsCompletedInspectionWithMatchedCount = createSelector(
  getSKUItemsFilteredByQuantity,
  (SKUItemsFilteredByQuantity) => {
    if (!SKUItemsFilteredByQuantity?.length) return false;

    return SKUItemsFilteredByQuantity.every((v) => {
      const isMatched = v.quantity === v.actualQty;
      const isCompleted = v.isCompleteInspection;

      return isMatched && isCompleted;
    });
  }
);

const getCanCloseInspection = createSelector(
  getCurrentUserIsReturningManager,
  getAllSKUAreInspectionCompleted,
  (currentUserIsReceivingManager, allSKUAreInspectionCompleted) => {
    return currentUserIsReceivingManager && allSKUAreInspectionCompleted;
  }
);

const checkIsCompletedWarehousingWithMatchedCount = createSelector(
  getSKUItemsFilteredByActualQty,
  (SKUItemsFilteredByActualQty) => {
    if (!SKUItemsFilteredByActualQty?.length) {
      return false;
    }

    return SKUItemsFilteredByActualQty.every((item) => {
      if (!item.placeItems) {
        return false;
      }

      /**
       * 초과수량 입고가 가능하기 때문에 전체수량과 입고된 수량의 총합을 비교하는 것이 아니라,
       * 개별 아이템의 수량 일치 여부를 확인하여 수량 불일치인 경우 문제보고가 될 수 있도록 함
       */
      return item.placeItems.every((placeItem) => {
        const isMatched = placeItem.quantity === placeItem.placeQty;
        const isCompleted = placeItem.isCompletePlacing;

        return isMatched && isCompleted;
      });
    });
  }
);

const checkCanCloseWarehousing = createSelector(
  getSKUItemsFilteredByActualQty,
  (SKUItemsFilteredByActualQty) => {
    if (!SKUItemsFilteredByActualQty?.length) return false;

    return SKUItemsFilteredByActualQty.every((item) => {
      if (!item.placeItems) return true;

      return item.placeItems.every((pi) => pi.isCompletePlacing);
    });
  }
);

const getReturningPictureDict = createSelector(
  (state: RootState) => state.returning.GET_DETAIL?.data?.attachment,
  (attachment) => {
    const dict: {
      [P in FileDomainType]?: FulfillmentAttachment[];
    } = {};

    if (!attachment?.length) return dict;

    attachment.forEach((v) => {
      if (dict[v.domain]) {
        dict[v.domain]?.push(v);
      } else {
        dict[v.domain] = [v];
      }
    });

    return dict;
  }
);

const getAllRequiredPicturesAreUploaded = createSelector(
  (state: RootState) => state.returning.GET_DETAIL?.data?.returning.delivery,
  (state: RootState) =>
    state.returning.GET_DETAIL?.data?.returning.isInvoiceMatched,
  getReturningPictureDict,
  (delivery, isInvoiceMatched, pictureDict) => {
    if (delivery === "truckRequest") {
      return (
        !!pictureDict["cargo"]?.length &&
        !!pictureDict["shippingReceipt"]?.length &&
        !!pictureDict["packing"]?.length
      );
    }

    if (delivery === "parcel") {
      const hasInvoiceChecked =
        !!isInvoiceMatched || !!pictureDict["invoice"]?.length;

      return hasInvoiceChecked && !!pictureDict["packing"]?.length;
    }

    return false;
  }
);

const getCanCompleteConfirmation = createSelector(
  getAllRequiredPicturesAreUploaded,
  (state: RootState) => !!getReturningPictureDict(state)["damages"]?.length,
  (state: RootState) =>
    authSelectors.getCurrentUser()?.authority === "whMaster" ||
    authSelectors.getCurrentUser()?.authority === "admin",
  (allRequiredPicturesAreUploaded, hasDamage, currentUserIsWHMasterLike) => {
    if (currentUserIsWHMasterLike) {
      // 데미지 유무와 상관없이 창고관리자는 완료처리가능함
      return allRequiredPicturesAreUploaded;
    }

    if (hasDamage) {
      return false;
    } else {
      return allRequiredPicturesAreUploaded;
    }
  }
);

export default {
  getSKUItems,
  getSKUItemsFilteredByQuantity,
  getSKUItemsFilteredByActualQty,
  getCurrentUserIsReturningManager,
  getCurrentUserIsPersonInCharge,
  getAllSKUAreInspectionCompleted,
  checkIsCompletedInspectionWithMatchedCount,
  getCanCloseInspection,
  checkIsCompletedWarehousingWithMatchedCount,
  checkCanCloseWarehousing,
  getReturningPictureDict,
  getAllRequiredPicturesAreUploaded,
  getCanCompleteConfirmation,
};
