import RETURNING_QUERY from "@sellernote/_shared/src/queries/fulfillment/RETURNING_QUERY";
import { ProblemInfoWithAttachments } from "@sellernote/_shared/src/types/fulfillment/inspection";
import { ReceivingItem } from "@sellernote/_shared/src/types/fulfillment/receiving";

// 비정상 상품의 attachment 정보 -> items 의 해당 attachment에서 domain 과 targetId(problemId)로 필터링해서 사용
const getProblemInfoWithAttachments = ({
  returningItems,
  skuId,
}: {
  returningItems?: ReceivingItem[];
  skuId: number;
}) => {
  if (!returningItems) return;

  const returningItem = returningItems.find((item) => item.skuId === skuId);

  const problemList = returningItem?.inspectProblems.map((item) => ({
    ...item,
    attachments: returningItem.attachment?.filter(
      ({ domain, targetId }) =>
        domain === "abnormalSku" && targetId === item.problemId
    ),
  }));

  const problemMemo = returningItem?.problemMemo;

  return {
    list: problemList ?? [],
    memo: problemMemo,
  };
};

export default function useFetchListForProblem({
  returningId,
  skuId,
  onSuccessOfGetReturningProblemInfo,
}: {
  returningId: number;
  skuId: number;
  onSuccessOfGetReturningProblemInfo: (
    data?: ProblemInfoWithAttachments
  ) => void;
}) {
  const {
    data: returningDetail,
    ResponseHandler: ResponseHandlerOfReturningDetail,
  } = RETURNING_QUERY.useGetPDAReturningDetail({
    id: returningId,
    onSuccess: (res) => {
      onSuccessOfGetReturningProblemInfo(
        getProblemInfoWithAttachments({
          returningItems: res.returning.items,
          skuId,
        })
      );
    },

    titleOfFailureModal: "비정상 상품 조회 중 오류가 발생했습니다.",
  });

  const problemInfo = getProblemInfoWithAttachments({
    returningItems: returningDetail?.returning.items,
    skuId,
  });

  return {
    problemInfo,
    ResponseHandlerOfProblemInfo: ResponseHandlerOfReturningDetail,
  };
}
