import { useState } from "react";
import { useQueryClient } from "react-query";
import INSPECTION_QUERY from "queries/returning/INSPECTION_QUERY";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import { RETURNING_QUERY_KEY_GEN } from "@sellernote/_shared/src/queries/fulfillment/RETURNING_QUERY";
import InputTextArea from "@sellernote/_sds-v1/src/components/input/InputTextArea";

import Styled from "./index.styles";

export default function ProblemMemo({
  returningId,
  skuId,
  problemMemo,
}: {
  returningId: number;
  skuId: number;
  problemMemo?: string;
}) {
  const [memo, setMemo] = useState(problemMemo ?? "");

  const queryClient = useQueryClient();

  const {
    mutate: updateProblemMemo,
    ResponseHandler: ResponseHandlerOfUpdateProblemMemo,
  } = INSPECTION_QUERY.useUpdateProblemMemo({
    returningId,
    onSuccess: () => {
      queryClient.invalidateQueries(
        RETURNING_QUERY_KEY_GEN.getPDAReturningDetail({ id: returningId })
      );
    },
  });

  const handleMemoSave = () => {
    updateProblemMemo({ problemMemo: { skuId, memo } });
  };

  const validate = memo && problemMemo !== memo && memo.length <= 200;

  return (
    <Styled.problemMemo>
      <InputTextArea
        label="비고"
        value={memo}
        setValue={(value) => setMemo(value)}
        placeholder="입력해주세요."
        height={100}
        errorMessage={
          memo.length > 200 ? "200자 이하로 입력해주세요." : undefined
        }
      />

      <Button
        label="비고 저장"
        size="small"
        theme="primary"
        handleClick={handleMemoSave}
        disabled={!validate}
      />

      {ResponseHandlerOfUpdateProblemMemo}
    </Styled.problemMemo>
  );
}
