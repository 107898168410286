import { useEffect, useMemo } from "react";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { useRecoilState } from "recoil";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import { TableRowInfoToHighlight } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import { FULFILLMENT_COMMON_ATOMS } from "@sellernote/_shared/src/states/fulfillment/common";
import { ReceivingItem } from "@sellernote/_shared/src/types/fulfillment/receiving";
import { omitWithEllipsis } from "@sellernote/_shared/src/utils/common/string";
import { getTeamLabelForBofulWorker } from "@sellernote/_shared/src/utils/fulfillment/common";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import { getFormattedReturningId } from "@sellernote/_shared/src/utils/fulfillment/returning";

import Layout from "containers/Layout";
import { LayoutRefreshInfo } from "containers/Layout/Refresh";
import withRequireAuth from "hocs/withRequireAuth";
import useConfirmModal from "hooks/common/useConfirmModal";
import useFetchDetailForInspection from "hooks/returning/useFetchDetailForInspection";
import useScanInspectionSKU from "hooks/returning/useScanInspectionSKU";
import useSKUCountingForInspection from "hooks/returning/useSKUCountingForInspection";
import { authSelectors } from "modules/auth";
import { returningActions, returningSelectors } from "modules/returning";
import { useAppSelector } from "store";

import AddPersonInCharge from "./AddPersonInCharge";
import CloseInspection from "./CloseInspection";
import CompleteInspection from "./CompleteInspection";
import PersonInChargeList from "./PersonInChargeList";
import SKUList from "./SKUList";
import UnverifiedItemInput from "./UnverifiedItemInput";
import useReturningGuide from "./useReturningGuide";
import useUnverifiedList from "./useUnverifiedList";
import Styled from "./index.styles";

function ReturningInspectionDetail() {
  const { ConfirmModal, setConfirmModal } = useConfirmModal();
  const { ReturningGuideModal, setReturningGuideModal } = useReturningGuide();

  const [canNotLeavePage, setCanNotLeavePage] = useRecoilState(
    FULFILLMENT_COMMON_ATOMS.CAN_NOT_LEAVE_PAGE
  );

  const dispatch = useDispatch();

  const match = useRouteMatch<{ id: string }>();
  const {
    params: { id },
  } = match;

  const {
    currentUser,
    currentUserIsPersonInChargeReturning,
    canCloseInspectionReturning,
  } = useAppSelector((state) => {
    return {
      currentUser: authSelectors.getCurrentUser(),
      currentUserIsPersonInChargeReturning:
        returningSelectors.getCurrentUserIsPersonInCharge(state),
      canCloseInspectionReturning:
        returningSelectors.getCanCloseInspection(state),
    };
  });

  const [rowInfoToHighlight, setRowInfoToHighlight] = useState<
    TableRowInfoToHighlight | undefined
  >(undefined);

  const {
    returningDetailData,
    SKUItemsFilteredByQuantityReturning,
    ResponseHandlerOfDetail,
  } = useFetchDetailForInspection({ returningId: Number(id) });

  const {
    registeredUnverifiedList,
    registeredUnverifiedCount,
    scannedUnregisteredUnverifiedCount,
  } = useUnverifiedList({ items: returningDetailData?.items });

  const skuCounting = useSKUCountingForInspection({
    items: SKUItemsFilteredByQuantityReturning,
    setRowInfoToHighlight,
    unverifiedCount:
      registeredUnverifiedCount + scannedUnregisteredUnverifiedCount,
  });

  // scan 결과를 다루는 hook
  const { ResultHandlerOfScanSKU } = useScanInspectionSKU({
    skuCounting,
    returningId: Number(id),
    registeredUnverifiedList,
    setRowInfoToHighlight,
    setSkuInProgress: skuCounting.setSkuInProgress,
    startInspectionAt: returningDetailData?.startInspectionAt,
  });

  // 검수가 시작된 후에는 파트타이머가 빠져나갈 수 없도록(뒤로가기 할 수 없도록) 함
  useEffect(() => {
    const currentUserIsPartTimer = currentUser?.authority === "partTimer";

    const positionCannotGoBack =
      currentUserIsPartTimer && currentUserIsPersonInChargeReturning;

    if (!canNotLeavePage && positionCannotGoBack) {
      setCanNotLeavePage(true);
    }
  }, [
    currentUser,
    canNotLeavePage,
    currentUserIsPersonInChargeReturning,
    setCanNotLeavePage,
  ]);

  /**
   * 담당중인 SKU 중 미결된 것이 있으면 이탈 할 수 없게 함
   */
  const checkUnfinishedInspectionOfMine = useCallback(
    (items: ReceivingItem[] | undefined) => {
      if (!items) return;

      return items.some((item) => {
        return (
          item.inspectorId === currentUser?.id && !item.isCompleteInspection
        );
      });
    },
    [currentUser?.id]
  );

  /**
   * 담당중인 SKU 중 미결된 것이 있으면 이탈 할 수 없게 함
   */
  useEffect(() => {
    const hasUnfinishedInspectionOfMine = checkUnfinishedInspectionOfMine(
      returningDetailData?.items
    );

    if (hasUnfinishedInspectionOfMine) {
      setCanNotLeavePage(true);
    } else {
      setCanNotLeavePage(false);
    }
  }, [
    checkUnfinishedInspectionOfMine,
    returningDetailData?.items,
    setCanNotLeavePage,
  ]);

  const refreshInfo: LayoutRefreshInfo = useMemo(() => {
    let confirmMessage = "";

    if (skuCounting.skuInProgress) {
      confirmMessage = `현재 카운트 중인 SKU ID(${getFormattedSingleSkuId(
        skuCounting.skuInProgress.skuId
      )})가 초기화 됩니다`;
    }

    return {
      ...(confirmMessage ? { confirm: { message: confirmMessage } } : {}),
      handleRefresh: (showSuccessMessage) => {
        dispatch(
          returningActions.GET_DETAIL({
            id: Number(id),

            _postSuccessCallback: (res) => {
              setRowInfoToHighlight(undefined);
              showSuccessMessage();
              skuCounting.reset(
                res.returning.items.filter((item) => item.quantity > 0)
              );
            },
          })
        );
      },
    };
  }, [skuCounting, dispatch, id]);

  const resetCountByCounterKey = useCallback(
    (counterKey: string) => {
      setConfirmModal(undefined);

      skuCounting.counter.resetCountById(counterKey);

      // 초기화하면 초기화한 SKU작업이 진행중인 작업이 된다.
      const target = skuCounting.counter.counterInfo[counterKey];
      skuCounting.setSkuInProgress(target);
      setRowInfoToHighlight({ rowKey: target.itemId });
    },
    [setConfirmModal, skuCounting]
  );

  const resetAfterRemoveInProgressPersonInCharge = useCallback(
    (counterKey: string) => () => {
      setConfirmModal(undefined);

      skuCounting.counter.resetCountById(counterKey);
      setRowInfoToHighlight(undefined);
      skuCounting.setSkuInProgress(undefined);
    },
    [setConfirmModal, skuCounting]
  );

  const resetAfterComplete = useCallback(() => {
    setRowInfoToHighlight(undefined);
    skuCounting.setSkuInProgress(undefined);
  }, [skuCounting]);

  const counterInProgress = skuCounting.skuInProgress
    ? skuCounting.counter.counterInfo[skuCounting.skuInProgress.counterKey]
    : undefined;

  return (
    <Layout
      navigator={{
        title: `${getFormattedReturningId(
          returningDetailData
        )} / ${getTeamLabelForBofulWorker({
          id: returningDetailData?.team?.id,
          name: omitWithEllipsis({
            src: returningDetailData?.team?.name,
            maxLength: 10,
            ellipsis: "...",
          }),
          company: omitWithEllipsis({
            src: returningDetailData?.team?.company,
            maxLength: 10,
            ellipsis: "...",
          }),
        })}`,
      }}
      refreshInfo={refreshInfo}
    >
      <Styled.container>
        {ConfirmModal}
        {ReturningGuideModal}
        <div className="header">
          <AddPersonInCharge
            isManager={currentUserIsPersonInChargeReturning}
            workerList={returningDetailData?.workerList}
          />

          <Button
            label="상품스캔"
            size="small"
            theme="tertiary"
            handleClick={() => {}}
          />
        </div>
        <PersonInChargeList
          returningManagerId={returningDetailData?.managerId}
          workerList={returningDetailData?.workerList}
        />

        <UnverifiedItemInput
          registeredUnverifiedList={registeredUnverifiedList}
          returningId={Number(id)}
          counterInProgress={counterInProgress}
        />

        <SKUList
          returningId={Number(id)}
          returningItems={SKUItemsFilteredByQuantityReturning}
          rowInfoToHighlight={rowInfoToHighlight}
          setRowInfoToHighlight={setRowInfoToHighlight}
          skuCounting={skuCounting}
          counterData={skuCounting.counter.counterInfo}
          addCountByInput={skuCounting.counter.addCountByInput}
          resetCountByCounterKey={resetCountByCounterKey}
          resetAfterRemoveInProgressPersonInCharge={
            resetAfterRemoveInProgressPersonInCharge
          }
          setConfirmModal={setConfirmModal}
          returningRequests={returningDetailData?.requests}
          setReturningGuideModal={setReturningGuideModal}
        />
        <div className="total-count">
          <span className="label">총 카운트:</span>
          <span className="value">{skuCounting.totalCount}</span>
        </div>
        <div className="footer">
          {canCloseInspectionReturning ? (
            <CloseInspection returningId={Number(id)} />
          ) : (
            <CompleteInspection
              returningId={Number(id)}
              counterInProgress={counterInProgress}
              resetAfterComplete={resetAfterComplete}
            />
          )}
        </div>
      </Styled.container>

      {ResponseHandlerOfDetail}
      {ResultHandlerOfScanSKU}
    </Layout>
  );
}

export default withRequireAuth(ReturningInspectionDetail);
