import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import { TableRowInfoToHighlight } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import {
  checkIsUnverifiedSku,
  getWarehousingItemStatusLabel,
} from "@sellernote/_shared/src/utils/fulfillment/common";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import { checkConfirmedAsSingleLocationWarehousing } from "@sellernote/_shared/src/utils/fulfillment/receiving";
import { checkHasOnlyInitialWarehousing } from "@sellernote/_shared/src/utils/fulfillment/receiving";

import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";
import useConfirmModal from "hooks/common/useConfirmModal";
import useFetchDetailForWarehousing from "hooks/returning/useFetchDetailForWarehousing";
import useScanLocation from "hooks/returning/useScanLocation";
import useScanWarehousingSKU from "hooks/returning/useScanWarehousingSKU";
import useWarehousingCounting from "hooks/returning/useSKUCountingForWarehousing";

import ItemStatus from "components/ItemStatus";

import { ScanModeForReturningWarehousing } from "../..";
import CompleteWarehousing from "../../CompleteWarehousing";
import AddPartialWarehousing from "./AddPartialWarehousing";
import PartedList from "./PartedList";
import Styled from "./index.styles";

function Guide({ skuId, actualQty }: { skuId: number; actualQty?: number }) {
  return (
    <Styled.guide>
      <div className="title">‣ 분할입고할 내용을 추가해주세요.</div>

      <div className="desc">
        [입고 정보]
        <br />
        ・SKU ID : {getFormattedSingleSkuId(skuId)}
        <br />
        ・총 입고수량 : {actualQty}
      </div>
    </Styled.guide>
  );
}

function TotalCount({ max, counted }: { max: number; counted: number }) {
  return (
    <Styled.totalCount>
      <div className="label">총 카운트 :</div>
      <div className="value">
        {counted} / {max}
      </div>
    </Styled.totalCount>
  );
}

function MultiLocation() {
  const { ConfirmModal, setConfirmModal } = useConfirmModal();

  const match = useRouteMatch<{ id: string; skuId: string }>();
  const {
    params: { id, skuId },
  } = match;

  const itemId = useLocation<{ itemId: number }>().state?.itemId;

  const [scanMode, setScanMode] =
    useState<ScanModeForReturningWarehousing>("skuId");
  const [rowInfoToHighlight, setRowInfoToHighlight] = useState<
    TableRowInfoToHighlight | undefined
  >(undefined);
  const [placingIdInProgress, setPlacingIdInProgress] = useState("");

  const {
    returningDetailData,
    SKUItemsFilteredByActualQtyReturning,
    ResponseHandlerOfDetail,
  } = useFetchDetailForWarehousing({
    returningId: Number(id),
  });

  const skuCounting = useWarehousingCounting(
    SKUItemsFilteredByActualQtyReturning
  );

  const {
    setScanSKUActive,
    showSelectionErrorForScanSKU,
    ResultHandlerOfScanSKU,
  } = useScanWarehousingSKU({
    scanType: "multi",
    skuId: Number(skuId),
    placingId: placingIdInProgress,
    skuCounting,
    returningId: Number(id),
    setRowInfoToHighlight,
  });

  const {
    scannedLocation,
    setScannedLocation,
    setScanLocationActive,
    showSelectionErrorForScanLocation,
    ResultHandlerOfScanLocation,
  } = useScanLocation({
    scanType: "multi",
    skuIdInprogress: skuCounting.skuInProgress?.skuId,
    counterData: skuCounting.counter.counterInfo,
  });

  useEffect(() => {
    setScanSKUActive(scanMode === "skuId");
    setScanLocationActive(scanMode === "location");
  }, [scanMode, setScanLocationActive, setScanSKUActive]);

  const returningItem = useMemo(() => {
    if (!SKUItemsFilteredByActualQtyReturning) return;

    return SKUItemsFilteredByActualQtyReturning.find(
      (v) => v.id === Number(itemId)
    );
  }, [SKUItemsFilteredByActualQtyReturning, itemId]);

  const confirmedAsSingleLocationWarehousing = useMemo(() => {
    return checkConfirmedAsSingleLocationWarehousing(returningItem);
  }, [returningItem]);

  const totalPlacedQty = useMemo(() => {
    if (!returningItem?.placeItems) return 0;

    return returningItem.placeItems.reduce((a, c) => {
      return a + (c.placeQty ?? 0);
    }, 0);
  }, [returningItem]);

  const hasOnlyInitialWarehousing = useMemo(() => {
    return checkHasOnlyInitialWarehousing(returningItem);
  }, [returningItem]);

  const returningItemCompleted = useMemo(() => {
    const allWarehousingItemsAreSplit =
      returningItem?.actualQty ===
      returningItem?.placeItems.reduce((a, c) => {
        return a + (c.quantity ?? 0);
      }, 0);

    const allWarehousingItemsAreCompleted = returningItem?.placeItems.every(
      (ii) => ii.isCompletePlacing
    );

    return allWarehousingItemsAreSplit && allWarehousingItemsAreCompleted;
  }, [returningItem?.actualQty, returningItem?.placeItems]);

  const itemStatusLabel = useMemo(() => {
    return getWarehousingItemStatusLabel(returningItem?.processStatus);
  }, [returningItem?.processStatus]);

  const isUnverifiedSku = checkIsUnverifiedSku(returningItem?.inspectProblems);

  const confirmMessageToLeave = useMemo(() => {
    //  분할입고를 시작했는데, 모든 분할입고를 완료하지 않았을 경우 confirm 메시지를 띄움.
    if (
      !hasOnlyInitialWarehousing &&
      !confirmedAsSingleLocationWarehousing &&
      !returningItemCompleted
    ) {
      return {
        title: (
          <>
            {getFormattedSingleSkuId(Number(skuId))}(SKU ID)
            <br />
            상태:{" "}
            <ItemStatus
              statusLabel={itemStatusLabel}
              isUnverifiedSku={checkIsUnverifiedSku(
                returningItem?.inspectProblems
              )}
            />
          </>
        ),
        body: (
          <>
            입고가 완료되지 않았습니다.
            <br />
            분할입고를 그만하겠습니까?
          </>
        ),
      };
    }
  }, [
    hasOnlyInitialWarehousing,
    confirmedAsSingleLocationWarehousing,
    returningItemCompleted,
    skuId,
    itemStatusLabel,
    returningItem?.inspectProblems,
  ]);

  const handleScanSKUClick = useCallback(() => {
    if (!placingIdInProgress) {
      showSelectionErrorForScanSKU();
      return;
    }

    setScanMode("skuId");
  }, [placingIdInProgress, showSelectionErrorForScanSKU]);

  const handleScanLocationClick = useCallback(() => {
    if (!skuCounting.skuInProgress) {
      showSelectionErrorForScanLocation();
    }

    setScanMode("location");
  }, [showSelectionErrorForScanLocation, skuCounting.skuInProgress]);

  const resetCountByCounterKey = useCallback(
    (counterKey: string) => () => {
      setConfirmModal(undefined);

      skuCounting.counter.resetCountById(counterKey);
      skuCounting.setSkuInProgress(undefined);
      setRowInfoToHighlight(undefined);
    },
    [setConfirmModal, skuCounting]
  );

  const resetAfterComplete = useCallback(() => {
    setScanMode("skuId");
    setScannedLocation(undefined);
    setRowInfoToHighlight(undefined);
    skuCounting.setSkuInProgress(undefined);
    setPlacingIdInProgress("");
  }, [setScannedLocation, skuCounting]);

  if (!returningItem) return null;

  return (
    <Layout
      navigator={{
        title: (
          <>
            분할입고(ID: {getFormattedSingleSkuId(Number(skuId))})
            <br />
            상태:{" "}
            <ItemStatus
              statusLabel={itemStatusLabel}
              isUnverifiedSku={isUnverifiedSku}
            />
            {returningItem.sku.barCode ? (
              <>
                <br />({returningItem.sku.barCode})
              </>
            ) : (
              ""
            )}
          </>
        ),
      }}
      confirmMessageToLeave={confirmMessageToLeave}
    >
      <Styled.container>
        {ConfirmModal}

        <Styled.headerForScan>
          <div className="left">{scannedLocation?.barCode}</div>

          <div className="right">
            <Button
              label="상품스캔"
              size="small"
              theme={scanMode === "skuId" ? "tertiary" : "secondary"}
              handleClick={handleScanSKUClick}
            />

            <Button
              label="위치스캔"
              size="small"
              theme={scanMode === "location" ? "tertiary" : "secondary"}
              handleClick={handleScanLocationClick}
            />
          </div>
        </Styled.headerForScan>

        {hasOnlyInitialWarehousing ? (
          <Guide skuId={Number(skuId)} actualQty={returningItem.actualQty} />
        ) : (
          <PartedList
            returningId={Number(id)}
            itemId={returningItem.id}
            skuId={Number(skuId)}
            mainManagerId={returningDetailData?.returning.managerId}
            placeItems={returningItem.placeItems}
            selectedLocation={scannedLocation}
            rowInfoToHighlight={rowInfoToHighlight}
            setRowInfoToHighlight={setRowInfoToHighlight}
            placingIdInProgress={placingIdInProgress}
            setPlacingIdInProgress={setPlacingIdInProgress}
            skuCounting={skuCounting}
            counterData={skuCounting.counter.counterInfo}
            maxCount={returningItem.actualQty ?? 0}
            addCountByInput={skuCounting.counter.addCountByInput}
            resetCountByCounterKey={resetCountByCounterKey}
            setConfirmModal={setConfirmModal}
            statusLabel={itemStatusLabel}
            isUnverifiedSku={isUnverifiedSku}
          />
        )}

        <TotalCount
          counted={totalPlacedQty}
          max={returningItem.actualQty ?? 0}
        />

        <AddPartialWarehousing
          returningId={Number(id)}
          returningItem={returningItem}
        />

        <CompleteWarehousing
          locationType="multi"
          returningId={Number(id)}
          selectedLocation={scannedLocation}
          counterInProgress={
            skuCounting.skuInProgress
              ? skuCounting.counter.counterInfo[
                  skuCounting.skuInProgress.counterKey
                ]
              : undefined
          }
          resetAfterComplete={resetAfterComplete}
        />
      </Styled.container>

      {ResponseHandlerOfDetail}
      {ResultHandlerOfScanSKU}
      {ResultHandlerOfScanLocation}
    </Layout>
  );
}

export default withRequireAuth(MultiLocation);
