import { useState } from "react";
import { useHistory } from "react-router-dom";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import Icon from "@sellernote/_shared/src/componentsToMoveToV1/Icon";
import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { ReceivingItem } from "@sellernote/_shared/src/types/fulfillment/receiving";
import { UseCounterDataValue } from "@sellernote/_shared/src/utils/common/hook";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import { replaceEmptyToDash } from "@sellernote/_shared/src/utils/common/string";

import { InspectionCounterSKU } from "hooks/returning/useSKUCountingForInspection";
import useUnverifiedItem from "./hooks/useUnverifiedItem";

import Styled from "./index.styles";

export default function UnverifiedItemInput({
  returningId,
  registeredUnverifiedList,
  counterInProgress,
}: {
  returningId: number;
  registeredUnverifiedList?: ReceivingItem[];
  counterInProgress?: UseCounterDataValue<InspectionCounterSKU>;
}) {
  const history = useHistory();

  const [
    opensInspectionCompleteConfirmModal,
    setOpensInspectionCompleteConfirmModal,
  ] = useState(false);

  const {
    unregisteredTempUnverifiedList,
    resetUnregisteredTempUnverifiedList,
    handleUnverifiedListCreate,
    ResponseHandlerOfCreateUnverifiedList,
  } = useUnverifiedItem({
    returningId,
    onSuccessForCreateUnverifiedList: () => {
      history.push(`${history.location.pathname}/unverified-item`);

      resetUnregisteredTempUnverifiedList();
    },
  });

  // 개별 등록한 불일치 상품의 총 수량
  const registeredItemTotalQuantity =
    registeredUnverifiedList?.reduce(
      (acc, { actualQty }) => acc + (actualQty ?? 0),
      0
    ) ?? 0;

  // 스캔만 완료하고, 등록 이전인 불일치 상품의 수량
  const scannedUnregisteredItemTotalQuantity =
    unregisteredTempUnverifiedList.reduce(
      (acc, { quantity }) => acc + (quantity ?? 0),
      0
    );

  const totalQuantity =
    registeredItemTotalQuantity + scannedUnregisteredItemTotalQuantity;

  const hasUnverifiedItem = !!totalQuantity;

  // 스캔한 상품도 모두 등록되어있고, 첨부파일도 모두 등록되어있는지 여부.
  const isAllRegisteredItemWithAttachments =
    !unregisteredTempUnverifiedList.length &&
    registeredUnverifiedList?.every(({ attachment }) => !!attachment?.length);

  const isCounterInProgress = !!counterInProgress;

  // 스캔한 상품이 검수완료 되어야 불일치 입력/수정 가능.
  const handleUnverifiedUpdate = () => {
    if (isCounterInProgress)
      return setOpensInspectionCompleteConfirmModal(true);

    handleUnverifiedListCreate();
  };

  return (
    <Styled.unverifiedItemNotification hasUnverifiedItem={hasUnverifiedItem}>
      <div className="guide">
        <Icon type="warning" color={COLOR.pointWarning} size={1} />
        불일치 상품 발생{!hasUnverifiedItem && " 시 입력해주세요."}
      </div>

      {hasUnverifiedItem && (
        <div>
          수량: {replaceEmptyToDash(toThousandUnitFormat(totalQuantity), true)}
          PCS
        </div>
      )}

      <Button
        label={
          hasUnverifiedItem && isAllRegisteredItemWithAttachments
            ? "수정"
            : "입력"
        }
        size="small"
        theme={
          hasUnverifiedItem && isAllRegisteredItemWithAttachments
            ? "secondary"
            : "dangerStroke"
        }
        handleClick={handleUnverifiedUpdate}
      />

      {ResponseHandlerOfCreateUnverifiedList}

      <Modal
        uiType="titleOnly"
        active={opensInspectionCompleteConfirmModal}
        title={`현재 작업 중인 검수(SKU ID: ${
          counterInProgress?.skuId ?? "-"
        })를 완료한 후에 불일치 상품 입력, 수정이 가능합니다.`}
        actionPositive={{
          label: "확인",
          handleClick: () => setOpensInspectionCompleteConfirmModal(false),
        }}
      />
    </Styled.unverifiedItemNotification>
  );
}
