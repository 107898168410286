import { useMemo, useState } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import useMultiSelect from "@sellernote/_shared/src/hooks/common/useMultiSelect";
import { UseCounterDataValue } from "@sellernote/_shared/src/utils/common/hook";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import { replaceEmptyToDash } from "@sellernote/_shared/src/utils/common/string";
import { getFormattedSkuId } from "@sellernote/_shared/src/utils/fulfillment/common";
import TextButton from "@sellernote/_sds-v1/src/components/button/TextButton";

import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";
import useFetchListForProblem from "hooks/returning/useFetchListForProblem";
import { InspectionCounterSKU } from "hooks/returning/useSKUCountingForInspection";
import useProblemItem from "../hooks/useProblemItem";

import ProblemList from "./ProblemList";
import ProblemMemo from "./ProblemMemo";
import useProblemItemModal from "./useProblemItemModal";
import Styled from "./index.styles";

function ProblemItem() {
  const history = useHistory();

  const match = useRouteMatch<{ id: string; skuId: string; itemId: string }>();

  const {
    params: { id, skuId, itemId },
  } = match;

  const location = useLocation();

  const state = location.state as
    | {
        prevPath: string;
        inputtingCounterInfo: UseCounterDataValue<InspectionCounterSKU>;
      }
    | undefined;

  const searchParams = new URLSearchParams(location.search);

  const inspectionCount = searchParams.get("inspectionCount") ?? 0;

  const multiSelect = useMultiSelect();

  const [opensConfirmRemoveModal, setOpensConfirmRemoveModal] = useState(false);

  const { problemInfo, ResponseHandlerOfProblemInfo } = useFetchListForProblem({
    returningId: Number(id),
    skuId: Number(skuId),
    onSuccessOfGetReturningProblemInfo: (data) => {
      if (!data?.list) return;

      multiSelect.initSelectionDict(
        data?.list.map(({ problemId }) => problemId)
      );
    },
  });

  // 프론트에서 생성해서 최초 등록하는 ID, 등록 이후에는 data의 ID 로 지정됨.
  const newProblemId = useMemo(() => {
    if (!problemInfo?.list.length) return Number(`` + itemId + 1);

    const currentIdMaxValue = Math.max(
      ...problemInfo?.list.map(({ problemId }) => problemId)
    );

    const newOrder =
      Number(`${currentIdMaxValue}`.replace(`${itemId}`, "")) + 1;

    return Number(`` + itemId + newOrder);
  }, [itemId, problemInfo?.list]);

  const problemListTotalQuantity = problemInfo?.list?.reduce((acc, cur) => {
    return acc + cur.quantity;
  }, 0);

  const { ProblemItemModal, handleModalOpen, updateFormInfo } =
    useProblemItemModal({
      returningId: Number(id),
      skuId: Number(skuId),
      newProblemId,
      inspectionCount: Number(inspectionCount),
      problemTotalQuantity: problemListTotalQuantity ?? 0,
    });

  const { handleProblemListRemove, ResponseHandlerOfRemoveProblemList } =
    useProblemItem({
      returningId: Number(id),
      onSuccessForRemoveProblemList: () => setOpensConfirmRemoveModal(false),
    });

  return (
    <Layout
      navigator={{
        title: `${getFormattedSkuId({
          skuId: Number(skuId),
        })} / 비정상 상품(PCS) 입력`,
      }}
      isWithoutDefaultPadding
      onBackButtonClick={() => {
        history.replace(`${state?.prevPath}`, {
          inputtingCounterInfo: state?.inputtingCounterInfo,
        });
      }}
    >
      <Styled.container>
        <Styled.totalQuantity>
          <span className="label">비정상 수량(PCS)</span>

          <span className="value">
            {replaceEmptyToDash(
              toThousandUnitFormat(problemListTotalQuantity),
              true
            )}
          </span>
        </Styled.totalQuantity>

        <Styled.problemListContainer>
          <div className="header">
            <span className="title">문제 보고</span>

            <TextButton
              label="삭제"
              theme="danger"
              size="12px"
              handleClick={() => setOpensConfirmRemoveModal(true)}
              disabled={!multiSelect.selectedIdList.length}
            />
          </div>

          <ProblemList
            list={problemInfo?.list}
            itemId={itemId}
            skuId={skuId}
            multiSelect={multiSelect}
            openModalAndUpdate={(formInfo) => {
              updateFormInfo(formInfo);

              handleModalOpen();
            }}
          />

          <Button
            label="문제 카테고리 추가"
            size="small"
            theme="secondary"
            width="100%"
            handleClick={handleModalOpen}
            disabled={(problemInfo?.list?.length ?? 0) >= 5}
          />

          {(problemInfo?.list?.length ?? 0) >= 5 && (
            <span className="max-length-guide">
              최대 5개까지 입력 가능합니다.
            </span>
          )}
        </Styled.problemListContainer>

        {problemInfo && (
          <ProblemMemo
            returningId={Number(id)}
            skuId={Number(skuId)}
            problemMemo={problemInfo.memo}
          />
        )}

        <Modal
          uiType="titleOnly"
          active={opensConfirmRemoveModal}
          title={`해당 입력란을 삭제하시겠습니까?`}
          actionPositive={{
            label: "네",
            handleClick: () =>
              handleProblemListRemove({
                itemId: Number(itemId),
                problemIds: multiSelect.selectedIdList,
              }),
          }}
          actionNegative={{
            label: "아니오",
            handleClick: () => setOpensConfirmRemoveModal(false),
          }}
        />
      </Styled.container>

      {ProblemItemModal}

      {ResponseHandlerOfProblemInfo}
      {ResponseHandlerOfRemoveProblemList}
    </Layout>
  );
}

export default withRequireAuth(ProblemItem);
