import React, { memo, useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";

import Icon from "@sellernote/_shared/src/componentsToMoveToV1/Icon";
import {
  TableDataListItem,
  TableRowInfoToHighlight,
} from "@sellernote/_shared/src/headlessComponents/table/useTable";
import { FULFILLMENT_COMMON_ATOMS } from "@sellernote/_shared/src/states/fulfillment/common";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { Overwrite } from "@sellernote/_shared/src/types/common/customUtilityTypes";
import { BofulWorker } from "@sellernote/_shared/src/types/fulfillment/auth";
import { BofulLocation } from "@sellernote/_shared/src/types/fulfillment/common";
import {
  ReceivingItem,
  ReceivingPlaceItem,
} from "@sellernote/_shared/src/types/fulfillment/receiving";
import { ScannedLocation } from "@sellernote/_shared/src/types/fulfillment/scan";
import {
  checkIsUnverifiedSku,
  getWarehousingItemStatusLabel,
} from "@sellernote/_shared/src/utils/fulfillment/common";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import { checkConfirmedAsSingleLocationWarehousing } from "@sellernote/_shared/src/utils/fulfillment/receiving";
import { getHasMultiLocationWarehousing } from "@sellernote/_shared/src/utils/fulfillment/receiving";
import {
  checkIsGroupedItemBySkuId,
  getGroupedItemSkuIdList,
} from "@sellernote/_shared/src/utils/fulfillment/returning";
import { getStyledSKUCount } from "@sellernote/_shared/src/utils/fulfillment/sku";
import TableForCounting from "@sellernote/_sds-v1/src/components/table/TableForCounting";

import { ConfirmModal } from "hooks/common/useConfirmModal";
import useCountForScanning from "hooks/common/useCountForScanning";
import useGetWorkerByIdFactory from "hooks/common/useGetWorkerByIdFactory";
import useCompleteWarehousingByForce from "hooks/returning/useCompleteWarehousingByForce";
import useRemoveWarehousingPersonInCharge from "hooks/returning/useRemoveWarehousingPersonInCharge";
import useResetWarehousingOfItem from "hooks/returning/useResetWarehousingOfItem";
import {
  CounterDataForWarehousing,
  getCounterKeyByStatus,
  getCounterKeyForMultiLocationWarehousing,
  SKUCountingForWarehousing,
} from "hooks/returning/useSKUCountingForWarehousing";
import useSKUImageInfoModal from "hooks/sku/useSKUImageInfoModal";
import { authSelectors } from "modules/auth";
import { useAppSelector } from "store";

import CompleteByForce from "components/CompleteByForce";
import CountForScanning from "components/CountForScanning";
import ItemStatus from "components/ItemStatus";
import PersonInCharge from "components/PersonInCharge";
import ResetCounting from "components/ResetCounting";
import SelectMultiLocation from "components/SelectMultiLocation";
import SKUInfo from "components/SKUInfo";
import SubRowIndicator from "components/SubRowIndicator";
import UnverifiedSku from "components/UnverifiedSku";

import Styled from "./index.styles";
interface ReturningWarehousingDetailTableItem {
  selection: React.ReactNode;
  location: React.ReactNode;
  SKUId: React.ReactNode;
  count: React.ReactNode;
  personInCharge?: React.ReactNode;
  completeStatus: string;
  reset: React.ReactNode;
  completeByForce: React.ReactNode;
  selectMultiLocation: React.ReactNode;
}

function getCanReset({
  skuId,
  itemId,
  counterData,
  hasRightToReset,
  hasMultiLocation,
  isGroupedItem,
}: {
  skuId: number;
  itemId: number;
  counterData: CounterDataForWarehousing;
  hasRightToReset: boolean;
  hasMultiLocation: boolean;
  isGroupedItem: boolean;
}): boolean {
  if (hasMultiLocation) {
    return hasRightToReset;
  }

  const counterKeyAsSingleLocation = getCounterKeyByStatus({
    isGroupedItem,
    skuId,
    itemId,
  });
  const itemCounter = counterData[counterKeyAsSingleLocation];
  return (
    hasRightToReset &&
    (!!itemCounter?.current || !!itemCounter?.isCompletePlacing)
  );
}

function getHasRightToReset({
  hasMultiLocation,
  currentUserIsMainManager,
  currentUserId,
  placeItems,
}: {
  hasMultiLocation: boolean;
  currentUserIsMainManager: boolean;
  currentUserId: number | undefined;
  placeItems: ReceivingPlaceItem[];
}) {
  // 분할입고일때는 메인매니저만 초기화 권한을 주면 됨.
  if (hasMultiLocation) {
    return currentUserIsMainManager;
  }

  return currentUserIsMainManager || currentUserId === placeItems[0].placerId;
}

function getCurrentSKUCount({
  skuId,
  itemId,
  counterData,
  placeItems,
  hasMultiLocation,
  isGroupedItem,
}: {
  skuId: number;
  itemId: number;
  counterData: CounterDataForWarehousing;
  placeItems: ReceivingPlaceItem[];
  hasMultiLocation: boolean;
  isGroupedItem: boolean;
}) {
  if (!counterData) return 0;

  return placeItems.reduce((a, c) => {
    const counterKey = getCounterKeyByStatus({
      hasMultiLocationWarehousing: hasMultiLocation,
      isGroupedItem,
      skuId,
      itemId,
      placingId: c.placingId,
    });

    const currentTempCount = counterData[counterKey]?.current;

    return a + (currentTempCount || c.placeQty || 0);
  }, 0);
}

function getPersonInCharge({
  placeItems,
  getWorkerById,
}: {
  placeItems: ReceivingPlaceItem[];
  getWorkerById: (workerId?: number | undefined) => BofulWorker | undefined;
}) {
  if (!placeItems.length) return "";

  const workerIds = placeItems.reduce((a: number[], c) => {
    if (c.placerId) {
      a.push(c.placerId);
    }
    return a;
  }, []);

  const setOfWorkerIds = new Set(workerIds);

  const firstWorkerName = getWorkerById(workerIds[0])?.name ?? "";

  const isMultiWorker = setOfWorkerIds.size > 1;

  return isMultiWorker
    ? `${firstWorkerName} 외 ${workerIds.length - 1}`
    : firstWorkerName;
}

function getLocationName({
  skuId,
  itemId,
  counterData,
  selectedLocation,
  hasMultiLocation,
  placeItems,
  locationListOfWarehouse,
  placingIdInProgress,
  isGroupedItem,
}: {
  skuId: number;
  itemId: number;
  counterData: CounterDataForWarehousing;
  hasMultiLocation: boolean;
  placeItems: ReceivingPlaceItem[];
  selectedLocation: ScannedLocation | undefined;
  locationListOfWarehouse: BofulLocation[];
  placingIdInProgress: string | undefined;
  isGroupedItem: boolean;
}) {
  if (hasMultiLocation) {
    return `분할입고 - ${placeItems.length}건`;
  }

  const itemAsSingleLocation = placeItems[0];

  const counterKey = getCounterKeyByStatus({
    isGroupedItem,
    skuId,
    itemId,
  });
  const counter = counterData[counterKey];

  // 현재 작업 중
  if (
    placingIdInProgress &&
    placingIdInProgress === itemAsSingleLocation.placingId &&
    counter?.current
  ) {
    return selectedLocation?.barCode ?? "";
  }

  // 완료된 위치
  if (itemAsSingleLocation.locationId) {
    return (
      locationListOfWarehouse.find(
        (v) => v.id === itemAsSingleLocation.locationId
      )?.barCode ?? ""
    );
  }

  return "";
}

function SKUList({
  returningId,
  mainManagerId,
  detailItems,
  selectedLocation,
  rowInfoToHighlight,
  setRowInfoToHighlight,
  groupedItemIdInProgress,
  setGroupedItemIdInProgress,
  skuCounting,
  counterData,
  placingIdInProgress,
  addCountByInput,
  resetCountByCounterKey,
  setConfirmModal,
  resetAfterCompleteWarehousingByForce,
}: {
  returningId: number;
  mainManagerId: number | undefined;
  detailItems: ReceivingItem[] | undefined;
  selectedLocation: ScannedLocation | undefined;
  rowInfoToHighlight: TableRowInfoToHighlight | undefined;
  setRowInfoToHighlight: (val: TableRowInfoToHighlight) => void;
  groupedItemIdInProgress: number;
  setGroupedItemIdInProgress: React.Dispatch<React.SetStateAction<number>>;
  skuCounting: SKUCountingForWarehousing;
  counterData: CounterDataForWarehousing;
  placingIdInProgress: string | undefined;
  addCountByInput: ({ id, count }: { id: string; count: number }) => void;
  resetCountByCounterKey: (counterKey: string) => () => void;
  setConfirmModal: React.Dispatch<
    React.SetStateAction<ConfirmModal | undefined>
  >;
  resetAfterCompleteWarehousingByForce: () => void;
}) {
  const history = useHistory();

  const { currentUser } = useAppSelector((state) => {
    return {
      currentUser: authSelectors.getCurrentUser(),
    };
  });

  const {
    CountForScanningModal,
    handleDirectInputModalOpen,
    setCountForScanning,
  } = useCountForScanning();

  const locationListOfWarehouse = useRecoilValue(
    FULFILLMENT_COMMON_ATOMS.LOCATION_LIST_OF_WAREHOUSE
  );

  const {
    fetchImageInfoBySKUId,
    SKUImageInfoModal,
    ResponseHandlerOfGettingSKUInfo,
    ResponseHandlerOfGettingFileURLList,
  } = useSKUImageInfoModal();

  const getWorkerById = useGetWorkerByIdFactory();

  const { removePersonInCharge, ResponseHandlerOfRemovingPersonInCharge } =
    useRemoveWarehousingPersonInCharge();

  const {
    resetWarehousingOfItem,
    ResponseHandlerOfResetWarehousingOfItemExceptPlacer,
  } = useResetWarehousingOfItem();

  const {
    completeWarehousingByForce,
    ResponseHandlerOfCompletingWarehousingByForce,
  } = useCompleteWarehousingByForce();

  const handleCountAddByDirectInput = useCallback(
    (counterKey: string) => (count: number | undefined) => {
      if (!count) return;

      const target = skuCounting.counter.counterInfo[counterKey];

      addCountByInput({ id: counterKey, count });

      setRowInfoToHighlight({ rowKey: target.itemId });
    },
    [addCountByInput, setRowInfoToHighlight, skuCounting]
  );

  const resetLocalCount = useCallback(
    ({
        hasMultiLocation,
        placeItems,
        skuId,
        itemId,
        isGroupedItem,
      }: {
        hasMultiLocation: boolean;
        placeItems: ReceivingPlaceItem[];
        skuId: number;
        itemId: number;
        isGroupedItem: boolean;
      }) =>
      () => {
        if (hasMultiLocation) {
          const counterKeyListToReset = placeItems.map((placeItem) =>
            getCounterKeyForMultiLocationWarehousing(skuId, placeItem.placingId)
          );

          counterKeyListToReset.forEach((counterKey) =>
            resetCountByCounterKey(counterKey)()
          );
          return;
        }

        const counterKey = getCounterKeyByStatus({
          isGroupedItem,
          skuId,
          itemId,
        });
        resetCountByCounterKey(counterKey)();
      },
    [resetCountByCounterKey]
  );

  const tableDataList = useMemo(() => {
    const list = detailItems;

    if (!list) return [];

    const currentUserId = currentUser?.id;
    const currentUserIsMainManager = mainManagerId === currentUserId;

    const warehousingList = list.reduce<
      Overwrite<
        Partial<ReceivingItem>,
        { id: number; sku: { id: number }; placeItems: ReceivingPlaceItem[] }
      >[]
    >((acc, cur, index) => {
      const duplicateItemIndex = acc.findIndex((v) => v.sku?.id === cur.sku.id);

      if (duplicateItemIndex === -1) {
        return [...acc, cur];
      }

      // 이미 부모행이 만들어져있으면 부모행에는 actualQty를 더해주고, 자식행에는 그대로 추가
      if (acc[duplicateItemIndex].id < 0) {
        return [
          ...acc.slice(0, duplicateItemIndex),
          {
            ...acc[duplicateItemIndex],
            actualQty:
              (acc[duplicateItemIndex].actualQty ?? 0) + (cur.actualQty ?? 0),
          },
          ...acc.slice(duplicateItemIndex + 1),
          cur,
        ];
      }

      // 동일한 SKU ID가 존재하는 경우(groupedItem) 상단에 부모행(SKU ID, 상품바코드, 카운트만 존재)을 만들어 줌
      return [
        ...acc.slice(0, duplicateItemIndex),
        {
          // itemId과 겹치지 않는 고유한 값을 만들기 위해 -index로 설정
          id: -1 * index,
          sku: cur.sku,
          actualQty:
            (acc[duplicateItemIndex].actualQty ?? 0) + (cur.actualQty ?? 0),
          placeItems: [],
        },
        ...acc.slice(duplicateItemIndex),
        cur,
      ];
    }, []);

    const groupedItemSkuIdList = getGroupedItemSkuIdList(warehousingList);

    return warehousingList.map((v) => {
      const hasMultiLocation = getHasMultiLocationWarehousing(
        v.actualQty,
        v.placeItems
      );

      const isParentRowOfGroupedItem = v.id < 0;
      if (isParentRowOfGroupedItem) {
        const groupedItemList = list.filter((item) => item.sku.id === v.sku.id);
        const currentCount = groupedItemList.reduce(
          (a, c) =>
            a +
            getCurrentSKUCount({
              skuId: c.sku.id,
              itemId: c.id,
              counterData,
              placeItems: c.placeItems,
              hasMultiLocation,
              isGroupedItem: true,
            }),
          0
        );

        return {
          rowKey: v.id,

          selection: "",
          location: "",
          SKUId: (
            <SKUInfo
              skuId={v.sku.id}
              skuBarcode={v.sku.barCode}
              handleSKUIdClick={fetchImageInfoBySKUId}
            />
          ),
          count: getStyledSKUCount({ currentCount, goalCount: v.actualQty }),
          personInCharge: "",
          completeStatus: "",
          reset: "",
          completeByForce: "",
          selectMultiLocation: "",
        };
      }

      // 정상/불량으로 나뉜 상품
      const isGroupedItem = checkIsGroupedItemBySkuId(
        groupedItemSkuIdList,
        v.sku.id
      );
      // 불일치 상품
      const isUnverifiedSku = checkIsUnverifiedSku(v.inspectProblems);

      const counterKey = getCounterKeyByStatus({
        isGroupedItem,
        skuId: v.sku.id,
        itemId: v.id,
      });

      const skuId = getFormattedSingleSkuId(v.sku.id);

      const tempLocationName = getLocationName({
        skuId: v.sku.id,
        itemId: v.id,
        counterData,
        placeItems: v.placeItems,
        selectedLocation,
        hasMultiLocation,
        locationListOfWarehouse,
        placingIdInProgress,
        isGroupedItem,
      });

      const currentCount = getCurrentSKUCount({
        skuId: v.sku.id,
        itemId: v.id,
        counterData,
        placeItems: v.placeItems,
        hasMultiLocation,
        isGroupedItem,
      });

      const currentUserIsSingleLocationWarehousingAssignee =
        !hasMultiLocation && v.placeItems?.[0].placerId === currentUserId;
      const completedSingleLocationWarehousing =
        !hasMultiLocation && v.placeItems?.[0].isCompletePlacing;
      const skuInProgressInSingleLocationInspection =
        !hasMultiLocation && v.placeItems[0]?.placingId === placingIdInProgress;
      const canOpenDirectInputModal =
        currentUserIsSingleLocationWarehousingAssignee &&
        !completedSingleLocationWarehousing &&
        skuInProgressInSingleLocationInspection;

      const hasRightToReset = getHasRightToReset({
        hasMultiLocation,
        currentUserId,
        currentUserIsMainManager,
        placeItems: v.placeItems,
      });
      const canReset = getCanReset({
        skuId: v.sku.id,
        itemId: v.id,
        counterData,
        hasRightToReset,
        hasMultiLocation,
        isGroupedItem,
      });

      const completedWarehousing = v.placeItems.every(
        (pi) => pi.isCompletePlacing
      );
      const canCompleteWarehousingByForce =
        !completedWarehousing && currentUserIsMainManager && !currentCount;

      const confirmedAsSingleLocationWarehousing =
        checkConfirmedAsSingleLocationWarehousing(v);
      const canSelectMultiLocation = !confirmedAsSingleLocationWarehousing;

      const isSelectedGroupedItem = v.id === groupedItemIdInProgress;
      const isDisabledGroupedItem = completedWarehousing || hasMultiLocation;

      const itemStatusLabel = getWarehousingItemStatusLabel(v.processStatus);

      const mapped: TableDataListItem<ReturningWarehousingDetailTableItem> = {
        rowKey: v.id,

        isSubRow: isGroupedItem,

        selection: (() => {
          if (!isGroupedItem) {
            return "";
          }

          if (isDisabledGroupedItem) {
            return (
              <Icon
                type="checkStrokeCircleDisabled"
                size={1}
                color={COLOR.grayScale_300}
              />
            );
          }

          if (isSelectedGroupedItem) {
            return (
              <Icon
                type="radioSelected"
                size={1}
                color={COLOR.primaryBlue}
                onClick={() => setGroupedItemIdInProgress(0)}
              />
            );
          }

          return (
            <Icon
              type="radio"
              size={1}
              color={COLOR.grayScale_300}
              onClick={() => setGroupedItemIdInProgress(v.id)}
            />
          );
        })(),

        location: isGroupedItem ? (
          <SubRowIndicator>{tempLocationName}</SubRowIndicator>
        ) : (
          tempLocationName
        ),

        SKUId: isUnverifiedSku ? (
          <UnverifiedSku>
            <Styled.skuIdContainer>
              <SKUInfo
                skuId={v.sku.id}
                skuBarcode={v.sku.barCode}
                handleSKUIdClick={fetchImageInfoBySKUId}
              />

              <ItemStatus
                statusLabel={itemStatusLabel}
                isUnverifiedSku={isUnverifiedSku}
                isTable
              />
            </Styled.skuIdContainer>
          </UnverifiedSku>
        ) : (
          <Styled.skuIdContainer>
            <SKUInfo
              skuId={v.sku.id}
              skuBarcode={v.sku.barCode}
              handleSKUIdClick={fetchImageInfoBySKUId}
            />

            <ItemStatus
              statusLabel={itemStatusLabel}
              isUnverifiedSku={isUnverifiedSku}
              isTable
            />
          </Styled.skuIdContainer>
        ),

        count: (
          <CountForScanning
            // type="warehousing"
            canOpenDirectInputModal={canOpenDirectInputModal}
            // skuId={v.sku.id}
            currentQty={currentCount}
            quantity={v.actualQty ?? 0}
            // onCountAdd={handleCountAddByDirectInput(counterKey)}
            openDirectInputModal={() => {
              handleDirectInputModalOpen();
              setCountForScanning({
                type: "returningWarehousing",
                skuId: v.sku.id,
                currentQty: currentCount,
                quantity: v.actualQty ?? 0,
                onCountAdd: handleCountAddByDirectInput(counterKey),
                statusLabel: itemStatusLabel,
                isUnverifiedSku,
              });
            }}
          />
        ),

        personInCharge: (
          <PersonInCharge
            worker={getPersonInCharge({
              placeItems: v.placeItems,
              getWorkerById,
            })}
            isRemovable={currentUserIsMainManager}
            openConfirmModal={() => {
              setConfirmModal({
                uiType: "titleOnly",
                title: "SKU 담당자를 삭제하시겠습니까?",
                actions: {
                  actionPositive: {
                    label: "예",
                    handleClick: removePersonInCharge({
                      returningId,
                      itemId: v.id,
                      resetAfterRemoveInProgressPersonInCharge:
                        resetCountByCounterKey(counterKey),
                    }),
                  },
                  actionNegative: {
                    label: "아니오",
                    handleClick: () => setConfirmModal(undefined),
                  },
                },
              });
            }}
            // removePersonInCharge={removePersonInCharge({
            //   returningId,
            //   itemId: v.id,
            //   resetAfterRemoveInProgressPersonInCharge:
            //     resetCountByCounterKey(counterKey),
            // })}
          />
        ),

        completeStatus: v.placeItems.some((p) => p.isCompletePlacing)
          ? "Y"
          : "N",

        reset: (
          <ResetCounting
            skuId={v.sku.id}
            canReset={canReset}
            openConfirmModal={() => {
              setConfirmModal({
                uiType: "content",
                title: (
                  <>
                    {skuId}(SKU ID)
                    <br />
                    상태:{" "}
                    <ItemStatus
                      statusLabel={itemStatusLabel}
                      isUnverifiedSku={isUnverifiedSku}
                    />
                  </>
                ),
                body: (
                  <>
                    초기화를 진행하겠습니까? <br />
                    (분할내역도 초기화 됩니다.)
                  </>
                ),
                actions: {
                  actionPositive: {
                    label: "예",
                    handleClick: resetWarehousingOfItem({
                      returningId,
                      itemId: v.id,
                      resetLocalCount: resetLocalCount({
                        hasMultiLocation,
                        placeItems: v.placeItems,
                        skuId: v.sku.id,
                        itemId: v.id,
                        isGroupedItem,
                      }),
                    }),
                  },
                  actionNegative: {
                    label: "아니오",
                    handleClick: () => setConfirmModal(undefined),
                  },
                },
              });
            }}
            // reset={resetWarehousingOfItem({
            //   returningId,
            //   itemId: v.id,
            //   resetLocalCount: resetLocalCount({
            //     hasMultiLocation,
            //     placeItems: v.placeItems,
            //     skuId: v.sku.id,
            //   }),
            // })}
          />
        ),

        completeByForce: (
          <CompleteByForce
            skuId={v.sku.id}
            canCompleteByForce={canCompleteWarehousingByForce}
            openConfirmModal={() => {
              setConfirmModal({
                uiType: "titleOnly",
                title: (
                  <>
                    강제로 완료처리하시겠습니까? (SKU ID: {skuId} / 상태:{" "}
                    <ItemStatus
                      statusLabel={itemStatusLabel}
                      isUnverifiedSku={isUnverifiedSku}
                    />
                    )
                  </>
                ),
                actions: {
                  actionPositive: {
                    label: "예",
                    handleClick: completeWarehousingByForce({
                      returningId,
                      itemId: v.id,
                      resetAfterCompleteWarehousingByForce: () => {
                        resetAfterCompleteWarehousingByForce();
                        setConfirmModal(undefined);
                      },
                    }),
                  },
                  actionNegative: {
                    label: "아니오",
                    handleClick: () => setConfirmModal(undefined),
                  },
                },
              });
            }}
            // completeByForce={completeWarehousingByForce({
            //   returningId,
            //   skuId: v.sku.id,
            // })}
          />
        ),

        selectMultiLocation: (
          <SelectMultiLocation
            type="warehousing"
            canSelectMultiLocation={canSelectMultiLocation}
            skuId={v.sku.id}
            openConfirmModal={() => {
              setConfirmModal({
                uiType: "content",
                title: (
                  <>
                    {skuId}(SKU ID)
                    <br />
                    상태:{" "}
                    <ItemStatus
                      statusLabel={itemStatusLabel}
                      isUnverifiedSku={isUnverifiedSku}
                    />
                  </>
                ),
                body: `분할입고를 진행하겠습니까?`,
                actions: {
                  actionPositive: {
                    label: "예",
                    handleClick: () => {
                      setConfirmModal(undefined);
                      history.push({
                        pathname: `${history.location?.pathname}/multi-location/${v.sku.id}`,
                        state: { itemId: v.id },
                      });
                    },
                  },
                  actionNegative: {
                    label: "아니오",
                    handleClick: () => setConfirmModal(undefined),
                  },
                },
              });
            }}
          />
        ),
      };

      return mapped;
    });
  }, [
    detailItems,
    currentUser?.id,
    mainManagerId,
    counterData,
    selectedLocation,
    locationListOfWarehouse,
    placingIdInProgress,
    groupedItemIdInProgress,
    fetchImageInfoBySKUId,
    getWorkerById,
    setGroupedItemIdInProgress,
    handleDirectInputModalOpen,
    setCountForScanning,
    handleCountAddByDirectInput,
    setConfirmModal,
    removePersonInCharge,
    returningId,
    resetCountByCounterKey,
    resetWarehousingOfItem,
    resetLocalCount,
    completeWarehousingByForce,
    resetAfterCompleteWarehousingByForce,
    history,
  ]);

  return (
    <>
      <TableForCounting<ReturningWarehousingDetailTableItem>
        isWindowed
        height={300}
        rowInfoToHighlight={rowInfoToHighlight}
        columnInfo={{
          selection: {
            label: "선택",
            fixedWidth: 40,
          },
          location: {
            label: "위치",
            fixedWidth: 100,
          },
          SKUId: {
            label: (
              <>
                SKU ID / 상품바코드 <br /> 상태
              </>
            ),
            fixedWidth: 130,
          },
          count: {
            label: "카운트",
            fixedWidth: 110,
          },
          personInCharge: {
            label: "담당자",
            fixedWidth: 150,
          },
          completeStatus: {
            label: "입고완료여부",
            fixedWidth: 100,
          },
          reset: {
            label: "초기화",
            fixedWidth: 110,
          },
          completeByForce: {
            label: "강제처리(카운트: 0)",
            fixedWidth: 150,
          },
          selectMultiLocation: {
            label: "분할입고",
            fixedWidth: 110,
          },
        }}
        dataList={tableDataList}
      />

      {CountForScanningModal}

      {SKUImageInfoModal}
      {ResponseHandlerOfGettingSKUInfo}
      {ResponseHandlerOfGettingFileURLList}

      {ResponseHandlerOfRemovingPersonInCharge}
      {ResponseHandlerOfResetWarehousingOfItemExceptPlacer}
      {ResponseHandlerOfCompletingWarehousingByForce}
    </>
  );
}

export default memo(SKUList);
