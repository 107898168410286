import React from "react";
import { useState } from "react";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";

import { TableDataListItem } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import RETURNING_QUERY from "@sellernote/_shared/src/queries/fulfillment/RETURNING_QUERY";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { ReceivingListItem } from "@sellernote/_shared/src/types/fulfillment/receiving";
import {
  isTodayOrBeforeToday,
  toFormattedDate,
} from "@sellernote/_shared/src/utils/common/date";
import { getPageSize } from "@sellernote/_shared/src/utils/common/etc";
import {
  getFormattedReturningId,
  getReturningInvoiceNoForPDA,
} from "@sellernote/_shared/src/utils/fulfillment/returning";
import Paging from "@sellernote/_sds-v1/src/components/Paging";
import TabFilter from "@sellernote/_sds-v1/src/components/TabFilter";
import Table from "@sellernote/_sds-v1/src/components/table/Table";

import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";

import AssignToMe, { ItemToAssign } from "./AssignToMe";
import Styled from "./index.styles";

type ReturningTableItem = {
  combinedTitle: React.ReactNode;
  SKU: number;
  PCS: number;
  bidId: number | string;
  returningInvoiceNo?: string;
} & Pick<ReceivingListItem, "dueDate" | "expectedDate">;

type FilterForIsMine = "mine" | "unSet";

const PAGE_UNIT = 10;

function ReturningConfirm() {
  const [filterForIsMine, setFilterForIsMine] =
    useState<FilterForIsMine>("mine");
  const [currentPage, setCurrentPage] = useState(0);
  const [itemToAssign, setItemToAssign] = useState<ItemToAssign | undefined>();

  const history = useHistory();

  const {
    data: returningList,
    refetch: refetchReturningList,
    ResponseHandler: ResponseHandlerOfGettingReturningList,
  } = RETURNING_QUERY.useGetManagerReturningList({
    page: currentPage,
    perPage: PAGE_UNIT,
    mode: "beforeReturning",
    isMine: filterForIsMine === "mine",
  });

  const isMine = filterForIsMine === "mine";

  const tableDataList = useMemo(() => {
    const list = returningList?.list;

    if (!list) {
      return [];
    }

    return list.map((v, i) => {
      const mapped: TableDataListItem<ReturningTableItem> = {
        rowKey: v.id,
        handleRowClick: () =>
          isMine
            ? handleClickMyItem(v.id)
            : handleClickUnSetItem({
                id: v.id,
                companyName: v.team?.company,
                teamName: v.team?.name,
                pcs: v.totalItem.quantity,
              }),
        rowFontColor: isTodayOrBeforeToday(v.dueDate)
          ? COLOR.pointWarning
          : undefined,

        combinedTitle: `${v.team?.company ?? "-"} (${v.team?.name ?? "-"} / ${
          v.team?.id
        })`,
        bidId: getFormattedReturningId(v),
        SKU: v.items.filter((v) => v.quantity > 0).length,
        PCS: v.totalItem.quantity,
        expectedDate: toFormattedDate(v.expectedDate, "YYYY-MM-DD"),
        dueDate: toFormattedDate(v.dueDate, "YYYY-MM-DD"),
        ...(!isMine && {
          returningInvoiceNo: getReturningInvoiceNoForPDA(v.returningPackings),
        }),
      };

      return mapped;
    });

    function handleClickMyItem(id: number) {
      history.push(`${history.location.pathname}/${id}`);
    }

    function handleClickUnSetItem({
      id,
      companyName,
      teamName,
      pcs,
    }: {
      id: number;
      companyName?: string;
      teamName?: string;
      pcs?: number;
    }) {
      setItemToAssign({
        id,
        summary: (
          <>
            {`R${id}`} <br />
            {companyName ?? "-"} ({teamName ?? "-"}) / {pcs} PCS
          </>
        ),
      });
    }
  }, [returningList?.list, isMine, history]);

  return (
    <Layout
      navigator={{
        title: "의뢰확인 및 입하",
      }}
    >
      <Styled.container>
        <TabFilter<FilterForIsMine>
          filterList={[
            {
              label: `My 의뢰 (${
                isMine ? returningList?.total : returningList?.anotherTotal
              })`,
              filter: "mine",
            },
            {
              label: `미배정의뢰 (${
                isMine ? returningList?.anotherTotal : returningList?.total
              })`,
              filter: "unSet",
            },
          ]}
          selectedFilter={filterForIsMine}
          setFilter={(filter) => {
            setFilterForIsMine(filter);
            setCurrentPage(0);
          }}
        />

        <Paging
          pageSize={getPageSize(PAGE_UNIT, returningList?.total)}
          currentPage={currentPage}
          currentPageData={
            <Table<ReturningTableItem>
              columnInfo={{
                combinedTitle: {
                  label: "회사명 (팀명)",
                  fixedWidth: 120,
                },
                bidId: {
                  label: "의뢰번호",
                },
                SKU: {
                  label: (
                    <>
                      입고상품수
                      <br />
                      (SKU)
                    </>
                  ),
                },
                PCS: {
                  label: (
                    <>
                      입고요청수량
                      <br />
                      (PCS)
                    </>
                  ),
                  fixedWidth: 100,
                },
                expectedDate: {
                  label: "센터도착일",
                  fixedWidth: 100,
                },
                dueDate: {
                  label: "입고기한",
                  fixedWidth: 100,
                },
                ...(!isMine && {
                  returningInvoiceNo: {
                    label: "반송장",
                    fixedWidth: 140,
                  },
                }),
              }}
              dataList={tableDataList}
            />
          }
          handleClickPage={setCurrentPage}
          isZeroBasedPage
        />
      </Styled.container>

      <AssignToMe
        item={itemToAssign}
        resetItem={() => setItemToAssign(undefined)}
        fetchList={refetchReturningList}
      />

      {ResponseHandlerOfGettingReturningList}
    </Layout>
  );
}

export default withRequireAuth(ReturningConfirm);
